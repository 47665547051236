import React from 'react';
import FullPageContainer from './js/components/containers/main-container/FullPageContainer';
import AndroidMapComponent from './js/components/containers/android-container/AndroidMapComponent';
import './scss/main.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {  useEffect } from "react";
import CiscoLiveFullPageContainer from './js/components/containers/cisco-live-container/CiscoLiveFullPageContainer';
import WebexOneFullPageContainer from './js/components/containers/webex-one-container/WebexOneFullPageContainer';
import CiscoLiveEMEAFullPageContainer from './js/components/containers/cisco-live-emea-container/CiscoLiveEMEAFullPageContainer';
import CLAI2025Page from './js/components/containers/cisco-live-emea-container-ai/CLPAGE25';
import HospitalVenueDemoFullPageContainer from './js/components/containers/hospital-venue/HospitalVenueDemoFullPageContainer';
import SJCDemoFullPageContainer from './js/components/containers/hospital-venue-sjc-demo/SJCDemoFullPageContainer';

function App() {
 
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/wayfinding/*" element={<AndroidMapComponent />} />
        <Route path={"/"}element={<FullPageContainer />} />
        <Route path={"/gsx"}element={<FullPageContainer />} />
        <Route path="/clapjc2024" element={<CiscoLiveFullPageContainer />} />
        <Route path="/clemea2025" element={<CiscoLiveEMEAFullPageContainer />} />
        <Route path="/himss" element={<HospitalVenueDemoFullPageContainer />} />
        <Route path="/sjc" element={<SJCDemoFullPageContainer />} />
        <Route path="/webexone" element={<WebexOneFullPageContainer />} />
        <Route path="/clai2025" element={<CLAI2025Page />} /> {/* New Route */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
