import React, { useEffect, useState, useRef } from "react";
import MapComponent from "../common-pointr-map-container/GenericPointrMapContainer"
import LeftContainer from "../left-container/left-container";
import HeaderContainer from "../header-container/header-container";
import RightContainer from "../right-container/right-container";
import SearchPopup from '../main-container/SearchPopup'; // Import SearchPopup component
import FooterContainer from '../footer-container/footer-container';
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { handleReset } from '../main-container/handleResetView'; // Import the functions
import { calculateMidPoint, manageLayers,markCurrentPosition, removeCurrentPosition } from '../main-container/markCurrentPosition'; // Import the functions
import { fetchGsxData } from '../left-container/fetchZonesHubwalksInfo';
import CustomPopup from '../main-container/DirectionPopup';
import ciscoSpacesPopupIcon from '../../../../assets/images/powered-by-spaces-cl-emea.png';
import Screensaver from "../countdown-timer-container/screensaver";
import ciscoSpacesPopupImage from '../../../../assets/images/cisco-space-popup-cl-emea.png';
import {handleWhereAmI} from '../footer-container/handle-where-am-i';  
import ciscoAILogo from '../../../../assets/images/ai/ai-logo.svg';
import ciscoAIQrPlaceHolderLogo from '../../../../assets/images/ai/qr-code-place-holder.svg';
import ciscoAIQrPlaceHolderFailedLogo from '../../../../assets/images/ai/qr-code-place-holder-failed.svg';

import ciscoClosebutton from '../../../../assets/images/close-button.svg';
import ciscoHelpbutton from '../../../../assets/images/ai/help-icon.svg';
import CiscoAiAuth from '../right-container/ciscoAiAuth';
import {fetchAttendeeInfo, fetchRecommendedEvents} from '../right-container/ciscoAiApis';
import { TeaLogService,TeaLogModule } from '@events/tealium';
import {initializeXAPIScanner, getHashes} from '../qr-code-scanner-component/qr-code-scanner';
import AIFAQListContainer from "../right-container/ai-faq-list";
import {sendTrackingEventForCiscoAI} from "../main-container/pendoApis"
import { scaleBand } from "d3";
const HospitalVenueDemoMainContainer = () => {
  const kioskData = {
    "1": {
      "lat":  42.18882997249821,
      "long":  -71.20129185010097,
      "rotation": 1.5,
      "bearing": -83.8,
      "building": 8,
      "level":0
    },
    "2": {
      "lat": 42.18882997249821,
      "long": 4.889270989661952,
      "rotation": 1.5,
      "bearing": -83.5,
      "building": 8,
      "level":0
    }
  };
  
  const { map } = useMap();
  const teaLogService = new TeaLogService();

  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isZonesInfoPopulated, setIsZonesInfoPopulated] = useState(false);

  const [zonesInfo, setZonesInfo] = useState([]);
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [recommendationsInfo, setRecommendationsInfo] = useState([]);
  const [attendeeUserToken, setAttendeeUserToken] = useState([]);
  const [attendeeInfo, setAttendeeInfo] = useState([]);

  const hasPassedDestFidRef = useRef(false);

  const timerRef = useRef(null);
  const [popupState, setPopupState] = useState({ poi: null, coordinates: null }); // Add state for the popup
  const [ciscoSpacesPopup, setCiscoSpacesPopup] = useState(false);
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [showFaqList, setShowFaqList] = useState(false);
  const [qrScanBadgeInfoInput, setQRScanBadgeInfoInput] = useState(null);
  const [aiLoader, showAiLoader] = useState(false);
  const [showFailedPopupCard, setShowFailedPopupCard] = useState(false);

  
  
  const [showAICard, setShowAICard] = useState(false);

  const [isPointrSDKLoaded, setIsPointrSDKLoaded] = useState(false); // State to track Pointr SDK load status
  const [triggerDirectionsPopup, setTriggerDirectionsPopup] = useState(false);

  const tenantId = "363250";
  const locationId = "64a2e626-25bf-4559-9e06-4b57127c9b23";
  const siteExternalIdentifier = "64a2e626-25bf-4559-9e06-4b57127c9b23";
  const timeZoneCity = "Las Vegas";
  const timeZone = "America/Los_Angeles";
  const qrCodeExpiresAt = 1743465599 // March 31 2025
  const eventLogoName = "trigue.svg"
  const eventName = "HIMSS"
  const eventId="ds1ciscolive25emea";
  const eventTitle = "Woodbury"
  const defaultBuildingTitle = "Woodbury Las Vegas"


  
  const eventSubTitle = ""

  const buildingInfo = [
    {
        "buildingTitle": "Hospital Building",
        "buildingInternalIdentifier": 9,
        "buildingExternalIdentifier": "5db35008-3de0-4551-8b6e-a6fe29b06a38",
        "buildingExtraData": {
            "timeZone": "America/Los_Angeles"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.20258849,
                        42.18869352
                    ],
                    [
                        -71.20258819,
                        42.18868984
                    ],
                    [
                        -71.20258778,
                        42.18868803
                    ],
                    [
                        -71.20258754,
                        42.18868619
                    ],
                    [
                        -71.20258656,
                        42.18868258
                    ],
                    [
                        -71.20258523,
                        42.18867903
                    ],
                    [
                        -71.20258485,
                        42.18867817
                    ],
                    [
                        -71.20258432,
                        42.1886773
                    ],
                    [
                        -71.20258397,
                        42.18867648
                    ],
                    [
                        -71.20258358,
                        42.18867555
                    ],
                    [
                        -71.20258231,
                        42.1886733
                    ],
                    [
                        -71.2025816,
                        42.18867217
                    ],
                    [
                        -71.20257932,
                        42.18866889
                    ],
                    [
                        -71.20257795,
                        42.18866735
                    ],
                    [
                        -71.20257673,
                        42.18866575
                    ],
                    [
                        -71.20257385,
                        42.18866274
                    ],
                    [
                        -71.2025707,
                        42.18865989
                    ],
                    [
                        -71.20256894,
                        42.18865859
                    ],
                    [
                        -71.20256729,
                        42.1886572
                    ],
                    [
                        -71.20256541,
                        42.188656
                    ],
                    [
                        -71.20256364,
                        42.1886547
                    ],
                    [
                        -71.20255976,
                        42.18865239
                    ],
                    [
                        -71.20255567,
                        42.18865029
                    ],
                    [
                        -71.2025535,
                        42.1886494
                    ],
                    [
                        -71.20255141,
                        42.18864841
                    ],
                    [
                        -71.20254696,
                        42.18864674
                    ],
                    [
                        -71.2025438,
                        42.18864575
                    ],
                    [
                        -71.20254238,
                        42.18864531
                    ],
                    [
                        -71.20253916,
                        42.18864446
                    ],
                    [
                        -71.20253768,
                        42.18864411
                    ],
                    [
                        -71.20253313,
                        42.18864322
                    ],
                    [
                        -71.20253288,
                        42.18864317
                    ],
                    [
                        -71.2025321,
                        42.18864306
                    ],
                    [
                        -71.20253176,
                        42.18864299
                    ],
                    [
                        -71.202528,
                        42.18864247
                    ],
                    [
                        -71.20252581,
                        42.18864204
                    ],
                    [
                        -71.20252547,
                        42.18864191
                    ],
                    [
                        -71.20252511,
                        42.18864182
                    ],
                    [
                        -71.20252417,
                        42.18864137
                    ],
                    [
                        -71.20252317,
                        42.18864096
                    ],
                    [
                        -71.20252291,
                        42.18864077
                    ],
                    [
                        -71.2025226,
                        42.18864061
                    ],
                    [
                        -71.20252185,
                        42.18863999
                    ],
                    [
                        -71.20252105,
                        42.18863939
                    ],
                    [
                        -71.20252086,
                        42.18863915
                    ],
                    [
                        -71.2025206,
                        42.18863893
                    ],
                    [
                        -71.20251957,
                        42.18863744
                    ],
                    [
                        -71.2025193,
                        42.18863667
                    ],
                    [
                        -71.20251898,
                        42.18863584
                    ],
                    [
                        -71.20251892,
                        42.18863361
                    ],
                    [
                        -71.20251968,
                        42.18863145
                    ],
                    [
                        -71.20252039,
                        42.18863057
                    ],
                    [
                        -71.20252123,
                        42.18862953
                    ],
                    [
                        -71.20252353,
                        42.18862727
                    ],
                    [
                        -71.2025256,
                        42.18862486
                    ],
                    [
                        -71.20252582,
                        42.18862459
                    ],
                    [
                        -71.20252678,
                        42.18862357
                    ],
                    [
                        -71.20252709,
                        42.18862312
                    ],
                    [
                        -71.20252763,
                        42.18862249
                    ],
                    [
                        -71.20252926,
                        42.18862025
                    ],
                    [
                        -71.20253008,
                        42.18861881
                    ],
                    [
                        -71.2025311,
                        42.18861734
                    ],
                    [
                        -71.20253206,
                        42.1886153
                    ],
                    [
                        -71.2025331,
                        42.18861347
                    ],
                    [
                        -71.2025336,
                        42.18861206
                    ],
                    [
                        -71.20253429,
                        42.18861061
                    ],
                    [
                        -71.20253491,
                        42.18860838
                    ],
                    [
                        -71.20253564,
                        42.18860635
                    ],
                    [
                        -71.20253586,
                        42.18860501
                    ],
                    [
                        -71.20253624,
                        42.18860363
                    ],
                    [
                        -71.20253647,
                        42.18860123
                    ],
                    [
                        -71.20253682,
                        42.18859904
                    ],
                    [
                        -71.20253679,
                        42.18859781
                    ],
                    [
                        -71.20253691,
                        42.18859653
                    ],
                    [
                        -71.20253676,
                        42.18859296
                    ],
                    [
                        -71.20253664,
                        42.18859231
                    ],
                    [
                        -71.20253664,
                        42.18859168
                    ],
                    [
                        -71.20253607,
                        42.18858903
                    ],
                    [
                        -71.20253551,
                        42.18858589
                    ],
                    [
                        -71.20253523,
                        42.18858514
                    ],
                    [
                        -71.20253507,
                        42.18858441
                    ],
                    [
                        -71.20253404,
                        42.18858191
                    ],
                    [
                        -71.20253328,
                        42.18857984
                    ],
                    [
                        -71.20253311,
                        42.18857939
                    ],
                    [
                        -71.20253301,
                        42.18857914
                    ],
                    [
                        -71.20256099,
                        42.18857109
                    ],
                    [
                        -71.20250734,
                        42.18846865
                    ],
                    [
                        -71.20247936,
                        42.18847669
                    ],
                    [
                        -71.20247932,
                        42.18847664
                    ],
                    [
                        -71.2024792,
                        42.18847638
                    ],
                    [
                        -71.20247806,
                        42.18847482
                    ],
                    [
                        -71.20247792,
                        42.18847458
                    ],
                    [
                        -71.20247782,
                        42.18847442
                    ],
                    [
                        -71.20247723,
                        42.18847361
                    ],
                    [
                        -71.20247714,
                        42.1884735
                    ],
                    [
                        -71.20247705,
                        42.18847333
                    ],
                    [
                        -71.20247695,
                        42.18847318
                    ],
                    [
                        -71.20247681,
                        42.18847294
                    ],
                    [
                        -71.20247669,
                        42.18847281
                    ],
                    [
                        -71.2024765,
                        42.18847259
                    ],
                    [
                        -71.20247612,
                        42.18847199
                    ],
                    [
                        -71.2024759,
                        42.18847169
                    ],
                    [
                        -71.20247575,
                        42.18847153
                    ],
                    [
                        -71.20247562,
                        42.18847135
                    ],
                    [
                        -71.20247505,
                        42.18847047
                    ],
                    [
                        -71.20247481,
                        42.18847021
                    ],
                    [
                        -71.2024746,
                        42.18846992
                    ],
                    [
                        -71.20247398,
                        42.18846941
                    ],
                    [
                        -71.20247338,
                        42.1884687
                    ],
                    [
                        -71.20247328,
                        42.18846858
                    ],
                    [
                        -71.2024731,
                        42.18846837
                    ],
                    [
                        -71.20247224,
                        42.18846748
                    ],
                    [
                        -71.20247181,
                        42.18846685
                    ],
                    [
                        -71.20247157,
                        42.18846658
                    ],
                    [
                        -71.20247141,
                        42.18846664
                    ],
                    [
                        -71.20247067,
                        42.18846581
                    ],
                    [
                        -71.20247041,
                        42.1884656
                    ],
                    [
                        -71.20247022,
                        42.18846539
                    ],
                    [
                        -71.20246955,
                        42.18846462
                    ],
                    [
                        -71.20246921,
                        42.18846435
                    ],
                    [
                        -71.20246891,
                        42.18846404
                    ],
                    [
                        -71.20246839,
                        42.18846377
                    ],
                    [
                        -71.20246742,
                        42.1884629
                    ],
                    [
                        -71.20246715,
                        42.18846267
                    ],
                    [
                        -71.20246612,
                        42.18846186
                    ],
                    [
                        -71.20246558,
                        42.18846127
                    ],
                    [
                        -71.20246362,
                        42.18845983
                    ],
                    [
                        -71.20246344,
                        42.18845969
                    ],
                    [
                        -71.20246304,
                        42.18845937
                    ],
                    [
                        -71.20246277,
                        42.1884592
                    ],
                    [
                        -71.2024626,
                        42.1884591
                    ],
                    [
                        -71.20246235,
                        42.18845891
                    ],
                    [
                        -71.20246211,
                        42.18845873
                    ],
                    [
                        -71.20246183,
                        42.18845856
                    ],
                    [
                        -71.20245926,
                        42.18845705
                    ],
                    [
                        -71.20245898,
                        42.18845687
                    ],
                    [
                        -71.20245885,
                        42.18845679
                    ],
                    [
                        -71.20245851,
                        42.18845643
                    ],
                    [
                        -71.20245768,
                        42.1884561
                    ],
                    [
                        -71.20245704,
                        42.18845567
                    ],
                    [
                        -71.20245644,
                        42.18845536
                    ],
                    [
                        -71.20245564,
                        42.18845482
                    ],
                    [
                        -71.20245507,
                        42.18845454
                    ],
                    [
                        -71.20245457,
                        42.18845425
                    ],
                    [
                        -71.20245368,
                        42.18845396
                    ],
                    [
                        -71.202453,
                        42.18845359
                    ],
                    [
                        -71.20245253,
                        42.18845334
                    ],
                    [
                        -71.20245126,
                        42.18845278
                    ],
                    [
                        -71.20245049,
                        42.18845229
                    ],
                    [
                        -71.20244977,
                        42.18845212
                    ],
                    [
                        -71.20244949,
                        42.18845199
                    ],
                    [
                        -71.20244931,
                        42.18845191
                    ],
                    [
                        -71.20244895,
                        42.18845171
                    ],
                    [
                        -71.20244832,
                        42.18845147
                    ],
                    [
                        -71.20244747,
                        42.18845102
                    ],
                    [
                        -71.2024467,
                        42.18845086
                    ],
                    [
                        -71.20244623,
                        42.18845054
                    ],
                    [
                        -71.20244535,
                        42.18845032
                    ],
                    [
                        -71.20244512,
                        42.18845023
                    ],
                    [
                        -71.20244453,
                        42.18845004
                    ],
                    [
                        -71.20244177,
                        42.188449
                    ],
                    [
                        -71.20244058,
                        42.18844868
                    ],
                    [
                        -71.20244006,
                        42.18844855
                    ],
                    [
                        -71.20243983,
                        42.18844847
                    ],
                    [
                        -71.20243871,
                        42.18844809
                    ],
                    [
                        -71.20243771,
                        42.18844792
                    ],
                    [
                        -71.20243597,
                        42.18844746
                    ],
                    [
                        -71.20243585,
                        42.18844734
                    ],
                    [
                        -71.20243534,
                        42.18844729
                    ],
                    [
                        -71.20243416,
                        42.18844696
                    ],
                    [
                        -71.20243379,
                        42.1884469
                    ],
                    [
                        -71.20243287,
                        42.18844677
                    ],
                    [
                        -71.2024323,
                        42.18844665
                    ],
                    [
                        -71.20243083,
                        42.18844633
                    ],
                    [
                        -71.20243035,
                        42.18844626
                    ],
                    [
                        -71.2024288,
                        42.1884459
                    ],
                    [
                        -71.20242852,
                        42.1884459
                    ],
                    [
                        -71.20242479,
                        42.18844539
                    ],
                    [
                        -71.20242306,
                        42.188445
                    ],
                    [
                        -71.20242266,
                        42.18844491
                    ],
                    [
                        -71.20242235,
                        42.18844485
                    ],
                    [
                        -71.20242218,
                        42.18844483
                    ],
                    [
                        -71.2024219,
                        42.18844473
                    ],
                    [
                        -71.20242172,
                        42.18844466
                    ],
                    [
                        -71.20242135,
                        42.18844449
                    ],
                    [
                        -71.20242116,
                        42.18844441
                    ],
                    [
                        -71.20242085,
                        42.18844424
                    ],
                    [
                        -71.20242027,
                        42.18844404
                    ],
                    [
                        -71.20242011,
                        42.18844394
                    ],
                    [
                        -71.20241995,
                        42.18844387
                    ],
                    [
                        -71.2024197,
                        42.18844373
                    ],
                    [
                        -71.20241918,
                        42.1884433
                    ],
                    [
                        -71.20241813,
                        42.18844259
                    ],
                    [
                        -71.20241799,
                        42.18844244
                    ],
                    [
                        -71.20241782,
                        42.18844231
                    ],
                    [
                        -71.20241776,
                        42.1884422
                    ],
                    [
                        -71.20241761,
                        42.18844201
                    ],
                    [
                        -71.20241743,
                        42.18844182
                    ],
                    [
                        -71.20241728,
                        42.18844166
                    ],
                    [
                        -71.20241691,
                        42.18844124
                    ],
                    [
                        -71.20241684,
                        42.18844112
                    ],
                    [
                        -71.20241672,
                        42.18844096
                    ],
                    [
                        -71.20241668,
                        42.18844079
                    ],
                    [
                        -71.20241658,
                        42.18844065
                    ],
                    [
                        -71.20241653,
                        42.18844049
                    ],
                    [
                        -71.20241645,
                        42.18844034
                    ],
                    [
                        -71.20241614,
                        42.18843975
                    ],
                    [
                        -71.20241607,
                        42.18843957
                    ],
                    [
                        -71.20241589,
                        42.18843921
                    ],
                    [
                        -71.2024158,
                        42.18843882
                    ],
                    [
                        -71.20241576,
                        42.18843856
                    ],
                    [
                        -71.20241569,
                        42.1884384
                    ],
                    [
                        -71.20241572,
                        42.18843712
                    ],
                    [
                        -71.20241572,
                        42.18843676
                    ],
                    [
                        -71.2024157,
                        42.1884365
                    ],
                    [
                        -71.20241572,
                        42.18843629
                    ],
                    [
                        -71.2024163,
                        42.18843484
                    ],
                    [
                        -71.20241645,
                        42.1884344
                    ],
                    [
                        -71.20241651,
                        42.18843425
                    ],
                    [
                        -71.20241772,
                        42.1884328
                    ],
                    [
                        -71.20241788,
                        42.18843256
                    ],
                    [
                        -71.202418,
                        42.18843244
                    ],
                    [
                        -71.20241813,
                        42.18843232
                    ],
                    [
                        -71.20241837,
                        42.18843206
                    ],
                    [
                        -71.20241889,
                        42.18843156
                    ],
                    [
                        -71.20241938,
                        42.1884311
                    ],
                    [
                        -71.20242031,
                        42.18843019
                    ],
                    [
                        -71.20242045,
                        42.18843
                    ],
                    [
                        -71.2024206,
                        42.18842986
                    ],
                    [
                        -71.20242098,
                        42.18842948
                    ],
                    [
                        -71.20242116,
                        42.18842919
                    ],
                    [
                        -71.20242134,
                        42.18842899
                    ],
                    [
                        -71.20242149,
                        42.18842884
                    ],
                    [
                        -71.2024216,
                        42.1884287
                    ],
                    [
                        -71.20242172,
                        42.18842855
                    ],
                    [
                        -71.20242201,
                        42.18842818
                    ],
                    [
                        -71.20242244,
                        42.18842785
                    ],
                    [
                        -71.20242406,
                        42.18842583
                    ],
                    [
                        -71.20242442,
                        42.1884254
                    ],
                    [
                        -71.20242465,
                        42.18842504
                    ],
                    [
                        -71.20242506,
                        42.18842436
                    ],
                    [
                        -71.20242517,
                        42.18842424
                    ],
                    [
                        -71.20242555,
                        42.18842376
                    ],
                    [
                        -71.20242605,
                        42.18842314
                    ],
                    [
                        -71.2024263,
                        42.18842271
                    ],
                    [
                        -71.20242671,
                        42.18842212
                    ],
                    [
                        -71.20242728,
                        42.18842096
                    ],
                    [
                        -71.20242742,
                        42.18842072
                    ],
                    [
                        -71.20242752,
                        42.1884206
                    ],
                    [
                        -71.202428,
                        42.18841969
                    ],
                    [
                        -71.20242871,
                        42.18841875
                    ],
                    [
                        -71.20242884,
                        42.18841841
                    ],
                    [
                        -71.20242922,
                        42.18841754
                    ],
                    [
                        -71.20242953,
                        42.18841698
                    ],
                    [
                        -71.20242952,
                        42.18841687
                    ],
                    [
                        -71.20242967,
                        42.18841674
                    ],
                    [
                        -71.20242989,
                        42.18841634
                    ],
                    [
                        -71.20243008,
                        42.18841589
                    ],
                    [
                        -71.20243023,
                        42.18841548
                    ],
                    [
                        -71.20243037,
                        42.18841527
                    ],
                    [
                        -71.2024304,
                        42.18841511
                    ],
                    [
                        -71.20243049,
                        42.18841488
                    ],
                    [
                        -71.20243093,
                        42.18841378
                    ],
                    [
                        -71.20243092,
                        42.18841356
                    ],
                    [
                        -71.20243133,
                        42.18841281
                    ],
                    [
                        -71.2024314,
                        42.18841254
                    ],
                    [
                        -71.20243145,
                        42.18841231
                    ],
                    [
                        -71.20243171,
                        42.18841172
                    ],
                    [
                        -71.20243176,
                        42.18841146
                    ],
                    [
                        -71.20243191,
                        42.18841053
                    ],
                    [
                        -71.20243198,
                        42.18841029
                    ],
                    [
                        -71.20243212,
                        42.1884099
                    ],
                    [
                        -71.20243217,
                        42.18840971
                    ],
                    [
                        -71.20243246,
                        42.18840839
                    ],
                    [
                        -71.20243263,
                        42.18840829
                    ],
                    [
                        -71.20243269,
                        42.1884081
                    ],
                    [
                        -71.20243281,
                        42.18840703
                    ],
                    [
                        -71.20243285,
                        42.18840688
                    ],
                    [
                        -71.20243287,
                        42.1884066
                    ],
                    [
                        -71.20243291,
                        42.18840643
                    ],
                    [
                        -71.20243303,
                        42.18840617
                    ],
                    [
                        -71.20243318,
                        42.18840556
                    ],
                    [
                        -71.20243322,
                        42.18840505
                    ],
                    [
                        -71.20243327,
                        42.18840484
                    ],
                    [
                        -71.20243335,
                        42.18840445
                    ],
                    [
                        -71.20243334,
                        42.18840327
                    ],
                    [
                        -71.20243336,
                        42.18840303
                    ],
                    [
                        -71.2024334,
                        42.18840281
                    ],
                    [
                        -71.20243342,
                        42.18840263
                    ],
                    [
                        -71.20243343,
                        42.18840245
                    ],
                    [
                        -71.20243345,
                        42.18840163
                    ],
                    [
                        -71.20243348,
                        42.18840111
                    ],
                    [
                        -71.20243366,
                        42.18840104
                    ],
                    [
                        -71.20243357,
                        42.18839971
                    ],
                    [
                        -71.20243358,
                        42.18839956
                    ],
                    [
                        -71.20243355,
                        42.18839929
                    ],
                    [
                        -71.20243356,
                        42.1883991
                    ],
                    [
                        -71.20243355,
                        42.18839891
                    ],
                    [
                        -71.20243364,
                        42.18839866
                    ],
                    [
                        -71.20243368,
                        42.1883982
                    ],
                    [
                        -71.20243364,
                        42.18839774
                    ],
                    [
                        -71.20243362,
                        42.18839748
                    ],
                    [
                        -71.20243363,
                        42.18839725
                    ],
                    [
                        -71.20243364,
                        42.18839708
                    ],
                    [
                        -71.20243357,
                        42.18839683
                    ],
                    [
                        -71.20243352,
                        42.18839612
                    ],
                    [
                        -71.20243348,
                        42.18839553
                    ],
                    [
                        -71.20243335,
                        42.18839528
                    ],
                    [
                        -71.2024334,
                        42.18839451
                    ],
                    [
                        -71.20243334,
                        42.18839424
                    ],
                    [
                        -71.20243331,
                        42.18839409
                    ],
                    [
                        -71.20243328,
                        42.18839375
                    ],
                    [
                        -71.20243328,
                        42.18839361
                    ],
                    [
                        -71.20243325,
                        42.18839341
                    ],
                    [
                        -71.20243321,
                        42.18839325
                    ],
                    [
                        -71.20243318,
                        42.18839309
                    ],
                    [
                        -71.20243292,
                        42.18839229
                    ],
                    [
                        -71.20243288,
                        42.18839201
                    ],
                    [
                        -71.20243285,
                        42.18839184
                    ],
                    [
                        -71.20243283,
                        42.18839168
                    ],
                    [
                        -71.20243264,
                        42.18839105
                    ],
                    [
                        -71.20243265,
                        42.18839041
                    ],
                    [
                        -71.20243248,
                        42.18839027
                    ],
                    [
                        -71.20243246,
                        42.18839021
                    ],
                    [
                        -71.2024326,
                        42.18839009
                    ],
                    [
                        -71.20243251,
                        42.1883897
                    ],
                    [
                        -71.20243232,
                        42.1883896
                    ],
                    [
                        -71.20243211,
                        42.18838859
                    ],
                    [
                        -71.20243195,
                        42.18838813
                    ],
                    [
                        -71.20243191,
                        42.18838766
                    ],
                    [
                        -71.20243182,
                        42.18838723
                    ],
                    [
                        -71.20243172,
                        42.18838698
                    ],
                    [
                        -71.2024316,
                        42.18838672
                    ],
                    [
                        -71.20243156,
                        42.18838645
                    ],
                    [
                        -71.20243151,
                        42.18838632
                    ],
                    [
                        -71.20243147,
                        42.18838616
                    ],
                    [
                        -71.20243079,
                        42.18838475
                    ],
                    [
                        -71.20243063,
                        42.18838431
                    ],
                    [
                        -71.20242992,
                        42.18838267
                    ],
                    [
                        -71.20242992,
                        42.18838235
                    ],
                    [
                        -71.20242972,
                        42.18838195
                    ],
                    [
                        -71.20242938,
                        42.18838138
                    ],
                    [
                        -71.20242923,
                        42.18838101
                    ],
                    [
                        -71.20242913,
                        42.18838078
                    ],
                    [
                        -71.20242895,
                        42.18838046
                    ],
                    [
                        -71.20242889,
                        42.18838035
                    ],
                    [
                        -71.2024289,
                        42.1883802
                    ],
                    [
                        -71.2024287,
                        42.18837991
                    ],
                    [
                        -71.20242861,
                        42.18837971
                    ],
                    [
                        -71.20242851,
                        42.18837948
                    ],
                    [
                        -71.20242837,
                        42.18837926
                    ],
                    [
                        -71.20242827,
                        42.18837909
                    ],
                    [
                        -71.20242815,
                        42.18837892
                    ],
                    [
                        -71.20242803,
                        42.18837869
                    ],
                    [
                        -71.20242795,
                        42.18837852
                    ],
                    [
                        -71.20242738,
                        42.18837775
                    ],
                    [
                        -71.20242693,
                        42.18837678
                    ],
                    [
                        -71.20242679,
                        42.18837658
                    ],
                    [
                        -71.20242665,
                        42.18837639
                    ],
                    [
                        -71.20242652,
                        42.18837614
                    ],
                    [
                        -71.20242641,
                        42.18837596
                    ],
                    [
                        -71.20242552,
                        42.1883749
                    ],
                    [
                        -71.2024254,
                        42.18837475
                    ],
                    [
                        -71.20242504,
                        42.18837407
                    ],
                    [
                        -71.20242436,
                        42.18837313
                    ],
                    [
                        -71.20242428,
                        42.188373
                    ],
                    [
                        -71.20242411,
                        42.18837279
                    ],
                    [
                        -71.20242314,
                        42.18837172
                    ],
                    [
                        -71.20242247,
                        42.18837082
                    ],
                    [
                        -71.20242236,
                        42.18837068
                    ],
                    [
                        -71.2024221,
                        42.18837034
                    ],
                    [
                        -71.20242185,
                        42.18837009
                    ],
                    [
                        -71.20242156,
                        42.18836975
                    ],
                    [
                        -71.20242036,
                        42.18836863
                    ],
                    [
                        -71.20242014,
                        42.18836837
                    ],
                    [
                        -71.20241996,
                        42.1883682
                    ],
                    [
                        -71.20241885,
                        42.18836714
                    ],
                    [
                        -71.20241874,
                        42.18836682
                    ],
                    [
                        -71.20241832,
                        42.18836662
                    ],
                    [
                        -71.20241734,
                        42.18836567
                    ],
                    [
                        -71.20241708,
                        42.18836544
                    ],
                    [
                        -71.20241611,
                        42.18836444
                    ],
                    [
                        -71.20241582,
                        42.18836424
                    ],
                    [
                        -71.20241565,
                        42.18836407
                    ],
                    [
                        -71.20241409,
                        42.18836295
                    ],
                    [
                        -71.20241389,
                        42.18836278
                    ],
                    [
                        -71.20241372,
                        42.18836266
                    ],
                    [
                        -71.20241219,
                        42.18836154
                    ],
                    [
                        -71.20241224,
                        42.18836139
                    ],
                    [
                        -71.20241144,
                        42.18836099
                    ],
                    [
                        -71.20241055,
                        42.18836033
                    ],
                    [
                        -71.20241037,
                        42.18836022
                    ],
                    [
                        -71.20240907,
                        42.18835923
                    ],
                    [
                        -71.20240875,
                        42.18835904
                    ],
                    [
                        -71.20240683,
                        42.18835798
                    ],
                    [
                        -71.20240499,
                        42.18835679
                    ],
                    [
                        -71.20240435,
                        42.18835647
                    ],
                    [
                        -71.20240299,
                        42.18835576
                    ],
                    [
                        -71.20240281,
                        42.18835573
                    ],
                    [
                        -71.20240258,
                        42.18835555
                    ],
                    [
                        -71.20240147,
                        42.18835498
                    ],
                    [
                        -71.20240127,
                        42.18835487
                    ],
                    [
                        -71.20240102,
                        42.18835474
                    ],
                    [
                        -71.20240083,
                        42.18835466
                    ],
                    [
                        -71.20240057,
                        42.18835453
                    ],
                    [
                        -71.20240018,
                        42.18835437
                    ],
                    [
                        -71.20239868,
                        42.18835378
                    ],
                    [
                        -71.20239838,
                        42.18835364
                    ],
                    [
                        -71.20239684,
                        42.18835288
                    ],
                    [
                        -71.20239655,
                        42.18835278
                    ],
                    [
                        -71.20239631,
                        42.18835267
                    ],
                    [
                        -71.20239544,
                        42.18835234
                    ],
                    [
                        -71.2023947,
                        42.18835214
                    ],
                    [
                        -71.20239436,
                        42.18835202
                    ],
                    [
                        -71.20239331,
                        42.18835156
                    ],
                    [
                        -71.20239246,
                        42.18835122
                    ],
                    [
                        -71.20239224,
                        42.18835116
                    ],
                    [
                        -71.20239191,
                        42.18835103
                    ],
                    [
                        -71.20239107,
                        42.18835074
                    ],
                    [
                        -71.20239023,
                        42.18835059
                    ],
                    [
                        -71.20238982,
                        42.18835045
                    ],
                    [
                        -71.20238926,
                        42.18835025
                    ],
                    [
                        -71.20238783,
                        42.18834975
                    ],
                    [
                        -71.20238602,
                        42.18834938
                    ],
                    [
                        -71.20238523,
                        42.18834916
                    ],
                    [
                        -71.20238498,
                        42.18834911
                    ],
                    [
                        -71.20238311,
                        42.18834857
                    ],
                    [
                        -71.20238275,
                        42.18834851
                    ],
                    [
                        -71.2023826,
                        42.18834843
                    ],
                    [
                        -71.20238225,
                        42.18834837
                    ],
                    [
                        -71.20238194,
                        42.18834829
                    ],
                    [
                        -71.20238158,
                        42.18834823
                    ],
                    [
                        -71.20238109,
                        42.18834821
                    ],
                    [
                        -71.20238046,
                        42.18834807
                    ],
                    [
                        -71.2023802,
                        42.18834801
                    ],
                    [
                        -71.20237858,
                        42.18834764
                    ],
                    [
                        -71.20237847,
                        42.18834769
                    ],
                    [
                        -71.20237838,
                        42.18834759
                    ],
                    [
                        -71.2023781,
                        42.18834755
                    ],
                    [
                        -71.20237788,
                        42.18834751
                    ],
                    [
                        -71.20237752,
                        42.18834747
                    ],
                    [
                        -71.20237676,
                        42.18834746
                    ],
                    [
                        -71.20237549,
                        42.18834723
                    ],
                    [
                        -71.20237483,
                        42.18834716
                    ],
                    [
                        -71.20237442,
                        42.18834706
                    ],
                    [
                        -71.20237396,
                        42.18834697
                    ],
                    [
                        -71.20237354,
                        42.1883469
                    ],
                    [
                        -71.20237305,
                        42.18834688
                    ],
                    [
                        -71.20237272,
                        42.18834681
                    ],
                    [
                        -71.2023725,
                        42.18834679
                    ],
                    [
                        -71.20237141,
                        42.18834676
                    ],
                    [
                        -71.20237082,
                        42.18834668
                    ],
                    [
                        -71.20237058,
                        42.18834667
                    ],
                    [
                        -71.20236849,
                        42.18834641
                    ],
                    [
                        -71.20236831,
                        42.18834641
                    ],
                    [
                        -71.2023681,
                        42.18834638
                    ],
                    [
                        -71.2023676,
                        42.1883465
                    ],
                    [
                        -71.20236726,
                        42.18834648
                    ],
                    [
                        -71.20236586,
                        42.18834637
                    ],
                    [
                        -71.20236565,
                        42.18834636
                    ],
                    [
                        -71.20236535,
                        42.18834635
                    ],
                    [
                        -71.20236487,
                        42.18834635
                    ],
                    [
                        -71.20236364,
                        42.18834624
                    ],
                    [
                        -71.20236318,
                        42.1883462
                    ],
                    [
                        -71.20236298,
                        42.18834624
                    ],
                    [
                        -71.20236268,
                        42.18834622
                    ],
                    [
                        -71.20236208,
                        42.18834625
                    ],
                    [
                        -71.20236187,
                        42.18834625
                    ],
                    [
                        -71.20236096,
                        42.1883463
                    ],
                    [
                        -71.20236074,
                        42.18834629
                    ],
                    [
                        -71.20235964,
                        42.18834628
                    ],
                    [
                        -71.20235941,
                        42.18834627
                    ],
                    [
                        -71.20235915,
                        42.18834628
                    ],
                    [
                        -71.20235889,
                        42.18834627
                    ],
                    [
                        -71.20235864,
                        42.18834628
                    ],
                    [
                        -71.20235825,
                        42.18834626
                    ],
                    [
                        -71.20235758,
                        42.18834634
                    ],
                    [
                        -71.20235736,
                        42.18834636
                    ],
                    [
                        -71.20235717,
                        42.18834636
                    ],
                    [
                        -71.20235672,
                        42.1883464
                    ],
                    [
                        -71.2023558,
                        42.18834649
                    ],
                    [
                        -71.20235514,
                        42.18834655
                    ],
                    [
                        -71.20235488,
                        42.18834657
                    ],
                    [
                        -71.20235427,
                        42.18834655
                    ],
                    [
                        -71.20235396,
                        42.18834656
                    ],
                    [
                        -71.20235365,
                        42.18834659
                    ],
                    [
                        -71.2023533,
                        42.18834664
                    ],
                    [
                        -71.20235296,
                        42.18834665
                    ],
                    [
                        -71.20235166,
                        42.18834686
                    ],
                    [
                        -71.20235124,
                        42.18834691
                    ],
                    [
                        -71.2023495,
                        42.18834706
                    ],
                    [
                        -71.20234866,
                        42.18834712
                    ],
                    [
                        -71.20234843,
                        42.18834716
                    ],
                    [
                        -71.20234787,
                        42.1883474
                    ],
                    [
                        -71.20234595,
                        42.18834765
                    ],
                    [
                        -71.20234569,
                        42.1883477
                    ],
                    [
                        -71.20234553,
                        42.18834774
                    ],
                    [
                        -71.20234526,
                        42.18834778
                    ],
                    [
                        -71.20234385,
                        42.18834795
                    ],
                    [
                        -71.2023435,
                        42.18834804
                    ],
                    [
                        -71.20234255,
                        42.18834832
                    ],
                    [
                        -71.20234186,
                        42.18834845
                    ],
                    [
                        -71.20234167,
                        42.18834849
                    ],
                    [
                        -71.20234048,
                        42.18834879
                    ],
                    [
                        -71.20233889,
                        42.18834903
                    ],
                    [
                        -71.20233853,
                        42.18834914
                    ],
                    [
                        -71.20233804,
                        42.1883494
                    ],
                    [
                        -71.20233644,
                        42.18834981
                    ],
                    [
                        -71.20233626,
                        42.18834986
                    ],
                    [
                        -71.20233607,
                        42.18834991
                    ],
                    [
                        -71.2023344,
                        42.18835032
                    ],
                    [
                        -71.20233418,
                        42.18835039
                    ],
                    [
                        -71.20233239,
                        42.18835105
                    ],
                    [
                        -71.20233222,
                        42.18835112
                    ],
                    [
                        -71.20233021,
                        42.18835185
                    ],
                    [
                        -71.20232983,
                        42.18835184
                    ],
                    [
                        -71.2023258,
                        42.18835349
                    ],
                    [
                        -71.20232548,
                        42.18835361
                    ],
                    [
                        -71.2023227,
                        42.18835448
                    ],
                    [
                        -71.20232233,
                        42.18835451
                    ],
                    [
                        -71.20232206,
                        42.18835459
                    ],
                    [
                        -71.20232128,
                        42.18835461
                    ],
                    [
                        -71.20231972,
                        42.18835474
                    ],
                    [
                        -71.20231897,
                        42.18835465
                    ],
                    [
                        -71.20231876,
                        42.18835466
                    ],
                    [
                        -71.20231841,
                        42.18835467
                    ],
                    [
                        -71.20231813,
                        42.1883546
                    ],
                    [
                        -71.20231778,
                        42.18835451
                    ],
                    [
                        -71.20231746,
                        42.18835453
                    ],
                    [
                        -71.20231718,
                        42.18835445
                    ],
                    [
                        -71.20231685,
                        42.18835441
                    ],
                    [
                        -71.20231604,
                        42.18835416
                    ],
                    [
                        -71.20231553,
                        42.18835397
                    ],
                    [
                        -71.20231523,
                        42.1883539
                    ],
                    [
                        -71.20231483,
                        42.18835378
                    ],
                    [
                        -71.20231367,
                        42.18835317
                    ],
                    [
                        -71.20231283,
                        42.18835277
                    ],
                    [
                        -71.20231257,
                        42.18835256
                    ],
                    [
                        -71.20231234,
                        42.18835242
                    ],
                    [
                        -71.2023122,
                        42.18835229
                    ],
                    [
                        -71.20231202,
                        42.18835219
                    ],
                    [
                        -71.2023116,
                        42.18835172
                    ],
                    [
                        -71.20231086,
                        42.18835108
                    ],
                    [
                        -71.2023107,
                        42.18835083
                    ],
                    [
                        -71.20231047,
                        42.1883506
                    ],
                    [
                        -71.20231009,
                        42.18834986
                    ],
                    [
                        -71.20231001,
                        42.18834972
                    ],
                    [
                        -71.20230959,
                        42.18834906
                    ],
                    [
                        -71.20230954,
                        42.18834879
                    ],
                    [
                        -71.2023094,
                        42.18834853
                    ],
                    [
                        -71.20230932,
                        42.18834775
                    ],
                    [
                        -71.20230912,
                        42.18834685
                    ],
                    [
                        -71.20230912,
                        42.18834651
                    ],
                    [
                        -71.20230891,
                        42.18834381
                    ],
                    [
                        -71.20230886,
                        42.18834319
                    ],
                    [
                        -71.20230884,
                        42.18834293
                    ],
                    [
                        -71.20230849,
                        42.18834138
                    ],
                    [
                        -71.20230846,
                        42.18834117
                    ],
                    [
                        -71.20230827,
                        42.18833956
                    ],
                    [
                        -71.20230788,
                        42.18833849
                    ],
                    [
                        -71.20230774,
                        42.18833784
                    ],
                    [
                        -71.20230733,
                        42.18833595
                    ],
                    [
                        -71.20230664,
                        42.18833423
                    ],
                    [
                        -71.20230607,
                        42.18833241
                    ],
                    [
                        -71.20230566,
                        42.18833182
                    ],
                    [
                        -71.20230525,
                        42.18833079
                    ],
                    [
                        -71.20230451,
                        42.188329
                    ],
                    [
                        -71.20230345,
                        42.18832729
                    ],
                    [
                        -71.20230257,
                        42.18832557
                    ],
                    [
                        -71.2023006,
                        42.18832265
                    ],
                    [
                        -71.20230049,
                        42.18832249
                    ],
                    [
                        -71.20230036,
                        42.18832229
                    ],
                    [
                        -71.20229811,
                        42.18831969
                    ],
                    [
                        -71.20229767,
                        42.18831918
                    ],
                    [
                        -71.20229525,
                        42.18831635
                    ],
                    [
                        -71.2022951,
                        42.1883162
                    ],
                    [
                        -71.20229487,
                        42.18831598
                    ],
                    [
                        -71.20229275,
                        42.18831415
                    ],
                    [
                        -71.20229212,
                        42.1883134
                    ],
                    [
                        -71.20228905,
                        42.1883109
                    ],
                    [
                        -71.20228884,
                        42.18831074
                    ],
                    [
                        -71.20228864,
                        42.1883106
                    ],
                    [
                        -71.20228834,
                        42.18831038
                    ],
                    [
                        -71.20228537,
                        42.18830826
                    ],
                    [
                        -71.2022848,
                        42.18830805
                    ],
                    [
                        -71.20228323,
                        42.18830702
                    ],
                    [
                        -71.20228128,
                        42.18830575
                    ],
                    [
                        -71.2022795,
                        42.18830486
                    ],
                    [
                        -71.20227779,
                        42.18830387
                    ],
                    [
                        -71.20227825,
                        42.18830305
                    ],
                    [
                        -71.202275,
                        42.18830201
                    ],
                    [
                        -71.20227476,
                        42.18830243
                    ],
                    [
                        -71.20227391,
                        42.18830209
                    ],
                    [
                        -71.20227316,
                        42.18830171
                    ],
                    [
                        -71.20227297,
                        42.18830163
                    ],
                    [
                        -71.20227279,
                        42.18830156
                    ],
                    [
                        -71.20226985,
                        42.18830052
                    ],
                    [
                        -71.20226877,
                        42.18829999
                    ],
                    [
                        -71.20226759,
                        42.18829964
                    ],
                    [
                        -71.20226642,
                        42.18829919
                    ],
                    [
                        -71.20226519,
                        42.18829886
                    ],
                    [
                        -71.20226406,
                        42.18829845
                    ],
                    [
                        -71.20226276,
                        42.1882982
                    ],
                    [
                        -71.20226179,
                        42.1882979
                    ],
                    [
                        -71.20226086,
                        42.18829769
                    ],
                    [
                        -71.20225957,
                        42.18829725
                    ],
                    [
                        -71.20225848,
                        42.18829704
                    ],
                    [
                        -71.20225734,
                        42.18829674
                    ],
                    [
                        -71.20225705,
                        42.18829678
                    ],
                    [
                        -71.20225525,
                        42.18829635
                    ],
                    [
                        -71.20225465,
                        42.18829621
                    ],
                    [
                        -71.20225413,
                        42.18829614
                    ],
                    [
                        -71.20225269,
                        42.18829587
                    ],
                    [
                        -71.20225227,
                        42.1882959
                    ],
                    [
                        -71.2022513,
                        42.18829572
                    ],
                    [
                        -71.20224995,
                        42.18829547
                    ],
                    [
                        -71.20224851,
                        42.18829541
                    ],
                    [
                        -71.20224743,
                        42.18829527
                    ],
                    [
                        -71.20224673,
                        42.18829521
                    ],
                    [
                        -71.20224491,
                        42.18829495
                    ],
                    [
                        -71.20224406,
                        42.18829492
                    ],
                    [
                        -71.20224317,
                        42.18829482
                    ],
                    [
                        -71.20224248,
                        42.18829488
                    ],
                    [
                        -71.20224073,
                        42.18829474
                    ],
                    [
                        -71.20224011,
                        42.1882947
                    ],
                    [
                        -71.20223963,
                        42.18829471
                    ],
                    [
                        -71.20223837,
                        42.18829466
                    ],
                    [
                        -71.20223756,
                        42.18829475
                    ],
                    [
                        -71.20223669,
                        42.18829473
                    ],
                    [
                        -71.20223503,
                        42.18829469
                    ],
                    [
                        -71.20223391,
                        42.18829484
                    ],
                    [
                        -71.20223261,
                        42.18829487
                    ],
                    [
                        -71.20223202,
                        42.18829492
                    ],
                    [
                        -71.2022302,
                        42.18829493
                    ],
                    [
                        -71.2022295,
                        42.18829502
                    ],
                    [
                        -71.20222877,
                        42.18829505
                    ],
                    [
                        -71.20222771,
                        42.18829526
                    ],
                    [
                        -71.20222697,
                        42.18829531
                    ],
                    [
                        -71.20222519,
                        42.18829544
                    ],
                    [
                        -71.20222414,
                        42.18829571
                    ],
                    [
                        -71.20222052,
                        42.18829617
                    ],
                    [
                        -71.20222026,
                        42.18829622
                    ],
                    [
                        -71.20221985,
                        42.18829632
                    ],
                    [
                        -71.20221968,
                        42.18829634
                    ],
                    [
                        -71.20221933,
                        42.18829639
                    ],
                    [
                        -71.20221737,
                        42.18829682
                    ],
                    [
                        -71.20221567,
                        42.18829718
                    ],
                    [
                        -71.20221538,
                        42.18829726
                    ],
                    [
                        -71.2022147,
                        42.18829741
                    ],
                    [
                        -71.20221333,
                        42.18829787
                    ],
                    [
                        -71.20221281,
                        42.18829799
                    ],
                    [
                        -71.20221093,
                        42.18829843
                    ],
                    [
                        -71.2022099,
                        42.18829888
                    ],
                    [
                        -71.20220873,
                        42.18829922
                    ],
                    [
                        -71.20220849,
                        42.18829931
                    ],
                    [
                        -71.20220646,
                        42.18829989
                    ],
                    [
                        -71.20220603,
                        42.18830006
                    ],
                    [
                        -71.20220581,
                        42.18830012
                    ],
                    [
                        -71.20220536,
                        42.18830031
                    ],
                    [
                        -71.20220391,
                        42.18830094
                    ],
                    [
                        -71.20220203,
                        42.18830161
                    ],
                    [
                        -71.20219986,
                        42.18830267
                    ],
                    [
                        -71.20219749,
                        42.1883037
                    ],
                    [
                        -71.20219398,
                        42.18830557
                    ],
                    [
                        -71.20219374,
                        42.1883057
                    ],
                    [
                        -71.20219358,
                        42.18830578
                    ],
                    [
                        -71.20218653,
                        42.18831044
                    ],
                    [
                        -71.20218001,
                        42.18830561
                    ],
                    [
                        -71.20217293,
                        42.18830104
                    ],
                    [
                        -71.20216545,
                        42.18829683
                    ],
                    [
                        -71.20215761,
                        42.188293
                    ],
                    [
                        -71.20214946,
                        42.18828957
                    ],
                    [
                        -71.202141,
                        42.18828655
                    ],
                    [
                        -71.20213229,
                        42.18828395
                    ],
                    [
                        -71.20212335,
                        42.18828181
                    ],
                    [
                        -71.20211425,
                        42.1882801
                    ],
                    [
                        -71.20210848,
                        42.18827932
                    ],
                    [
                        -71.20210502,
                        42.18827886
                    ],
                    [
                        -71.20209568,
                        42.18827807
                    ],
                    [
                        -71.20208631,
                        42.18827776
                    ],
                    [
                        -71.20207692,
                        42.18827791
                    ],
                    [
                        -71.20206757,
                        42.18827853
                    ],
                    [
                        -71.20206742,
                        42.18827854
                    ],
                    [
                        -71.20206737,
                        42.18827824
                    ],
                    [
                        -71.20206536,
                        42.18826635
                    ],
                    [
                        -71.20199377,
                        42.18823951
                    ],
                    [
                        -71.20199142,
                        42.1882386
                    ],
                    [
                        -71.20199106,
                        42.18823699
                    ],
                    [
                        -71.20199015,
                        42.1882336
                    ],
                    [
                        -71.20198739,
                        42.188227
                    ],
                    [
                        -71.20198346,
                        42.18822074
                    ],
                    [
                        -71.20197843,
                        42.18821493
                    ],
                    [
                        -71.20197239,
                        42.18820968
                    ],
                    [
                        -71.20196901,
                        42.18820729
                    ],
                    [
                        -71.20196166,
                        42.18820304
                    ],
                    [
                        -71.2019536,
                        42.18819958
                    ],
                    [
                        -71.20194499,
                        42.18819695
                    ],
                    [
                        -71.20193597,
                        42.1881952
                    ],
                    [
                        -71.20192671,
                        42.18819438
                    ],
                    [
                        -71.20192204,
                        42.18819432
                    ],
                    [
                        -71.20191275,
                        42.18819489
                    ],
                    [
                        -71.20190367,
                        42.18819639
                    ],
                    [
                        -71.20189492,
                        42.18819879
                    ],
                    [
                        -71.20189073,
                        42.18820031
                    ],
                    [
                        -71.20189008,
                        42.18820057
                    ],
                    [
                        -71.20188765,
                        42.18819985
                    ],
                    [
                        -71.20181601,
                        42.18817316
                    ],
                    [
                        -71.20180411,
                        42.18817751
                    ],
                    [
                        -71.20180373,
                        42.18817765
                    ],
                    [
                        -71.20180367,
                        42.18817754
                    ],
                    [
                        -71.20180357,
                        42.18817739
                    ],
                    [
                        -71.20180348,
                        42.18817725
                    ],
                    [
                        -71.20180031,
                        42.18817292
                    ],
                    [
                        -71.20179909,
                        42.18817126
                    ],
                    [
                        -71.20179885,
                        42.18817095
                    ],
                    [
                        -71.20179826,
                        42.18817022
                    ],
                    [
                        -71.20179703,
                        42.18816873
                    ],
                    [
                        -71.20179576,
                        42.18816726
                    ],
                    [
                        -71.20179447,
                        42.18816581
                    ],
                    [
                        -71.20179391,
                        42.18816519
                    ],
                    [
                        -71.20179377,
                        42.18816505
                    ],
                    [
                        -71.20179356,
                        42.18816482
                    ],
                    [
                        -71.20179314,
                        42.18816437
                    ],
                    [
                        -71.20179296,
                        42.18816417
                    ],
                    [
                        -71.20179178,
                        42.18816294
                    ],
                    [
                        -71.20179039,
                        42.18816154
                    ],
                    [
                        -71.20178896,
                        42.18816016
                    ],
                    [
                        -71.20178818,
                        42.18815942
                    ],
                    [
                        -71.20178795,
                        42.18815921
                    ],
                    [
                        -71.20178776,
                        42.18815902
                    ],
                    [
                        -71.20178711,
                        42.18815842
                    ],
                    [
                        -71.20178602,
                        42.18815744
                    ],
                    [
                        -71.2017845,
                        42.18815611
                    ],
                    [
                        -71.20178295,
                        42.18815479
                    ],
                    [
                        -71.20178193,
                        42.18815396
                    ],
                    [
                        -71.20178137,
                        42.18815351
                    ],
                    [
                        -71.20178073,
                        42.18815298
                    ],
                    [
                        -71.20177977,
                        42.18815223
                    ],
                    [
                        -71.20177814,
                        42.18815098
                    ],
                    [
                        -71.20177648,
                        42.18814975
                    ],
                    [
                        -71.20177531,
                        42.18814892
                    ],
                    [
                        -71.20177478,
                        42.18814853
                    ],
                    [
                        -71.20177445,
                        42.18814831
                    ],
                    [
                        -71.20177386,
                        42.18814789
                    ],
                    [
                        -71.20177306,
                        42.18814734
                    ],
                    [
                        -71.20177132,
                        42.18814618
                    ],
                    [
                        -71.20176955,
                        42.18814503
                    ],
                    [
                        -71.20176799,
                        42.18814406
                    ],
                    [
                        -71.20176776,
                        42.18814391
                    ],
                    [
                        -71.20176726,
                        42.1881436
                    ],
                    [
                        -71.20176652,
                        42.18814316
                    ],
                    [
                        -71.20176593,
                        42.18814281
                    ],
                    [
                        -71.20176408,
                        42.18814173
                    ],
                    [
                        -71.20176221,
                        42.18814067
                    ],
                    [
                        -71.20176032,
                        42.18813965
                    ],
                    [
                        -71.20175951,
                        42.18813922
                    ],
                    [
                        -71.20175876,
                        42.18813883
                    ],
                    [
                        -71.2017584,
                        42.18813864
                    ],
                    [
                        -71.20175646,
                        42.18813765
                    ],
                    [
                        -71.20175449,
                        42.18813669
                    ],
                    [
                        -71.20175251,
                        42.18813576
                    ],
                    [
                        -71.20175233,
                        42.18813568
                    ],
                    [
                        -71.20175154,
                        42.18813534
                    ],
                    [
                        -71.20175139,
                        42.18813524
                    ],
                    [
                        -71.2017505,
                        42.18813484
                    ],
                    [
                        -71.20174848,
                        42.18813396
                    ],
                    [
                        -71.20174643,
                        42.1881331
                    ],
                    [
                        -71.20174437,
                        42.18813227
                    ],
                    [
                        -71.20174396,
                        42.18813211
                    ],
                    [
                        -71.20174288,
                        42.18813169
                    ],
                    [
                        -71.20174229,
                        42.18813146
                    ],
                    [
                        -71.20174212,
                        42.18813139
                    ],
                    [
                        -71.20174018,
                        42.18813068
                    ],
                    [
                        -71.20173807,
                        42.18812992
                    ],
                    [
                        -71.20173594,
                        42.1881292
                    ],
                    [
                        -71.20173527,
                        42.18812897
                    ],
                    [
                        -71.20173454,
                        42.18812874
                    ],
                    [
                        -71.20173408,
                        42.18812858
                    ],
                    [
                        -71.20173372,
                        42.18812848
                    ],
                    [
                        -71.20173331,
                        42.18812834
                    ],
                    [
                        -71.20173161,
                        42.18812782
                    ],
                    [
                        -71.20172943,
                        42.18812717
                    ],
                    [
                        -71.20172723,
                        42.18812654
                    ],
                    [
                        -71.20172631,
                        42.1881263
                    ],
                    [
                        -71.20172503,
                        42.18812595
                    ],
                    [
                        -71.20172423,
                        42.18812574
                    ],
                    [
                        -71.2017228,
                        42.18812539
                    ],
                    [
                        -71.20172057,
                        42.18812484
                    ],
                    [
                        -71.20171832,
                        42.18812434
                    ],
                    [
                        -71.20171712,
                        42.18812408
                    ],
                    [
                        -71.20171605,
                        42.18812386
                    ],
                    [
                        -71.20171562,
                        42.18812377
                    ],
                    [
                        -71.20171494,
                        42.18812363
                    ],
                    [
                        -71.20171378,
                        42.1881234
                    ],
                    [
                        -71.20171151,
                        42.18812297
                    ],
                    [
                        -71.20170921,
                        42.18812258
                    ],
                    [
                        -71.20170782,
                        42.18812235
                    ],
                    [
                        -71.20170692,
                        42.18812221
                    ],
                    [
                        -71.20170622,
                        42.1881221
                    ],
                    [
                        -71.20170602,
                        42.18812207
                    ],
                    [
                        -71.20170547,
                        42.18812199
                    ],
                    [
                        -71.20170461,
                        42.18812187
                    ],
                    [
                        -71.2017023,
                        42.18812156
                    ],
                    [
                        -71.20169998,
                        42.18812128
                    ],
                    [
                        -71.20169823,
                        42.18812108
                    ],
                    [
                        -71.20169765,
                        42.18812102
                    ],
                    [
                        -71.2016966,
                        42.18812092
                    ],
                    [
                        -71.20169629,
                        42.18812088
                    ],
                    [
                        -71.20169588,
                        42.18812085
                    ],
                    [
                        -71.20169533,
                        42.1881208
                    ],
                    [
                        -71.20169298,
                        42.1881206
                    ],
                    [
                        -71.20169064,
                        42.18812044
                    ],
                    [
                        -71.20168863,
                        42.18812032
                    ],
                    [
                        -71.20168831,
                        42.1881203
                    ],
                    [
                        -71.2016869,
                        42.18812023
                    ],
                    [
                        -71.20168646,
                        42.18812022
                    ],
                    [
                        -71.20168619,
                        42.1881202
                    ],
                    [
                        -71.20168595,
                        42.18812019
                    ],
                    [
                        -71.2016836,
                        42.18812012
                    ],
                    [
                        -71.20168126,
                        42.18812007
                    ],
                    [
                        -71.2016789,
                        42.18812005
                    ],
                    [
                        -71.20167798,
                        42.18812007
                    ],
                    [
                        -71.20167781,
                        42.18812008
                    ],
                    [
                        -71.20167716,
                        42.18812005
                    ],
                    [
                        -71.20167655,
                        42.18812006
                    ],
                    [
                        -71.2016742,
                        42.18812009
                    ],
                    [
                        -71.20167186,
                        42.18812016
                    ],
                    [
                        -71.20166951,
                        42.18812026
                    ],
                    [
                        -71.2016693,
                        42.18812028
                    ],
                    [
                        -71.20166838,
                        42.18812035
                    ],
                    [
                        -71.20166749,
                        42.18812037
                    ],
                    [
                        -71.20166668,
                        42.18812042
                    ],
                    [
                        -71.20166481,
                        42.18812054
                    ],
                    [
                        -71.20166247,
                        42.18812073
                    ],
                    [
                        -71.20166015,
                        42.18812095
                    ],
                    [
                        -71.2016598,
                        42.18812098
                    ],
                    [
                        -71.20165782,
                        42.18812119
                    ],
                    [
                        -71.20165711,
                        42.18812126
                    ],
                    [
                        -71.2016555,
                        42.18812146
                    ],
                    [
                        -71.20165318,
                        42.18812176
                    ],
                    [
                        -71.20165087,
                        42.18812209
                    ],
                    [
                        -71.20165027,
                        42.18812218
                    ],
                    [
                        -71.20164856,
                        42.18812245
                    ],
                    [
                        -71.20164805,
                        42.18812254
                    ],
                    [
                        -71.20164756,
                        42.18812262
                    ],
                    [
                        -71.20164627,
                        42.18812284
                    ],
                    [
                        -71.201644,
                        42.18812325
                    ],
                    [
                        -71.20164172,
                        42.1881237
                    ],
                    [
                        -71.20164089,
                        42.18812387
                    ],
                    [
                        -71.20163946,
                        42.18812417
                    ],
                    [
                        -71.20163871,
                        42.18812433
                    ],
                    [
                        -71.20163851,
                        42.18812438
                    ],
                    [
                        -71.20163816,
                        42.18812445
                    ],
                    [
                        -71.2016372,
                        42.18812467
                    ],
                    [
                        -71.20163497,
                        42.1881252
                    ],
                    [
                        -71.20163274,
                        42.18812576
                    ],
                    [
                        -71.20163168,
                        42.18812603
                    ],
                    [
                        -71.20163053,
                        42.18812634
                    ],
                    [
                        -71.20162947,
                        42.18812663
                    ],
                    [
                        -71.20162916,
                        42.18812671
                    ],
                    [
                        -71.20162895,
                        42.18812678
                    ],
                    [
                        -71.20162832,
                        42.18812695
                    ],
                    [
                        -71.20162613,
                        42.18812759
                    ],
                    [
                        -71.20162396,
                        42.18812826
                    ],
                    [
                        -71.20162259,
                        42.1881287
                    ],
                    [
                        -71.2016218,
                        42.18812895
                    ],
                    [
                        -71.20162045,
                        42.1881294
                    ],
                    [
                        -71.20162008,
                        42.18812953
                    ],
                    [
                        -71.20161967,
                        42.18812968
                    ],
                    [
                        -71.20161754,
                        42.18813042
                    ],
                    [
                        -71.20161544,
                        42.18813119
                    ],
                    [
                        -71.20161388,
                        42.18813179
                    ],
                    [
                        -71.20161334,
                        42.18813199
                    ],
                    [
                        -71.20161245,
                        42.18813237
                    ],
                    [
                        -71.20161174,
                        42.18813262
                    ],
                    [
                        -71.20161127,
                        42.18813282
                    ],
                    [
                        -71.20160922,
                        42.18813367
                    ],
                    [
                        -71.20160719,
                        42.18813455
                    ],
                    [
                        -71.20160557,
                        42.18813526
                    ],
                    [
                        -71.20160517,
                        42.18813545
                    ],
                    [
                        -71.20160425,
                        42.18813591
                    ],
                    [
                        -71.20160335,
                        42.1881363
                    ],
                    [
                        -71.20160319,
                        42.18813637
                    ],
                    [
                        -71.20160295,
                        42.18813649
                    ],
                    [
                        -71.20160121,
                        42.18813732
                    ],
                    [
                        -71.20159927,
                        42.1881383
                    ],
                    [
                        -71.20159752,
                        42.18813921
                    ],
                    [
                        -71.20159734,
                        42.18813929
                    ],
                    [
                        -71.2015964,
                        42.18813983
                    ],
                    [
                        -71.20159544,
                        42.18814032
                    ],
                    [
                        -71.20159522,
                        42.18814044
                    ],
                    [
                        -71.20159495,
                        42.18814059
                    ],
                    [
                        -71.20159356,
                        42.18814137
                    ],
                    [
                        -71.20159171,
                        42.18814244
                    ],
                    [
                        -71.20158988,
                        42.18814353
                    ],
                    [
                        -71.20158973,
                        42.18814362
                    ],
                    [
                        -71.20158889,
                        42.18814417
                    ],
                    [
                        -71.20158807,
                        42.18814465
                    ],
                    [
                        -71.20158768,
                        42.18814489
                    ],
                    [
                        -71.20158751,
                        42.18814501
                    ],
                    [
                        -71.20158737,
                        42.18814509
                    ],
                    [
                        -71.20158628,
                        42.18814579
                    ],
                    [
                        -71.20158453,
                        42.18814695
                    ],
                    [
                        -71.20158281,
                        42.18814813
                    ],
                    [
                        -71.20158259,
                        42.18814828
                    ],
                    [
                        -71.20158111,
                        42.18814933
                    ],
                    [
                        -71.2015805,
                        42.18814977
                    ],
                    [
                        -71.20158022,
                        42.18814998
                    ],
                    [
                        -71.20157943,
                        42.18815056
                    ],
                    [
                        -71.20157779,
                        42.1881518
                    ],
                    [
                        -71.20157617,
                        42.18815307
                    ],
                    [
                        -71.2015757,
                        42.18815345
                    ],
                    [
                        -71.20157459,
                        42.18815436
                    ],
                    [
                        -71.20157378,
                        42.18815501
                    ],
                    [
                        -71.20157356,
                        42.18815522
                    ],
                    [
                        -71.20157303,
                        42.18815566
                    ],
                    [
                        -71.2015715,
                        42.18815699
                    ],
                    [
                        -71.20157001,
                        42.18815833
                    ],
                    [
                        -71.2015694,
                        42.18815888
                    ],
                    [
                        -71.20156853,
                        42.18815969
                    ],
                    [
                        -71.20156759,
                        42.18816059
                    ],
                    [
                        -71.20156739,
                        42.18816079
                    ],
                    [
                        -71.2015671,
                        42.18816106
                    ],
                    [
                        -71.20156569,
                        42.18816246
                    ],
                    [
                        -71.20156432,
                        42.18816388
                    ],
                    [
                        -71.20156371,
                        42.18816452
                    ],
                    [
                        -71.20156299,
                        42.18816531
                    ],
                    [
                        -71.20156238,
                        42.18816603
                    ],
                    [
                        -71.20156193,
                        42.18816647
                    ],
                    [
                        -71.20156168,
                        42.18816676
                    ],
                    [
                        -71.20156041,
                        42.18816823
                    ],
                    [
                        -71.20155917,
                        42.1881697
                    ],
                    [
                        -71.2015586,
                        42.18817041
                    ],
                    [
                        -71.20155841,
                        42.18817065
                    ],
                    [
                        -71.20155796,
                        42.1881712
                    ],
                    [
                        -71.20155748,
                        42.18817186
                    ],
                    [
                        -71.20155678,
                        42.18817271
                    ],
                    [
                        -71.20155564,
                        42.18817423
                    ],
                    [
                        -71.20155454,
                        42.18817577
                    ],
                    [
                        -71.20155382,
                        42.18817681
                    ],
                    [
                        -71.20155348,
                        42.18817733
                    ],
                    [
                        -71.20155301,
                        42.1881781
                    ],
                    [
                        -71.20155244,
                        42.18817889
                    ],
                    [
                        -71.20155235,
                        42.18817904
                    ],
                    [
                        -71.20155223,
                        42.18817922
                    ],
                    [
                        -71.20155145,
                        42.18818047
                    ],
                    [
                        -71.20155048,
                        42.18818206
                    ],
                    [
                        -71.20154998,
                        42.18818293
                    ],
                    [
                        -71.20154981,
                        42.18818323
                    ],
                    [
                        -71.20154956,
                        42.18818366
                    ],
                    [
                        -71.20154867,
                        42.18818527
                    ],
                    [
                        -71.20154846,
                        42.18818567
                    ],
                    [
                        -71.20154838,
                        42.18818583
                    ],
                    [
                        -71.20154782,
                        42.1881869
                    ],
                    [
                        -71.201547,
                        42.18818853
                    ],
                    [
                        -71.20154668,
                        42.18818919
                    ],
                    [
                        -71.20154639,
                        42.1881898
                    ],
                    [
                        -71.20154631,
                        42.18818998
                    ],
                    [
                        -71.20154623,
                        42.18819018
                    ],
                    [
                        -71.20154549,
                        42.18819183
                    ],
                    [
                        -71.20154515,
                        42.18819262
                    ],
                    [
                        -71.20154478,
                        42.1881935
                    ],
                    [
                        -71.20154459,
                        42.18819404
                    ],
                    [
                        -71.20154412,
                        42.18819516
                    ],
                    [
                        -71.20154394,
                        42.18819561
                    ],
                    [
                        -71.20154378,
                        42.18819617
                    ],
                    [
                        -71.20154349,
                        42.18819684
                    ],
                    [
                        -71.2015429,
                        42.18819853
                    ],
                    [
                        -71.2015426,
                        42.18819943
                    ],
                    [
                        -71.20154256,
                        42.18819957
                    ],
                    [
                        -71.20154236,
                        42.18820023
                    ],
                    [
                        -71.20154206,
                        42.18820126
                    ],
                    [
                        -71.20154184,
                        42.18820192
                    ],
                    [
                        -71.20154177,
                        42.18820214
                    ],
                    [
                        -71.20154137,
                        42.18820363
                    ],
                    [
                        -71.20154132,
                        42.1882038
                    ],
                    [
                        -71.20154129,
                        42.18820394
                    ],
                    [
                        -71.20154094,
                        42.18820534
                    ],
                    [
                        -71.20154063,
                        42.18820663
                    ],
                    [
                        -71.20154018,
                        42.18820878
                    ],
                    [
                        -71.20153987,
                        42.18821051
                    ],
                    [
                        -71.2015398,
                        42.18821087
                    ],
                    [
                        -71.20153979,
                        42.18821101
                    ],
                    [
                        -71.20153958,
                        42.18821224
                    ],
                    [
                        -71.20153939,
                        42.18821377
                    ],
                    [
                        -71.20153935,
                        42.18821397
                    ],
                    [
                        -71.2015392,
                        42.18821525
                    ],
                    [
                        -71.20153918,
                        42.18821546
                    ],
                    [
                        -71.20153915,
                        42.1882157
                    ],
                    [
                        -71.201539,
                        42.18821745
                    ],
                    [
                        -71.20153895,
                        42.188218
                    ],
                    [
                        -71.20153887,
                        42.18821918
                    ],
                    [
                        -71.20153879,
                        42.18822093
                    ],
                    [
                        -71.20153875,
                        42.18822217
                    ],
                    [
                        -71.20153875,
                        42.18822441
                    ],
                    [
                        -71.20153876,
                        42.18822515
                    ],
                    [
                        -71.20153877,
                        42.18822531
                    ],
                    [
                        -71.20153879,
                        42.18822615
                    ],
                    [
                        -71.20153887,
                        42.1882279
                    ],
                    [
                        -71.20153888,
                        42.18822816
                    ],
                    [
                        -71.20153893,
                        42.18822891
                    ],
                    [
                        -71.20153898,
                        42.18822964
                    ],
                    [
                        -71.20153912,
                        42.18823127
                    ],
                    [
                        -71.20151273,
                        42.18823915
                    ],
                    [
                        -71.20147533,
                        42.18829149
                    ],
                    [
                        -71.20148156,
                        42.18831208
                    ],
                    [
                        -71.20148143,
                        42.18831218
                    ],
                    [
                        -71.20148027,
                        42.18831264
                    ],
                    [
                        -71.20147938,
                        42.188313
                    ],
                    [
                        -71.20147865,
                        42.18831333
                    ],
                    [
                        -71.20147848,
                        42.1883134
                    ],
                    [
                        -71.20147817,
                        42.18831353
                    ],
                    [
                        -71.20147737,
                        42.18831391
                    ],
                    [
                        -71.20147538,
                        42.18831484
                    ],
                    [
                        -71.20147342,
                        42.1883158
                    ],
                    [
                        -71.20147148,
                        42.18831678
                    ],
                    [
                        -71.20147063,
                        42.18831722
                    ],
                    [
                        -71.20147046,
                        42.1883173
                    ],
                    [
                        -71.20147016,
                        42.18831745
                    ],
                    [
                        -71.20146955,
                        42.18831779
                    ],
                    [
                        -71.20146766,
                        42.18831882
                    ],
                    [
                        -71.20146578,
                        42.18831987
                    ],
                    [
                        -71.20146394,
                        42.18832094
                    ],
                    [
                        -71.20146312,
                        42.18832142
                    ],
                    [
                        -71.20146291,
                        42.18832155
                    ],
                    [
                        -71.20146254,
                        42.18832177
                    ],
                    [
                        -71.20146211,
                        42.18832204
                    ],
                    [
                        -71.20146031,
                        42.18832316
                    ],
                    [
                        -71.20145854,
                        42.1883243
                    ],
                    [
                        -71.20145766,
                        42.18832491
                    ],
                    [
                        -71.20145679,
                        42.18832546
                    ],
                    [
                        -71.20145603,
                        42.18832597
                    ],
                    [
                        -71.20145569,
                        42.18832621
                    ],
                    [
                        -71.20145533,
                        42.18832646
                    ],
                    [
                        -71.20145506,
                        42.18832666
                    ],
                    [
                        -71.20145338,
                        42.18832785
                    ],
                    [
                        -71.2014517,
                        42.18832909
                    ],
                    [
                        -71.20145084,
                        42.18832979
                    ],
                    [
                        -71.20145008,
                        42.18833034
                    ],
                    [
                        -71.20144931,
                        42.18833091
                    ],
                    [
                        -71.20144895,
                        42.18833121
                    ],
                    [
                        -71.20144855,
                        42.1883315
                    ],
                    [
                        -71.20144845,
                        42.18833162
                    ],
                    [
                        -71.20144687,
                        42.1883329
                    ],
                    [
                        -71.20144532,
                        42.18833421
                    ],
                    [
                        -71.20144456,
                        42.18833492
                    ],
                    [
                        -71.20144428,
                        42.18833514
                    ],
                    [
                        -71.2014438,
                        42.18833553
                    ],
                    [
                        -71.20144307,
                        42.18833618
                    ],
                    [
                        -71.20144265,
                        42.18833657
                    ],
                    [
                        -71.20144241,
                        42.18833676
                    ],
                    [
                        -71.2014423,
                        42.18833689
                    ],
                    [
                        -71.20144085,
                        42.18833825
                    ],
                    [
                        -71.20143941,
                        42.18833963
                    ],
                    [
                        -71.20143902,
                        42.18834007
                    ],
                    [
                        -71.20143888,
                        42.1883402
                    ],
                    [
                        -71.20143853,
                        42.18834055
                    ],
                    [
                        -71.20143802,
                        42.18834103
                    ],
                    [
                        -71.20143732,
                        42.18834175
                    ],
                    [
                        -71.20143674,
                        42.18834229
                    ],
                    [
                        -71.20143651,
                        42.18834259
                    ],
                    [
                        -71.20143567,
                        42.1883436
                    ],
                    [
                        -71.20143508,
                        42.18834421
                    ],
                    [
                        -71.20143408,
                        42.18834528
                    ],
                    [
                        -71.20143204,
                        42.18834756
                    ],
                    [
                        -71.20143131,
                        42.18834855
                    ],
                    [
                        -71.20142788,
                        42.18835303
                    ],
                    [
                        -71.20142736,
                        42.18835282
                    ],
                    [
                        -71.20143483,
                        42.18833985
                    ],
                    [
                        -71.20143466,
                        42.18833954
                    ],
                    [
                        -71.20141993,
                        42.18833487
                    ],
                    [
                        -71.20141948,
                        42.18833499
                    ],
                    [
                        -71.2014072,
                        42.18835629
                    ],
                    [
                        -71.20140352,
                        42.1883633
                    ],
                    [
                        -71.20140047,
                        42.18837047
                    ],
                    [
                        -71.20139804,
                        42.18837777
                    ],
                    [
                        -71.20139625,
                        42.18838518
                    ],
                    [
                        -71.20139512,
                        42.18839266
                    ],
                    [
                        -71.20139466,
                        42.18840017
                    ],
                    [
                        -71.20139484,
                        42.18840769
                    ],
                    [
                        -71.20139568,
                        42.18841518
                    ],
                    [
                        -71.20139718,
                        42.18842262
                    ],
                    [
                        -71.20139933,
                        42.18842997
                    ],
                    [
                        -71.20140211,
                        42.18843721
                    ],
                    [
                        -71.20140553,
                        42.1884443
                    ],
                    [
                        -71.20140956,
                        42.1884512
                    ],
                    [
                        -71.20141418,
                        42.18845789
                    ],
                    [
                        -71.20144993,
                        42.1885058
                    ],
                    [
                        -71.20145029,
                        42.18850592
                    ],
                    [
                        -71.20148685,
                        42.18849368
                    ],
                    [
                        -71.20148801,
                        42.18849445
                    ],
                    [
                        -71.20148888,
                        42.18849473
                    ],
                    [
                        -71.20148942,
                        42.1884949
                    ],
                    [
                        -71.20149968,
                        42.18849815
                    ],
                    [
                        -71.20149999,
                        42.18849825
                    ],
                    [
                        -71.20149892,
                        42.18849931
                    ],
                    [
                        -71.20149749,
                        42.1885007
                    ],
                    [
                        -71.20149607,
                        42.18850208
                    ],
                    [
                        -71.20149464,
                        42.18850347
                    ],
                    [
                        -71.20149322,
                        42.18850485
                    ],
                    [
                        -71.20149179,
                        42.18850623
                    ],
                    [
                        -71.20149037,
                        42.18850761
                    ],
                    [
                        -71.20148992,
                        42.18850806
                    ],
                    [
                        -71.20149019,
                        42.18850857
                    ],
                    [
                        -71.20149104,
                        42.18851018
                    ],
                    [
                        -71.2014919,
                        42.1885118
                    ],
                    [
                        -71.20149275,
                        42.18851343
                    ],
                    [
                        -71.20149447,
                        42.18851667
                    ],
                    [
                        -71.20149532,
                        42.18851829
                    ],
                    [
                        -71.20149618,
                        42.1885199
                    ],
                    [
                        -71.20149704,
                        42.18852152
                    ],
                    [
                        -71.2014979,
                        42.18852315
                    ],
                    [
                        -71.20149875,
                        42.18852477
                    ],
                    [
                        -71.20150047,
                        42.18852801
                    ],
                    [
                        -71.20150132,
                        42.18852963
                    ],
                    [
                        -71.20150223,
                        42.18853132
                    ],
                    [
                        -71.20150244,
                        42.18853173
                    ],
                    [
                        -71.20150672,
                        42.18853982
                    ],
                    [
                        -71.20150693,
                        42.18854023
                    ],
                    [
                        -71.20150984,
                        42.1885457
                    ],
                    [
                        -71.20151124,
                        42.18854832
                    ],
                    [
                        -71.20151145,
                        42.18854873
                    ],
                    [
                        -71.20151568,
                        42.18855673
                    ],
                    [
                        -71.20151584,
                        42.18855701
                    ],
                    [
                        -71.20151583,
                        42.18855701
                    ],
                    [
                        -71.2015159,
                        42.18855714
                    ],
                    [
                        -71.20151597,
                        42.18855726
                    ],
                    [
                        -71.20153247,
                        42.18858854
                    ],
                    [
                        -71.20153258,
                        42.18858873
                    ],
                    [
                        -71.20153672,
                        42.18859654
                    ],
                    [
                        -71.20153692,
                        42.18859694
                    ],
                    [
                        -71.20153949,
                        42.18860179
                    ],
                    [
                        -71.20154114,
                        42.18860494
                    ],
                    [
                        -71.20154136,
                        42.18860534
                    ],
                    [
                        -71.20154347,
                        42.18860933
                    ],
                    [
                        -71.20154547,
                        42.18861314
                    ],
                    [
                        -71.20154569,
                        42.18861354
                    ],
                    [
                        -71.20154982,
                        42.18862136
                    ],
                    [
                        -71.20155027,
                        42.1886222
                    ],
                    [
                        -71.2015514,
                        42.18862206
                    ],
                    [
                        -71.20155558,
                        42.18862151
                    ],
                    [
                        -71.20156021,
                        42.18862807
                    ],
                    [
                        -71.20156045,
                        42.18862838
                    ],
                    [
                        -71.20156064,
                        42.18862863
                    ],
                    [
                        -71.2015659,
                        42.18863509
                    ],
                    [
                        -71.20156617,
                        42.18863538
                    ],
                    [
                        -71.20156631,
                        42.18863539
                    ],
                    [
                        -71.20156649,
                        42.18863582
                    ],
                    [
                        -71.20156901,
                        42.18863841
                    ],
                    [
                        -71.20156922,
                        42.18863863
                    ],
                    [
                        -71.20157139,
                        42.18864095
                    ],
                    [
                        -71.20157236,
                        42.18864174
                    ],
                    [
                        -71.20157253,
                        42.18864192
                    ],
                    [
                        -71.20157248,
                        42.18864206
                    ],
                    [
                        -71.20157277,
                        42.18864234
                    ],
                    [
                        -71.20157919,
                        42.18864822
                    ],
                    [
                        -71.20157946,
                        42.18864834
                    ],
                    [
                        -71.20157943,
                        42.18864845
                    ],
                    [
                        -71.20157975,
                        42.18864871
                    ],
                    [
                        -71.20158669,
                        42.18865426
                    ],
                    [
                        -71.20158697,
                        42.18865436
                    ],
                    [
                        -71.20158694,
                        42.1886545
                    ],
                    [
                        -71.20158743,
                        42.18865465
                    ],
                    [
                        -71.20159159,
                        42.18865772
                    ],
                    [
                        -71.20159184,
                        42.18865789
                    ],
                    [
                        -71.20159347,
                        42.18865876
                    ],
                    [
                        -71.20159478,
                        42.18865977
                    ],
                    [
                        -71.20159496,
                        42.18866006
                    ],
                    [
                        -71.20159562,
                        42.18866049
                    ],
                    [
                        -71.20159205,
                        42.18866666
                    ],
                    [
                        -71.20155634,
                        42.18866049
                    ],
                    [
                        -71.20155455,
                        42.18866619
                    ],
                    [
                        -71.20155139,
                        42.18867624
                    ],
                    [
                        -71.20155134,
                        42.18867637
                    ],
                    [
                        -71.2015513,
                        42.18867649
                    ],
                    [
                        -71.20153792,
                        42.18871901
                    ],
                    [
                        -71.20156669,
                        42.18872398
                    ],
                    [
                        -71.20156611,
                        42.18872498
                    ],
                    [
                        -71.20156952,
                        42.18872555
                    ],
                    [
                        -71.20167899,
                        42.18874371
                    ],
                    [
                        -71.20167955,
                        42.18874202
                    ],
                    [
                        -71.20170754,
                        42.18874692
                    ],
                    [
                        -71.20170874,
                        42.18874714
                    ],
                    [
                        -71.2017089,
                        42.18874716
                    ],
                    [
                        -71.2017091,
                        42.1887472
                    ],
                    [
                        -71.20176734,
                        42.18875741
                    ],
                    [
                        -71.20176691,
                        42.18875815
                    ],
                    [
                        -71.20176234,
                        42.18876607
                    ],
                    [
                        -71.20176265,
                        42.18876617
                    ],
                    [
                        -71.20176072,
                        42.18876952
                    ],
                    [
                        -71.2017598,
                        42.18878085
                    ],
                    [
                        -71.20177105,
                        42.18878455
                    ],
                    [
                        -71.20178439,
                        42.18878894
                    ],
                    [
                        -71.2017873,
                        42.1887899
                    ],
                    [
                        -71.20178837,
                        42.18879032
                    ],
                    [
                        -71.20181357,
                        42.18880035
                    ],
                    [
                        -71.20181506,
                        42.18880094
                    ],
                    [
                        -71.20182431,
                        42.18880833
                    ],
                    [
                        -71.20184346,
                        42.18881037
                    ],
                    [
                        -71.20187989,
                        42.18878526
                    ],
                    [
                        -71.20201123,
                        42.18883137
                    ],
                    [
                        -71.20209107,
                        42.18885307
                    ],
                    [
                        -71.20212179,
                        42.18884446
                    ],
                    [
                        -71.20212199,
                        42.18884461
                    ],
                    [
                        -71.2021312,
                        42.18884383
                    ],
                    [
                        -71.20214027,
                        42.18884268
                    ],
                    [
                        -71.20214921,
                        42.18884114
                    ],
                    [
                        -71.20215803,
                        42.18883923
                    ],
                    [
                        -71.20216665,
                        42.18883696
                    ],
                    [
                        -71.20220082,
                        42.18884097
                    ],
                    [
                        -71.20222411,
                        42.18884201
                    ],
                    [
                        -71.20224283,
                        42.18883301
                    ],
                    [
                        -71.20226814,
                        42.18881838
                    ],
                    [
                        -71.20228058,
                        42.18879915
                    ],
                    [
                        -71.20228051,
                        42.1887977
                    ],
                    [
                        -71.20226894,
                        42.1887949
                    ],
                    [
                        -71.2022784,
                        42.18877343
                    ],
                    [
                        -71.20227969,
                        42.18877372
                    ],
                    [
                        -71.20229068,
                        42.18877638
                    ],
                    [
                        -71.20229102,
                        42.18877646
                    ],
                    [
                        -71.20241628,
                        42.1888068
                    ],
                    [
                        -71.20245584,
                        42.18882264
                    ],
                    [
                        -71.2024664,
                        42.18882718
                    ],
                    [
                        -71.20246745,
                        42.18882598
                    ],
                    [
                        -71.20246755,
                        42.18882586
                    ],
                    [
                        -71.2024678,
                        42.18882553
                    ],
                    [
                        -71.20247119,
                        42.18882121
                    ],
                    [
                        -71.20247196,
                        42.18881999
                    ],
                    [
                        -71.20247227,
                        42.18881957
                    ],
                    [
                        -71.20247251,
                        42.18881912
                    ],
                    [
                        -71.20247331,
                        42.18881786
                    ],
                    [
                        -71.20247443,
                        42.18881547
                    ],
                    [
                        -71.2024758,
                        42.18881286
                    ],
                    [
                        -71.20247614,
                        42.18881183
                    ],
                    [
                        -71.20247659,
                        42.18881087
                    ],
                    [
                        -71.20247772,
                        42.18880726
                    ],
                    [
                        -71.20247784,
                        42.18880657
                    ],
                    [
                        -71.20247807,
                        42.18880587
                    ],
                    [
                        -71.20247872,
                        42.1888023
                    ],
                    [
                        -71.20247878,
                        42.18880108
                    ],
                    [
                        -71.20247898,
                        42.18879992
                    ],
                    [
                        -71.20247894,
                        42.18879762
                    ],
                    [
                        -71.20247904,
                        42.1887951
                    ],
                    [
                        -71.20247886,
                        42.18879379
                    ],
                    [
                        -71.20247884,
                        42.18879252
                    ],
                    [
                        -71.20247837,
                        42.18879033
                    ],
                    [
                        -71.20247804,
                        42.18878794
                    ],
                    [
                        -71.20247759,
                        42.18878656
                    ],
                    [
                        -71.20247731,
                        42.18878521
                    ],
                    [
                        -71.20247608,
                        42.18878175
                    ],
                    [
                        -71.20247664,
                        42.18878159
                    ],
                    [
                        -71.20247501,
                        42.18877819
                    ],
                    [
                        -71.20247308,
                        42.18877486
                    ],
                    [
                        -71.20247252,
                        42.18877506
                    ],
                    [
                        -71.20247082,
                        42.18877239
                    ],
                    [
                        -71.20246997,
                        42.18877105
                    ],
                    [
                        -71.20246955,
                        42.18877058
                    ],
                    [
                        -71.20246911,
                        42.18876995
                    ],
                    [
                        -71.20246698,
                        42.18876749
                    ],
                    [
                        -71.20246601,
                        42.18876656
                    ],
                    [
                        -71.20246466,
                        42.18876502
                    ],
                    [
                        -71.20246216,
                        42.18876288
                    ],
                    [
                        -71.20246171,
                        42.18876235
                    ],
                    [
                        -71.20246181,
                        42.1887623
                    ],
                    [
                        -71.202483,
                        42.18874241
                    ],
                    [
                        -71.20248332,
                        42.18874211
                    ],
                    [
                        -71.20248354,
                        42.18874191
                    ],
                    [
                        -71.20248402,
                        42.18874207
                    ],
                    [
                        -71.20248496,
                        42.18874245
                    ],
                    [
                        -71.20248801,
                        42.18874338
                    ],
                    [
                        -71.20248835,
                        42.18874349
                    ],
                    [
                        -71.20249251,
                        42.18874486
                    ],
                    [
                        -71.20249362,
                        42.18874512
                    ],
                    [
                        -71.20249392,
                        42.18874519
                    ],
                    [
                        -71.20249724,
                        42.18874598
                    ],
                    [
                        -71.20249994,
                        42.1887464
                    ],
                    [
                        -71.20250209,
                        42.18874687
                    ],
                    [
                        -71.20250262,
                        42.18874685
                    ],
                    [
                        -71.20250268,
                        42.18874697
                    ],
                    [
                        -71.20250499,
                        42.1887472
                    ],
                    [
                        -71.20250696,
                        42.1887475
                    ],
                    [
                        -71.20250742,
                        42.18874755
                    ],
                    [
                        -71.20251192,
                        42.18874789
                    ],
                    [
                        -71.20251212,
                        42.1887479
                    ],
                    [
                        -71.20252186,
                        42.18874788
                    ],
                    [
                        -71.20252249,
                        42.18874783
                    ],
                    [
                        -71.20252432,
                        42.18874769
                    ],
                    [
                        -71.20252681,
                        42.1887475
                    ],
                    [
                        -71.20252924,
                        42.18874718
                    ],
                    [
                        -71.2025317,
                        42.18874686
                    ],
                    [
                        -71.20253218,
                        42.18874676
                    ],
                    [
                        -71.20253264,
                        42.18874671
                    ],
                    [
                        -71.20254126,
                        42.18874484
                    ],
                    [
                        -71.20254588,
                        42.18874346
                    ],
                    [
                        -71.20254807,
                        42.1887426
                    ],
                    [
                        -71.20255034,
                        42.18874186
                    ],
                    [
                        -71.20255467,
                        42.18874002
                    ],
                    [
                        -71.20255673,
                        42.18873899
                    ],
                    [
                        -71.2025588,
                        42.18873797
                    ],
                    [
                        -71.20256271,
                        42.18873556
                    ],
                    [
                        -71.20256644,
                        42.18873326
                    ],
                    [
                        -71.20256991,
                        42.18873062
                    ],
                    [
                        -71.20257145,
                        42.18872917
                    ],
                    [
                        -71.20257313,
                        42.1887278
                    ],
                    [
                        -71.20257453,
                        42.18872628
                    ],
                    [
                        -71.20257606,
                        42.18872483
                    ],
                    [
                        -71.20257731,
                        42.18872324
                    ],
                    [
                        -71.20257872,
                        42.18872172
                    ],
                    [
                        -71.20258109,
                        42.18871847
                    ],
                    [
                        -71.20258213,
                        42.18871676
                    ],
                    [
                        -71.20258313,
                        42.18871511
                    ],
                    [
                        -71.20258477,
                        42.18871147
                    ],
                    [
                        -71.20258627,
                        42.18870812
                    ],
                    [
                        -71.20258733,
                        42.18870452
                    ],
                    [
                        -71.20258761,
                        42.18870269
                    ],
                    [
                        -71.20258806,
                        42.18870088
                    ],
                    [
                        -71.20258817,
                        42.18869904
                    ],
                    [
                        -71.20258845,
                        42.18869721
                    ],
                    [
                        -71.20258849,
                        42.18869352
                    ]
                ]
            ]
        },
        "levels": [
            {
                "levelShortTitle": "1F",
                "levelLongTitle": "First Floor",
                "levelIndex": 1,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "79f22b63-76e9-48c1-810f-c6216967a42b"
                }
            },
            {
                "levelShortTitle": "2F",
                "levelLongTitle": "Second Floor",
                "levelIndex": 2,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "4bf5ea8c-8763-4eba-b088-b2bb3d580024"
                }
            },
            {
                "levelShortTitle": "3F",
                "levelLongTitle": "Third Floor",
                "levelIndex": 3,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "76474369-4e41-401e-a40b-d6b9e83d8ce9"
                }
            },
            {
                "levelShortTitle": "4F",
                "levelLongTitle": "Fourth Floor",
                "levelIndex": 4,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "3b76d4e3-781e-4d15-aa71-0ea2a40ea108"
                }
            }
        ]
    },
    {
        "buildingTitle": "Main Hospital",
        "buildingInternalIdentifier": 10,
        "buildingExternalIdentifier": "8b189e05-e562-463e-a262-78748371c458",
        "buildingExtraData": {
            "timeZone": "America/Los_Angeles"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.20157543,
                        42.19005998
                    ],
                    [
                        -71.20159403,
                        42.19006139
                    ],
                    [
                        -71.20183079,
                        42.19007996
                    ],
                    [
                        -71.2018295,
                        42.1900888
                    ],
                    [
                        -71.20182643,
                        42.19011039
                    ],
                    [
                        -71.20182598,
                        42.19011352
                    ],
                    [
                        -71.20182515,
                        42.19011924
                    ],
                    [
                        -71.20182208,
                        42.19014072
                    ],
                    [
                        -71.201821,
                        42.19014828
                    ],
                    [
                        -71.20200199,
                        42.19016188
                    ],
                    [
                        -71.20208881,
                        42.19016841
                    ],
                    [
                        -71.20208942,
                        42.19016409
                    ],
                    [
                        -71.2020872,
                        42.19016393
                    ],
                    [
                        -71.20210373,
                        42.19004471
                    ],
                    [
                        -71.2021041,
                        42.19004212
                    ],
                    [
                        -71.20210809,
                        42.19001426
                    ],
                    [
                        -71.20210847,
                        42.19001167
                    ],
                    [
                        -71.20211153,
                        42.18999032
                    ],
                    [
                        -71.20211246,
                        42.18998383
                    ],
                    [
                        -71.20211283,
                        42.18998123
                    ],
                    [
                        -71.20211681,
                        42.18995338
                    ],
                    [
                        -71.20211719,
                        42.18995079
                    ],
                    [
                        -71.20212117,
                        42.18992294
                    ],
                    [
                        -71.20212155,
                        42.18992034
                    ],
                    [
                        -71.20212554,
                        42.1898925
                    ],
                    [
                        -71.20212591,
                        42.18988991
                    ],
                    [
                        -71.2021299,
                        42.18986206
                    ],
                    [
                        -71.20213017,
                        42.1898601
                    ],
                    [
                        -71.20213028,
                        42.18985946
                    ],
                    [
                        -71.20213882,
                        42.18980119
                    ],
                    [
                        -71.20219821,
                        42.18980565
                    ],
                    [
                        -71.20219883,
                        42.1898057
                    ],
                    [
                        -71.20221324,
                        42.18980678
                    ],
                    [
                        -71.20221385,
                        42.18980682
                    ],
                    [
                        -71.20221716,
                        42.18980707
                    ],
                    [
                        -71.20222151,
                        42.1898074
                    ],
                    [
                        -71.20222213,
                        42.18980744
                    ],
                    [
                        -71.20223637,
                        42.18980851
                    ],
                    [
                        -71.20223699,
                        42.18980855
                    ],
                    [
                        -71.20227175,
                        42.18981117
                    ],
                    [
                        -71.20227237,
                        42.18981122
                    ],
                    [
                        -71.20228677,
                        42.1898123
                    ],
                    [
                        -71.20228739,
                        42.18981235
                    ],
                    [
                        -71.20229505,
                        42.18981292
                    ],
                    [
                        -71.20229565,
                        42.18981297
                    ],
                    [
                        -71.20230991,
                        42.18981405
                    ],
                    [
                        -71.20231053,
                        42.18981409
                    ],
                    [
                        -71.2023453,
                        42.18981671
                    ],
                    [
                        -71.20234592,
                        42.18981675
                    ],
                    [
                        -71.20236033,
                        42.18981783
                    ],
                    [
                        -71.20236093,
                        42.18981788
                    ],
                    [
                        -71.20236526,
                        42.1898182
                    ],
                    [
                        -71.20236767,
                        42.18981839
                    ],
                    [
                        -71.2023686,
                        42.18981846
                    ],
                    [
                        -71.2023692,
                        42.1898185
                    ],
                    [
                        -71.20238345,
                        42.18981957
                    ],
                    [
                        -71.20238406,
                        42.18981963
                    ],
                    [
                        -71.20239571,
                        42.1898205
                    ],
                    [
                        -71.20240812,
                        42.18982143
                    ],
                    [
                        -71.20241884,
                        42.18982224
                    ],
                    [
                        -71.20241945,
                        42.18982228
                    ],
                    [
                        -71.20243386,
                        42.18982335
                    ],
                    [
                        -71.20243447,
                        42.18982341
                    ],
                    [
                        -71.20243829,
                        42.18982369
                    ],
                    [
                        -71.20244214,
                        42.18982398
                    ],
                    [
                        -71.20244274,
                        42.18982402
                    ],
                    [
                        -71.202457,
                        42.18982509
                    ],
                    [
                        -71.20245762,
                        42.18982515
                    ],
                    [
                        -71.20247997,
                        42.18982683
                    ],
                    [
                        -71.20248207,
                        42.18981215
                    ],
                    [
                        -71.20248238,
                        42.18981217
                    ],
                    [
                        -71.20248245,
                        42.18981173
                    ],
                    [
                        -71.2024839,
                        42.18980159
                    ],
                    [
                        -71.20248396,
                        42.18980116
                    ],
                    [
                        -71.20248434,
                        42.18979845
                    ],
                    [
                        -71.20248473,
                        42.18979577
                    ],
                    [
                        -71.20248479,
                        42.18979533
                    ],
                    [
                        -71.20248624,
                        42.18978528
                    ],
                    [
                        -71.20248629,
                        42.18978486
                    ],
                    [
                        -71.20248598,
                        42.18978484
                    ],
                    [
                        -71.2024895,
                        42.18976032
                    ],
                    [
                        -71.20248981,
                        42.18976036
                    ],
                    [
                        -71.20248987,
                        42.18975992
                    ],
                    [
                        -71.20249132,
                        42.18974977
                    ],
                    [
                        -71.20249138,
                        42.18974934
                    ],
                    [
                        -71.20249176,
                        42.18974665
                    ],
                    [
                        -71.20249216,
                        42.18974394
                    ],
                    [
                        -71.20249222,
                        42.18974351
                    ],
                    [
                        -71.20249366,
                        42.18973347
                    ],
                    [
                        -71.20249372,
                        42.18973304
                    ],
                    [
                        -71.20249341,
                        42.18973301
                    ],
                    [
                        -71.20249693,
                        42.18970851
                    ],
                    [
                        -71.20249722,
                        42.18970853
                    ],
                    [
                        -71.20249729,
                        42.1897081
                    ],
                    [
                        -71.20249875,
                        42.18969796
                    ],
                    [
                        -71.20249881,
                        42.18969753
                    ],
                    [
                        -71.2024992,
                        42.18969482
                    ],
                    [
                        -71.20249958,
                        42.18969213
                    ],
                    [
                        -71.20249964,
                        42.1896917
                    ],
                    [
                        -71.20250108,
                        42.18968165
                    ],
                    [
                        -71.20250115,
                        42.18968123
                    ],
                    [
                        -71.20250083,
                        42.18968121
                    ],
                    [
                        -71.20250434,
                        42.1896567
                    ],
                    [
                        -71.20250464,
                        42.18965672
                    ],
                    [
                        -71.20250471,
                        42.18965629
                    ],
                    [
                        -71.20250618,
                        42.18964614
                    ],
                    [
                        -71.20250622,
                        42.1896457
                    ],
                    [
                        -71.202507,
                        42.18964031
                    ],
                    [
                        -71.20250706,
                        42.18963987
                    ],
                    [
                        -71.20250855,
                        42.18962941
                    ],
                    [
                        -71.20251207,
                        42.1896049
                    ],
                    [
                        -71.20251238,
                        42.18960492
                    ],
                    [
                        -71.20251245,
                        42.18960449
                    ],
                    [
                        -71.20251622,
                        42.18957804
                    ],
                    [
                        -71.20251628,
                        42.18957761
                    ],
                    [
                        -71.20251598,
                        42.18957759
                    ],
                    [
                        -71.20251965,
                        42.18955201
                    ],
                    [
                        -71.20251972,
                        42.18955157
                    ],
                    [
                        -71.20252116,
                        42.18954143
                    ],
                    [
                        -71.20252123,
                        42.18954099
                    ],
                    [
                        -71.202522,
                        42.1895356
                    ],
                    [
                        -71.20252205,
                        42.18953516
                    ],
                    [
                        -71.2025235,
                        42.18952512
                    ],
                    [
                        -71.20252372,
                        42.1895247
                    ],
                    [
                        -71.20253174,
                        42.18946868
                    ],
                    [
                        -71.20249329,
                        42.18946579
                    ],
                    [
                        -71.20249312,
                        42.18946697
                    ],
                    [
                        -71.202493,
                        42.18946773
                    ],
                    [
                        -71.20249178,
                        42.18946763
                    ],
                    [
                        -71.20246711,
                        42.18946578
                    ],
                    [
                        -71.20246665,
                        42.18946574
                    ],
                    [
                        -71.20245517,
                        42.18946489
                    ],
                    [
                        -71.20245409,
                        42.1894648
                    ],
                    [
                        -71.20245364,
                        42.18946477
                    ],
                    [
                        -71.20244261,
                        42.18946394
                    ],
                    [
                        -71.20244216,
                        42.18946391
                    ],
                    [
                        -71.20242958,
                        42.18946296
                    ],
                    [
                        -71.20242912,
                        42.18946293
                    ],
                    [
                        -71.20242163,
                        42.18946236
                    ],
                    [
                        -71.20240167,
                        42.18946087
                    ],
                    [
                        -71.2023891,
                        42.18945992
                    ],
                    [
                        -71.20238864,
                        42.18945988
                    ],
                    [
                        -71.20237608,
                        42.18945895
                    ],
                    [
                        -71.20237563,
                        42.18945891
                    ],
                    [
                        -71.20236461,
                        42.18945809
                    ],
                    [
                        -71.20236415,
                        42.18945805
                    ],
                    [
                        -71.20235161,
                        42.18945711
                    ],
                    [
                        -71.20235113,
                        42.18945706
                    ],
                    [
                        -71.20232004,
                        42.18945473
                    ],
                    [
                        -71.20231957,
                        42.18945469
                    ],
                    [
                        -71.20230808,
                        42.18945383
                    ],
                    [
                        -71.20230731,
                        42.18945377
                    ],
                    [
                        -71.20230701,
                        42.18945375
                    ],
                    [
                        -71.20230655,
                        42.18945371
                    ],
                    [
                        -71.20230473,
                        42.18945358
                    ],
                    [
                        -71.20229552,
                        42.18945288
                    ],
                    [
                        -71.20229507,
                        42.18945286
                    ],
                    [
                        -71.20228251,
                        42.18945191
                    ],
                    [
                        -71.20228203,
                        42.18945188
                    ],
                    [
                        -71.2022812,
                        42.18945181
                    ],
                    [
                        -71.20224047,
                        42.18944874
                    ],
                    [
                        -71.20223179,
                        42.18944809
                    ],
                    [
                        -71.20223182,
                        42.18944788
                    ],
                    [
                        -71.2022312,
                        42.18944782
                    ],
                    [
                        -71.20221803,
                        42.18944684
                    ],
                    [
                        -71.20221742,
                        42.18944679
                    ],
                    [
                        -71.20220822,
                        42.18944609
                    ],
                    [
                        -71.20220762,
                        42.18944605
                    ],
                    [
                        -71.20219444,
                        42.18944507
                    ],
                    [
                        -71.20219382,
                        42.18944502
                    ],
                    [
                        -71.20219379,
                        42.18944524
                    ],
                    [
                        -71.20216824,
                        42.18944331
                    ],
                    [
                        -71.20216829,
                        42.1894431
                    ],
                    [
                        -71.20216767,
                        42.18944305
                    ],
                    [
                        -71.20215782,
                        42.18944231
                    ],
                    [
                        -71.2021572,
                        42.18944227
                    ],
                    [
                        -71.20215573,
                        42.18944215
                    ],
                    [
                        -71.20215511,
                        42.18944211
                    ],
                    [
                        -71.20214607,
                        42.18944143
                    ],
                    [
                        -71.20214586,
                        42.18944141
                    ],
                    [
                        -71.20214524,
                        42.18944137
                    ],
                    [
                        -71.20213206,
                        42.18944037
                    ],
                    [
                        -71.20213146,
                        42.18944033
                    ],
                    [
                        -71.20213142,
                        42.18944054
                    ],
                    [
                        -71.2021057,
                        42.18943861
                    ],
                    [
                        -71.20210573,
                        42.18943839
                    ],
                    [
                        -71.20210381,
                        42.18943825
                    ],
                    [
                        -71.2021032,
                        42.1894382
                    ],
                    [
                        -71.20209255,
                        42.1894374
                    ],
                    [
                        -71.20209194,
                        42.18943735
                    ],
                    [
                        -71.20209003,
                        42.18943721
                    ],
                    [
                        -71.20208942,
                        42.18943717
                    ],
                    [
                        -71.20208907,
                        42.18943715
                    ],
                    [
                        -71.20208275,
                        42.18943667
                    ],
                    [
                        -71.20208213,
                        42.18943662
                    ],
                    [
                        -71.20206895,
                        42.18943563
                    ],
                    [
                        -71.20206834,
                        42.18943559
                    ],
                    [
                        -71.20206831,
                        42.1894358
                    ],
                    [
                        -71.20203767,
                        42.1894335
                    ],
                    [
                        -71.20203769,
                        42.18943328
                    ],
                    [
                        -71.20203709,
                        42.18943323
                    ],
                    [
                        -71.20202391,
                        42.18943225
                    ],
                    [
                        -71.2020233,
                        42.1894322
                    ],
                    [
                        -71.20201411,
                        42.1894315
                    ],
                    [
                        -71.20201349,
                        42.18943146
                    ],
                    [
                        -71.20200031,
                        42.18943047
                    ],
                    [
                        -71.20199971,
                        42.18943043
                    ],
                    [
                        -71.20199968,
                        42.18943064
                    ],
                    [
                        -71.20197808,
                        42.18942902
                    ],
                    [
                        -71.20197525,
                        42.18944866
                    ],
                    [
                        -71.20185959,
                        42.18943997
                    ],
                    [
                        -71.20113006,
                        42.18938511
                    ],
                    [
                        -71.20112136,
                        42.18944581
                    ],
                    [
                        -71.20112137,
                        42.18944585
                    ],
                    [
                        -71.20112105,
                        42.1894481
                    ],
                    [
                        -71.20109067,
                        42.18944581
                    ],
                    [
                        -71.20109056,
                        42.18944667
                    ],
                    [
                        -71.20108867,
                        42.18944654
                    ],
                    [
                        -71.20107465,
                        42.18944548
                    ],
                    [
                        -71.20107466,
                        42.18944486
                    ],
                    [
                        -71.20107475,
                        42.18944426
                    ],
                    [
                        -71.2010435,
                        42.18944191
                    ],
                    [
                        -71.2008899,
                        42.18943037
                    ],
                    [
                        -71.20088981,
                        42.18943096
                    ],
                    [
                        -71.20088974,
                        42.18943158
                    ],
                    [
                        -71.20086656,
                        42.18942985
                    ],
                    [
                        -71.20078398,
                        42.18942363
                    ],
                    [
                        -71.20077207,
                        42.1895068
                    ],
                    [
                        -71.20076597,
                        42.1895494
                    ],
                    [
                        -71.20076458,
                        42.18955908
                    ],
                    [
                        -71.20076281,
                        42.18957148
                    ],
                    [
                        -71.200755,
                        42.18962598
                    ],
                    [
                        -71.20075498,
                        42.18962602
                    ],
                    [
                        -71.20075499,
                        42.18962605
                    ],
                    [
                        -71.20074713,
                        42.18968085
                    ],
                    [
                        -71.20074211,
                        42.189716
                    ],
                    [
                        -71.20073075,
                        42.18979529
                    ],
                    [
                        -71.20073064,
                        42.18979595
                    ],
                    [
                        -71.20072752,
                        42.18981776
                    ],
                    [
                        -71.20072582,
                        42.18981764
                    ],
                    [
                        -71.20072285,
                        42.18983834
                    ],
                    [
                        -71.20072256,
                        42.18984034
                    ],
                    [
                        -71.20071695,
                        42.18987945
                    ],
                    [
                        -71.20071467,
                        42.18989534
                    ],
                    [
                        -71.2007068,
                        42.18995033
                    ],
                    [
                        -71.2006919,
                        42.18994978
                    ],
                    [
                        -71.20068944,
                        42.18998554
                    ],
                    [
                        -71.20070169,
                        42.18998601
                    ],
                    [
                        -71.20070046,
                        42.18999466
                    ],
                    [
                        -71.20070903,
                        42.1899953
                    ],
                    [
                        -71.20070894,
                        42.18999595
                    ],
                    [
                        -71.20080392,
                        42.19000309
                    ],
                    [
                        -71.20080402,
                        42.19000244
                    ],
                    [
                        -71.2008126,
                        42.19000309
                    ],
                    [
                        -71.20081249,
                        42.19000373
                    ],
                    [
                        -71.20090596,
                        42.19001076
                    ],
                    [
                        -71.20090605,
                        42.19001011
                    ],
                    [
                        -71.20091464,
                        42.19001076
                    ],
                    [
                        -71.20091453,
                        42.1900114
                    ],
                    [
                        -71.201008,
                        42.19001843
                    ],
                    [
                        -71.20100809,
                        42.19001778
                    ],
                    [
                        -71.20101667,
                        42.19001843
                    ],
                    [
                        -71.20101657,
                        42.19001908
                    ],
                    [
                        -71.20111157,
                        42.19002621
                    ],
                    [
                        -71.20111167,
                        42.19002557
                    ],
                    [
                        -71.20112024,
                        42.1900262
                    ],
                    [
                        -71.20112028,
                        42.19002621
                    ],
                    [
                        -71.20127659,
                        42.19003752
                    ],
                    [
                        -71.2012952,
                        42.19003893
                    ],
                    [
                        -71.20129518,
                        42.19003914
                    ],
                    [
                        -71.20129594,
                        42.1900392
                    ],
                    [
                        -71.20130988,
                        42.19004024
                    ],
                    [
                        -71.20131065,
                        42.19004029
                    ],
                    [
                        -71.201354,
                        42.19004356
                    ],
                    [
                        -71.20135475,
                        42.19004362
                    ],
                    [
                        -71.2013687,
                        42.19004467
                    ],
                    [
                        -71.20136948,
                        42.19004473
                    ],
                    [
                        -71.2013695,
                        42.19004451
                    ],
                    [
                        -71.201453,
                        42.19005078
                    ],
                    [
                        -71.20145699,
                        42.19005108
                    ],
                    [
                        -71.20145696,
                        42.1900513
                    ],
                    [
                        -71.20145773,
                        42.19005136
                    ],
                    [
                        -71.20145775,
                        42.19005114
                    ],
                    [
                        -71.20146684,
                        42.19005182
                    ],
                    [
                        -71.2014717,
                        42.19005219
                    ],
                    [
                        -71.20147167,
                        42.1900524
                    ],
                    [
                        -71.20147243,
                        42.19005245
                    ],
                    [
                        -71.20147246,
                        42.19005225
                    ],
                    [
                        -71.20148641,
                        42.1900533
                    ],
                    [
                        -71.20148638,
                        42.19005351
                    ],
                    [
                        -71.20148714,
                        42.19005357
                    ],
                    [
                        -71.20148717,
                        42.19005335
                    ],
                    [
                        -71.20150112,
                        42.1900544
                    ],
                    [
                        -71.20150109,
                        42.19005462
                    ],
                    [
                        -71.20150185,
                        42.19005467
                    ],
                    [
                        -71.20150188,
                        42.19005446
                    ],
                    [
                        -71.20151582,
                        42.19005551
                    ],
                    [
                        -71.20151579,
                        42.19005572
                    ],
                    [
                        -71.20151655,
                        42.19005578
                    ],
                    [
                        -71.20151659,
                        42.19005556
                    ],
                    [
                        -71.20153054,
                        42.19005661
                    ],
                    [
                        -71.20153049,
                        42.19005683
                    ],
                    [
                        -71.20153126,
                        42.19005688
                    ],
                    [
                        -71.2015313,
                        42.19005667
                    ],
                    [
                        -71.20153827,
                        42.19005719
                    ],
                    [
                        -71.20154524,
                        42.19005771
                    ],
                    [
                        -71.20154521,
                        42.19005793
                    ],
                    [
                        -71.20154598,
                        42.19005799
                    ],
                    [
                        -71.20154601,
                        42.19005777
                    ],
                    [
                        -71.20155995,
                        42.19005882
                    ],
                    [
                        -71.20155992,
                        42.19005904
                    ],
                    [
                        -71.20156068,
                        42.19005909
                    ],
                    [
                        -71.20156072,
                        42.19005888
                    ],
                    [
                        -71.20157465,
                        42.19005994
                    ],
                    [
                        -71.20157461,
                        42.19006022
                    ],
                    [
                        -71.20157538,
                        42.19006028
                    ],
                    [
                        -71.20157543,
                        42.19005998
                    ]
                ]
            ]
        },
        "levels": [
            {
                "levelShortTitle": "1F",
                "levelLongTitle": "First Floor",
                "levelIndex": 1,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "ba1bbc61-27d5-42bb-96b8-1c1578df9a82"
                }
            },
            {
                "levelShortTitle": "2F",
                "levelLongTitle": "Second Floor",
                "levelIndex": 2,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "53dccfed-3aa2-44e4-932f-69462c2a7435"
                }
            }
        ]
    },
    {
        "buildingTitle": "Wing",
        "buildingInternalIdentifier": 11,
        "buildingExternalIdentifier": "d0b514eb-0057-41ab-a72e-0a54b13d1e31",
        "buildingExtraData": {
            "timeZone": "America/Los_Angeles"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.2022232,
                        42.18921972
                    ],
                    [
                        -71.20221724,
                        42.1892543
                    ],
                    [
                        -71.20239022,
                        42.18927071
                    ],
                    [
                        -71.20254182,
                        42.18928509
                    ],
                    [
                        -71.20254477,
                        42.18926802
                    ],
                    [
                        -71.20256098,
                        42.18926955
                    ],
                    [
                        -71.20260398,
                        42.18902082
                    ],
                    [
                        -71.20252644,
                        42.18901345
                    ],
                    [
                        -71.20233075,
                        42.18899488
                    ],
                    [
                        -71.20226318,
                        42.18898847
                    ],
                    [
                        -71.20225722,
                        42.189023
                    ],
                    [
                        -71.20226057,
                        42.18902331
                    ],
                    [
                        -71.2022729,
                        42.18903501
                    ],
                    [
                        -71.20226216,
                        42.18909709
                    ],
                    [
                        -71.20225291,
                        42.1891061
                    ],
                    [
                        -71.20225231,
                        42.18910668
                    ],
                    [
                        -71.20225222,
                        42.18910712
                    ],
                    [
                        -71.20225222,
                        42.18910715
                    ],
                    [
                        -71.20224971,
                        42.18912173
                    ],
                    [
                        -71.20224955,
                        42.18912266
                    ],
                    [
                        -71.20224937,
                        42.18912369
                    ],
                    [
                        -71.20224873,
                        42.18912739
                    ],
                    [
                        -71.202255,
                        42.18913852
                    ],
                    [
                        -71.20225324,
                        42.18914878
                    ],
                    [
                        -71.20225958,
                        42.18916001
                    ],
                    [
                        -71.20225779,
                        42.18917037
                    ],
                    [
                        -71.20224784,
                        42.18917994
                    ],
                    [
                        -71.20224247,
                        42.18921108
                    ],
                    [
                        -71.20222669,
                        42.18922005
                    ],
                    [
                        -71.2022232,
                        42.18921972
                    ]
                ]
            ]
        },
        "levels": [
            {
                "levelShortTitle": "1F",
                "levelLongTitle": "First Floor",
                "levelIndex": 1,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "42a70e13-a72f-4b35-84e4-30f07fa3913e"
                }
            },
            {
                "levelShortTitle": "2F",
                "levelLongTitle": "Second Floor",
                "levelIndex": 2,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "800bc8c4-11a8-40c9-85e8-71f929e81af9"
                }
            }
        ]
    },
    {
        "buildingTitle": "Garage Building",
        "buildingInternalIdentifier": 12,
        "buildingExternalIdentifier": "7a33465d-2b6e-4b20-9c3e-be03659a1b85",
        "buildingExtraData": {},
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.20011522,
                        42.18915487
                    ],
                    [
                        -71.20012563,
                        42.18912177
                    ],
                    [
                        -71.20015572,
                        42.18902492
                    ],
                    [
                        -71.20015313,
                        42.18902447
                    ],
                    [
                        -71.20019105,
                        42.18890243
                    ],
                    [
                        -71.20019365,
                        42.18890287
                    ],
                    [
                        -71.20027489,
                        42.18864133
                    ],
                    [
                        -71.2002723,
                        42.18864089
                    ],
                    [
                        -71.20031039,
                        42.18851831
                    ],
                    [
                        -71.20031299,
                        42.18851874
                    ],
                    [
                        -71.20037025,
                        42.18833443
                    ],
                    [
                        -71.20044045,
                        42.1883464
                    ],
                    [
                        -71.20044018,
                        42.18834726
                    ],
                    [
                        -71.20094656,
                        42.18843362
                    ],
                    [
                        -71.20094682,
                        42.18843277
                    ],
                    [
                        -71.20119342,
                        42.18847573
                    ],
                    [
                        -71.20119336,
                        42.18847594
                    ],
                    [
                        -71.20119271,
                        42.18847804
                    ],
                    [
                        -71.20118191,
                        42.18851268
                    ],
                    [
                        -71.20103219,
                        42.18899281
                    ],
                    [
                        -71.20103333,
                        42.18899299
                    ],
                    [
                        -71.20103576,
                        42.18899341
                    ],
                    [
                        -71.20098805,
                        42.18914702
                    ],
                    [
                        -71.20098252,
                        42.18916481
                    ],
                    [
                        -71.20097568,
                        42.18918703
                    ],
                    [
                        -71.2009745,
                        42.18919086
                    ],
                    [
                        -71.2009719,
                        42.18919917
                    ],
                    [
                        -71.20097072,
                        42.18920301
                    ],
                    [
                        -71.20096409,
                        42.18922433
                    ],
                    [
                        -71.2009629,
                        42.18922816
                    ],
                    [
                        -71.20096031,
                        42.18923647
                    ],
                    [
                        -71.20095912,
                        42.18924031
                    ],
                    [
                        -71.2009525,
                        42.18926163
                    ],
                    [
                        -71.20095131,
                        42.18926547
                    ],
                    [
                        -71.20094872,
                        42.18927379
                    ],
                    [
                        -71.20094753,
                        42.18927763
                    ],
                    [
                        -71.20093489,
                        42.18931656
                    ],
                    [
                        -71.20093292,
                        42.18931629
                    ],
                    [
                        -71.20093168,
                        42.1893161
                    ],
                    [
                        -71.200927,
                        42.18933149
                    ],
                    [
                        -71.20092169,
                        42.18934861
                    ],
                    [
                        -71.20073829,
                        42.18931755
                    ],
                    [
                        -71.20073643,
                        42.18932352
                    ],
                    [
                        -71.20067198,
                        42.18931252
                    ],
                    [
                        -71.20067353,
                        42.18930763
                    ],
                    [
                        -71.20065969,
                        42.18930526
                    ],
                    [
                        -71.20065976,
                        42.18930506
                    ],
                    [
                        -71.20064552,
                        42.18930262
                    ],
                    [
                        -71.20059502,
                        42.18929401
                    ],
                    [
                        -71.20059529,
                        42.18929316
                    ],
                    [
                        -71.20016832,
                        42.18922034
                    ],
                    [
                        -71.20016805,
                        42.18922119
                    ],
                    [
                        -71.20009842,
                        42.18920932
                    ],
                    [
                        -71.20011533,
                        42.18915489
                    ],
                    [
                        -71.20011522,
                        42.18915487
                    ]
                ]
            ]
        },
        "levels": [
            {
                "levelShortTitle": "1F",
                "levelLongTitle": "First Floor",
                "levelIndex": 1,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "57bf956f-c527-4da1-a4f3-f20f72555e1d"
                }
            },
            {
                "levelShortTitle": "2F",
                "levelLongTitle": "Second Floor",
                "levelIndex": 2,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "72d26f80-07df-47e1-8ccb-060291ba96d8"
                }
            }
        ]
    }
];
  // Or, if the keys are static, you can use an object:
  const buildingExID = {
    9: "5db35008-3de0-4551-8b6e-a6fe29b06a38",
    10: "8b189e05-e562-463e-a262-78748371c458",
    11: "d0b514eb-0057-41ab-a72e-0a54b13d1e31",
    12: "7a33465d-2b6e-4b20-9c3e-be03659a1b85"
  };
  const levelExId ={
    "5db35008-3de0-4551-8b6e-a6fe29b06a38":{
      1:"79f22b63-76e9-48c1-810f-c6216967a42b",
      2:"4bf5ea8c-8763-4eba-b088-b2bb3d580024",
      3:"76474369-4e41-401e-a40b-d6b9e83d8ce9",
      4:"3b76d4e3-781e-4d15-aa71-0ea2a40ea108"
    },
    "8b189e05-e562-463e-a262-78748371c458":{
      1:"ba1bbc61-27d5-42bb-96b8-1c1578df9a82",
      2:"53dccfed-3aa2-44e4-932f-69462c2a7435"
    },
    "d0b514eb-0057-41ab-a72e-0a54b13d1e31":{
      1:"42a70e13-a72f-4b35-84e4-30f07fa3913e",
      2:"800bc8c4-11a8-40c9-85e8-71f929e81af9"
    },
    "7a33465d-2b6e-4b20-9c3e-be03659a1b85":{
      1:"57bf956f-c527-4da1-a4f3-f20f72555e1d",
      2:"72d26f80-07df-47e1-8ccb-060291ba96d8"
    }

  }

  const minMaxBounds = [
    
    [ 4.874747373780906,
      52.32649719537784
    ],
    [
      4.900164974144076,
      52.35777235234855
    ]
  ];

 
  const floorColourMapping = [
    { levelIndex: 0, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 1, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 2, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 3, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 4, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 5, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
  ];

  const constraintsLookUp = {
    "Full Conference": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "Cisco Full Conference": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "IT Leadership": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Thursday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Thursday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Wednesday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Wednesday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Event Staff": [
        "Full Conf - View"
    ],
    "Speaker Full Conference": [
        "Full Conf - View"
    ],
    "Cisco Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],

}  
 
  const getLocationId = (poi) => {
    const bid = poi?.properties?.bid;
    const lvl = poi?.properties?.lvl;
    const buildingId = buildingExID[bid];
  
    // Check if both buildingId and lvl exist in levelExId
    if (buildingId && lvl !== undefined && levelExId[buildingId]?.[lvl] !== undefined) {
      return levelExId[buildingId][lvl];
    }
  
    // Fallback to buildingId if lvl is not present or no match found
    return buildingId || locationId;
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const kioskFid = query.get('kioskFid')||query.get('locationNum');
  const destFid = query.get('destFid');
  const latFromQuery = parseFloat(query.get('lat'));
  const longFromQuery = parseFloat(query.get('long'));
  const rotationFromQuery = parseFloat(query.get('rotation'));
  const zoomFromQuery = parseFloat(query.get('zoom'));
  const pitchFromQuery = parseFloat(query.get('pitch'));
  const bearingFromQuery = parseFloat(query.get('bearing'));
  const levelFromQuery = parseFloat(query.get('level'));
  const buildingFromQuery = parseFloat(query.get('building'));
  const visibleAiContainerFromQuery = query.get('enableAI');
  const eventStartDay = query.get('eventStartDay')?query.get('eventStartDay'):3;

  const centerLong = -71.20129185010097;
  const centerLat =  42.18882997249821;
  const whereAmIScale = 42.091301692798277e-8;
  const xapi=null;

  const [lat, setLat] = useState(kioskData[kioskFid]?.lat || (latFromQuery ? latFromQuery : 42.18846877027224));
  const [long, setLong] = useState(kioskData[kioskFid]?.long || (longFromQuery ? longFromQuery : -71.20235964462145));
  const [rotation, setRotation] = useState(kioskData[kioskFid]?.rotation || (rotationFromQuery ? rotationFromQuery:2.8));
  const [zoom, setZoom] = useState(kioskData[kioskFid]?.zoom || (zoomFromQuery ? zoomFromQuery : 18));
  const [pitch, setPitch] = useState(kioskData[kioskFid]?.pitch || (pitchFromQuery ? pitchFromQuery: 65));
  const [bearing, setBearing] = useState(kioskData[kioskFid]?.bearing || (bearingFromQuery ? bearingFromQuery : -157.99999999999994));
  const [level, setLevel] = useState(kioskData[kioskFid]?.level || (levelFromQuery ? levelFromQuery: 1));
  const [building, setBuilding] = useState(kioskData[kioskFid]?.building || (buildingFromQuery ? buildingFromQuery : 9));
  const [visibleAIContainer, setVisibleAIContainer] = useState(visibleAiContainerFromQuery?(visibleAiContainerFromQuery==='true'):false);

  const [whereAmILevel, setWhereAmILevel] = useState(level);
  const [whereAmIBuilding, setWhereAmIBuilding] = useState(building);
  const [selectedFloor, setSelectedFloor] = useState(level);
  const [kioskLocation, setKioskLocation] = useState(1);

  const [eventStartDateData, setEventStartDateData] = useState({
    year: 2025,
    month: 3,
    day: eventStartDay
  });


  const [eventEndDateData, setEventEndDateData] = useState({
    year: 2025,
    month: 3,
    day: 14
  });


  useEffect(() => {
    if (kioskFid && kioskData[kioskFid]) {
      const data = kioskData[kioskFid];
      setLat(data.lat ||  42.18846877027224);
      setLong(data.long ||  -71.20235964462145);
      setRotation(data.rotation || 2.8);
      setZoom(data.zoom || 18);
      setPitch(data.pitch || 65);
      setBearing(data.bearing || -157.99999999999994);
      setLevel(data.level || 1);
      setWhereAmILevel(level);
      setBuilding(data.building || 9);
      setWhereAmIBuilding(building);
    }
  }, [kioskFid]);


  useEffect(() => {
    if (destFid && !hasPassedDestFidRef.current) {
      // Call any function or set up as needed for the first time `destFid` is passed
      hasPassedDestFidRef.current = true;
    }
  }, [destFid]);

  
  const showPopup = (poi, coordinates, cleanPopup = true,isMapClick=false) => {
    if (!poi) {
      if(popupState.poi){
        handleResetView(cleanPopup,isMapClick);
      } 
      const navigationViewController = window.PointrWebSDK.MapWidget.getInstance()?.getUiController().getNavigationViewController();
      window.PointrWebSDK.MapWidget.getInstance().getUiController().getMapViewController().hideRoute();
      navigationViewController.close();
      setPopupState({ poi: null, coordinates: null });
    
     
    } else {
      setPopupState({ poi, coordinates: (poi?.geometry?.type === 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
    }
  };
  const showCiscoSpacesPopup = () => {
    setCiscoSpacesPopup(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.PointrWebSDK) {
        setIsPointrSDKLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, []);
  // Handler to hide the popup
  const hidePopup = () => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
    setTriggerDirectionsPopup(false);
  };
  
  const badgeQrScanSuccess = async (badgeId) => {
    try{
    console.log("Badge Id received " + badgeId);
    showPopup(null, null, true, false);
    setQRScanBadgeInfoInput(null);
    let accessToken = await CiscoAiAuth.fetchAccessTokenForAttendee(eventId, badgeId);
    const attendeeInfo = await fetchAttendeeInfo(accessToken, eventId, { badgeId: badgeId },teaLogService);
    const userKey=attendeeInfo?.[0]?.userKey;     
    //const userFullName=attendeeInfo?.[0]?.firstName+" "+attendeeInfo?.[0]?.lastName;
    //const firstName=attendeeInfo?.[0]?.firstName
    //const lastName=attendeeInfo?.[0]?.lastName     
    //const dashboardPackage=attendeeInfo?.[0]?.dashboardPackage;     


    teaLogService.overrideConfiguration({"data":{"user":attendeeInfo?.[0]}})
    teaLogService.teaLogBadgeScanAction(badgeId,kioskLocation?kioskLocation:1);
    sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0].userKey, "Badge Scan",eventName,attendeeInfo?.[0]);
    const recommendedEvents = await fetchRecommendedEvents(accessToken,eventId,attendeeInfo,"",constraintsLookUp,teaLogService);
    if(recommendedEvents == undefined|| recommendedEvents?.length==0){
      setShowFailedPopupCard(true);
      setShowPopupCard(false);
      return;
    }
    setRecommendationsInfo(recommendedEvents);
    setAttendeeInfo(attendeeInfo);
    setAttendeeUserToken(accessToken)
    setShowPopupCard(false);
    setShowAICard(true);
    }catch(error){
      console.log("Exception occured while processing badge");
      console.log(error)
      setShowFailedPopupCard(true);
      setShowPopupCard(false);
    }
    
  }

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getZones = async () => {
      try {

        const data = await fetchGsxData(tenantId);
        console.log("gsx" + data);
        if(data.zones){
          setIsZonesInfoPopulated(true);
        }
        setZonesInfo(data.zones);
        setHubwalksInfo(data.hubWalks);
        setFacilitiesData(data.facilities);

      } catch (err) {

        console.log(err);

      }
    };

    getZones();
   // intialiseQRScannerModule();
  }, []);
  // Simulate map loading
  useEffect(() => {
    // Simulate a delay for the map loading
    const mapLoadTimeout = setTimeout(() => {
      setIsMapLoaded(true);
      document.title = 'HIMSS';
    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(mapLoadTimeout);
  }, []);

  useEffect(() => {
    let intervalId;
  
    const fetchPoiUntilFound = async () => {
      if (map && destFid) {
        const poi = await map.getPoiManager().get(destFid);
        if (poi) {
          // Uncomment this line when ready to show the popup
          const uiController = map.getUiController();
          const mapViewController = uiController.getMapViewController();
          mapViewController.setLevel(poi.properties.lvl);
          mapViewController.highlightPoi(poi);
           showPopup(poi, poi?.geometry?.type === 'Point' ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]),false,false);
           clearInterval(intervalId); // Stop checking once the poi is found
        }
        
      }
    };
  
    // Set up polling to run fetchPoiUntilFound every 500ms (adjust as needed)
    intervalId = setInterval(fetchPoiUntilFound, 5000);
  
    return () => clearInterval(intervalId); // Clear interval on component unmount or when dependencies change
  }, [map, destFid]);


  useEffect(() => {
    const eventTypes = ["click", "mousemove", "keypress", "wheel", "gesturestart", "gesturechange", "gestureend", "touchmove", "touchstart", "touchend"];

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleActivity);
    });

    return () => {
      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => { }, [isActive]);


  const handleActivity = (event) => {
    setIsActive(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
      console.log("No activity detected for 2 minutes." + event.type);
    }, 180000); // 3 minutes
  };


  const handleSearch = async (searchInput) => {
    hidePopup();
    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');

    console.log(searchInput);
    let valueAfterTilde;
    let poiFid = searchInput?.fId;
    if (searchInput?.type === 'session') {
      poiFid = searchInput?.poiFId;
    }else if((searchInput?.type === 'exhibitor')){
      poiFid = searchInput?.poiFId;
    }
    if (poiFid?.includes('~')) {
      valueAfterTilde = poiFid.split('~')[1];
    } else {
      valueAfterTilde = poiFid;
    }
    const poi = await map.getPoiManager().get(valueAfterTilde);
    if (poi) {
      map.unhighlight();
      mapViewController.setLevel(poi.properties.lvl);
      mapViewController.highlightPoi(poi);
      map.hidePoiDetails(poi);

      if (poi?._geometry?.coordinates[0]) {
        const latLong = (poi.geometry.type === 'Point') ? {
          lat: poi.geometry.coordinates[1],
          lng: poi.geometry.coordinates[0]
        } : calculateMidPoint(poi?._geometry?.coordinates[0]);

        //updateLayerFilterForSingleFeature(mapView, searchInput);
        mapView.map.flyTo({
          center: [latLong.lng, latLong.lat],
          zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
        });
        if (searchInput.type === 'session') {
          poi._properties.isSession = true;
          poi._properties.sessionsInfo = [searchInput];
          poi._properties.sessionStart= searchInput?.utcStartTime;

        }
        showPopup(poi, latLong);
        manageLayers(map?.getUiController().getMapViewController().getView(),poi?._properties?.bid,poi?._properties?.lvl)
        if(poi?._properties?.lvl==whereAmILevel){
          markCurrentPosition(map, long, lat, rotation, true, pitch, bearing, whereAmIBuilding, whereAmILevel, whereAmIBuilding, whereAmILevel, zoom, whereAmIScale);

        }else{
          removeCurrentPosition(map.getUiController().getMapViewController().getView());
        }
      }
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput);
    }
  }, [searchInput]);

  const intialiseQRScannerModule = async () =>{
      console.log("Initiliazing the QR code scanner");
      let hashes = getHashes(["username", "password", "ipAddress", "location"]);
      setKioskLocation(hashes?.location? hashes.location : 1)
      await initializeXAPIScanner(window.jsxapi,hashes,setQRScanBadgeInfoInput);
  }

  useEffect(() => {
    console.log("QR Input received "+qrScanBadgeInfoInput);
    if (qrScanBadgeInfoInput) {
      if(!showAICard && !showFaqList && showPopupCard){
        badgeQrScanSuccess(qrScanBadgeInfoInput);
        showAiLoader(true);
        setQRScanBadgeInfoInput(null);
      }else{
         console.error("Already Attendee is using the AI or no yet launched")
         setQRScanBadgeInfoInput(null);
      }
    }
  }, [qrScanBadgeInfoInput]);


  useEffect(() => {
    console.log("showAiLoader received "+aiLoader);
    if (aiLoader) {
      showLoader();
    }else{
      hideLoader();
    }
  }, [aiLoader]);


  useEffect(() => {
    console.log("showAICard received "+showAICard);
    if (!showAICard) {
      setShowFaqList(0);
      setQRScanBadgeInfoInput(null);
    }
  }, [showAICard]);

  // Function to create and show the loader
  function showLoader() {
    // Create and style the overlay element
    const overlay = document.createElement("div");
    overlay.id = "ai-overlay"; // Set the id
    overlay.className = "ai-overlay"; // Set the class name
    document.body.appendChild(overlay);

    // Create and style the loader element
    const loader = document.createElement("div");
    loader.id = "ai-loader"; // Set the id
    loader.className = "ai-loader"; // Set the class name
    document.body.appendChild(loader);

    // Display the loader and overlay
    if (loader && overlay) {
      overlay.style.display = "block";
      loader.style.display = "block";
    }
  }


  // Function to hide and remove the loader
  function hideLoader() {
    const loader = document.getElementById("ai-loader");
    const overlayloader = document.getElementById("ai-overlay");

    if (loader && overlayloader) {
      loader.style.display = "none";
      document.body.removeChild(loader);

      overlayloader.style.display = "none";
      document.body.removeChild(overlayloader);

    }
  }
  const handleResetView = (cleanPopup = false,isMapClick=false) => {
    setShowAICard(false);
    setShowFaqList(false);
    setTriggerDirectionsPopup(false);
    setSelectedFloor(whereAmILevel)
    handleReset(long ? long : -71.20129185010097, lat ? lat : 42.18882997249821, rotation ? rotation : -4.5, 18, true, cleanPopup,isMapClick, pitch, bearing, whereAmIBuilding, whereAmILevel, centerLong, centerLat, whereAmIScale);
  };


  const handleResetViewButton = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  const handleResetViewButtonInitialZoom = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    setSelectedFloor(whereAmILevel)
    handleResetView();
  //handleReset(long ? long : -115.1782890405586, lat ? lat : 36.088308032921276, rotation ? rotation : -4.5, 17, true, cleanPopup,false, pitch, bearing, building, level);

    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.map.flyTo({
      center: [long, lat],
      zoom: 18,
      bearing: bearing,
      pitch: pitch,
      speed:0.4
    });
  };

  return (
    <div className="main-container">

      {isPointrSDKLoaded && <MapComponent showPopup={showPopup} tenantId={tenantId}
        locationId={locationId} siteExternalIdentifier={siteExternalIdentifier} lat={lat} long={long} zoom={zoom} pitch={pitch} bearing={bearing} rotation={rotation} eventName = {eventName} whereAmILevel={parseInt(whereAmILevel)} whereAmIBuilding={parseInt(whereAmIBuilding)} defaultBuilding={parseInt(building)} defaultLevel={parseInt(level)} setBuilding={setBuilding} setLevel={setLevel} timeZone={timeZone} eventStartDateData={eventStartDateData} centerLatLong={[centerLong,centerLat]} 
        setTriggerDirectionsPopup={setTriggerDirectionsPopup} whereAmIScale={whereAmIScale}/>}
      {isMapLoaded && (
        
        <>
          {handleWhereAmI(map,long,lat,rotation,pitch,bearing,whereAmILevel, whereAmIBuilding)}
          <HeaderContainer
            showCiscoSpacesPopup={showCiscoSpacesPopup} timeZoneCity={timeZoneCity} 
            eventStartDateData = {eventStartDateData} eventEndDateData={eventEndDateData}  
            handleResetView={handleResetViewButton}  eventLogoName={eventLogoName} 
            eventName={eventName} defaultLevel={parseInt(level)} 
            defaultBuilding = {parseInt(building)} setBuilding={setBuilding} 
            setLevel={setLevel} pitch={pitch} 
            bearing={bearing} lat={lat} long={long} 
            rotation={rotation}
            eventTitle={eventTitle}
            eventSubTitle={eventSubTitle}
            eventTimeZone={timeZone}
            defaultBuildingTitle={"Woodbury"}
            buildingInfo={buildingInfo}
            whereAmIScale={whereAmIScale}
            displayDay ={false}/>
          {zonesInfo &&
            <LeftContainer
              zoneData={zonesInfo} showPopup={showPopup} hidePopup={hidePopup} tenantId={tenantId}
              locationId={locationId} qrCodeExpiresAt={qrCodeExpiresAt}
              whereAmIBuilding={whereAmIBuilding}
              whereAmILevel={whereAmILevel}
              defaultLevel={parseInt(level)} 
              defaultBuilding = {parseInt(building)}
              handleResetView={handleResetViewButton}
              setBuilding={setBuilding}
              setLevel={setLevel} 
              lat={lat}
              long={long}
              rotation={rotation}
              pitch={pitch}
              bearing={bearing}
              showLevelSelector={true}
              isZonesInfoPopulated={isZonesInfoPopulated}
              isDestFidPopulated={destFid?true:false}
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
              buildingInfo={buildingInfo}
              defaultBuildingTitle={defaultBuildingTitle}
              centerLatLong={[centerLong,centerLat]}
              eventName={eventName}
              floorColourMapping={floorColourMapping}
              whereAmIScale={whereAmIScale}
               />}
          {isPointrSDKLoaded && 
          <RightContainer
            hubwalksData={hubwalksInfo} 
            facilitiesData={facilitiesData} 
            pitch={pitch} 
            bearing={bearing} 
            centerLong={centerLong} 
            centerLat={centerLat}
            visibleAIContainer={visibleAIContainer}
            eventId={eventId}
            tenantId={tenantId}
            setShowPopupCard={setShowPopupCard}
            showAICard={showAICard}
            setShowAICard={setShowAICard}
            showPopup={showPopup}
            recommendationsInfo={recommendationsInfo}
            setRecommendationsInfo={setRecommendationsInfo}
            attendeeUserToken={attendeeUserToken}
            attendeeInfo={attendeeInfo}
            constraintsLookUp={constraintsLookUp}
            teaLogService={teaLogService}
            setShowFaqList={setShowFaqList}
            showFaqList={showFaqList}
            setTriggerDirectionsPopup={setTriggerDirectionsPopup}
            triggerDirectionsPopup={triggerDirectionsPopup}
            setPopupState={setPopupState}
            showAiLoader={showAiLoader}
            />}
          {(!isActive &&
          /** <CountdownTimer
              initialMinutes={0}
              initialSeconds={10}
              resetTimer={isActive} // Pass isActive as a prop to reset the timer
              showPopup={showPopup}
            /> */
            <Screensaver inactivityTimeout={1000} flipInterval={3000} showPopup={showPopup} ciscoSpacesPopupImage={ciscoSpacesPopupImage} lat={lat} long={long} zoom={zoom} rotation={rotation} pitch={pitch} bearing={bearing} whereAmILevel={whereAmILevel} centerLong={centerLong} centerLat={centerLat} setShowAICard={setShowAICard} setShowPopupCard={setShowPopupCard} whereAmIScale={whereAmIScale} displayScreenSaver={false}/>
           
          )}
          {(showFaqList==1) && <AIFAQListContainer setShowFaqList={setShowFaqList} showFaqList={showFaqList} setShowAICard={setShowAICard} showAICard={showAICard} setShowPopupCard={setShowPopupCard}/>}
          {showSearch && (
            <SearchPopup
              setSearchInput={setSearchInput}
              setShowSearch={setShowSearch}
              map={map}
              tenantId={tenantId}
              placeholder={"Search for points of interest and other facilities"}
            />
          )}
          {showPopupCard && (
            <div className="ai-popup-overlay">
              <div className="ai-popup-overlay-popup">
                <div className="ai-popup-overlay-popup-card">
                 
                  <img src={ciscoAILogo} class="ai-popup-overlay-popup-card-logo" />
                  <div className="ai-popup-overlay-popup-card-logo-name">
                  You have activated <br/>Cisco AI Assistant.
                  </div>
                  <div className="ai-popup-overlay-popup-card-title">
                  Now, scan the front of your<br/>badge on the scanner to begin<br/>your customized journey.​
                  </div>
                  <img src={ciscoAIQrPlaceHolderLogo} className="ai-popup-overlay-popup-card-qr-place-holder" onClick={()=>{badgeQrScanSuccess("1730740293847001Zs8f")}}  />
                  <div className="ai-popup-overlay-popup-card-bottom-button">
                  <img src={ciscoHelpbutton} className="ai-popup-overlay-popup-card-bottom-button-help"  onClick={() => {setShowPopupCard(false);setShowFailedPopupCard(false); setShowFaqList(1); }}/>

                  <img src={ciscoClosebutton} className="ai-popup-overlay-popup-card-bottom-button-close" onClick={() => setShowPopupCard(false)}/>
                  </div>
                </div>
              </div>
            </div>
          )}
           {showFailedPopupCard && (
            <div className="ai-popup-overlay">
              <div className="ai-popup-overlay-popup">
                <div className="ai-popup-overlay-popup-card">
                 
                  <img src={ciscoAILogo} class="ai-popup-overlay-popup-card-logo" />
                  <div className="ai-popup-overlay-popup-card-logo-name">
                  You have activated <br/>Cisco AI Assistant.
                  </div>
                  <div className="ai-popup-overlay-popup-card-title">
                  There was an error<br/>scanning your badge.​
                  </div>
                  <img src={ciscoAIQrPlaceHolderFailedLogo} className="ai-popup-overlay-popup-card-qr-place-holder"/>
                  <div className="ai-popup-overlay-popup-card-bottom-scan-again-button" onClick={()=>{ setShowFailedPopupCard(false); setShowPopupCard(true);}}>
                    Scan again
                  </div>

                  <div className="ai-popup-overlay-popup-card-bottom-button">
                  <img src={ciscoHelpbutton} className="ai-popup-overlay-popup-card-bottom-button-help"  onClick={() => {setShowPopupCard(false); setShowFailedPopupCard(false); setShowFaqList(1)}}/>

                  <img src={ciscoClosebutton} className="ai-popup-overlay-popup-card-bottom-button-close" onClick={() => {setShowPopupCard(false); setShowFailedPopupCard(false);}}/>
                  </div>
                </div>
              </div>
            </div>
          )}
          {popupState.poi && (
            <CustomPopup
              poi={popupState.poi}
              coordinates={popupState.coordinates}
              tenantId={tenantId}
              locationId={getLocationId(popupState.poi)}
              onCancel={hidePopup}
              setPopupState={setPopupState}
              source={"poiClick"}
              lat={lat}
              long={long}
              building={building}
              level={level}
              rotation={rotation}
              bearing={bearing}
              pitch={pitch}
              whereAmIBuilding={whereAmIBuilding}
              whereAmIlevel={whereAmILevel}
              qrCodeExpiresAt={qrCodeExpiresAt}
              destFid={hasPassedDestFidRef.current ? undefined : destFid} 
              setBuilding={setBuilding}
              setLevel={setLevel}
              eventStartDate={eventStartDateData}
              eventTimeZone={timeZone}
              setSelectedFloor={setSelectedFloor}
              triggerDirectionsPopup={triggerDirectionsPopup}
              setTriggerDirectionsPopup={setTriggerDirectionsPopup}
              whereAmIScale={whereAmIScale}
            />
          )}

          { aiLoader && <div  class="ai-overlay" id="ai-overlay" >
                    <div class="ai-loader" id="ai-loader" ></div>
                </div>}
          <FooterContainer
            setShowSearch={setShowSearch}
            handleResetView={handleResetViewButtonInitialZoom}
            long={long}
            lat={lat}
            rotation={rotation}
            pitch={pitch}
            bearing={bearing}
            whereAmIBuilding={parseInt(whereAmIBuilding)}
            whereAmILevel={parseInt(whereAmILevel)}
            setSelectedFloor={setSelectedFloor}
            whereAmIScale={whereAmIScale}
          />
        </>
      )}
    </div>
  );
};

export default HospitalVenueDemoMainContainer;
