import React, { useEffect, useState, useRef } from "react";
import MapComponent from "./PointrMapContainer";
import LeftContainer from "../left-container/left-container";
import HeaderContainer from "../header-container/header-container";
import RightContainer from "../right-container/right-container";
import SearchPopup from './SearchPopup'; // Import SearchPopup component
import FooterContainer from '../footer-container/footer-container';
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { handleReset } from './handleResetView'; // Import the functions
import { calculateMidPoint } from './markCurrentPosition'; // Import the functions
import { fetchGsxData } from '../left-container/fetchZonesHubwalksInfo';
import CustomPopup from './DirectionPopup';
import ciscoSpacesPopupIcon from '../../../../assets/images/powered-by-spaces.png';
import Screensaver from "../countdown-timer-container/screensaver";
import ciscoSpacesPopupImage from '../../../../assets/images/cisco-space-popup.png';

const MainContainer = () => {
  const { map } = useMap();

  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const [zonesInfo, setZonesInfo] = useState([]);
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);

  const timerRef = useRef(null);
  const [popupState, setPopupState] = useState({ poi: null, coordinates: null }); // Add state for the popup
const [ciscoSpacesPopup, setCiscoSpacesPopup] = useState(false);
  const [isPointrSDKLoaded, setIsPointrSDKLoaded] = useState(false); // State to track Pointr SDK load status
  // State for the date object in the parent component
  const [eventStartDateData, setEventStartDateData] = useState({
    year: 2024,
    month: 7,
    day: 27
  });

  const [eventEndDateData, setEventEndDateData] = useState({
    year: 2024,
    month: 7,
    day: 29
  });

  const tenantId = "26325";
  const locationId = "89468032-5da2-4204-bb90-4da339e35f1e";
  const timeZoneCity = "Las Vegas"
  const timeZone = "America/Los_Angeles"

  const qrCodeExpiresAt = 1735689598
  const eventLogoName = "cisco-gsx-logo.svg"
  const eventName = "GSX"

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const lat = query.get('lat') ? query.get('lat') : 36.088308032921276;
  const long = query.get('long') ? query.get('long') : -115.1782890405586;
  const [whereAmILevel, setWhereAmILevel] = useState(parseInt(query.get('level')) || 1);
  const [level, setLevel] = useState(parseInt(query.get('level')) || 1);
  const [building, setBuilding] = useState(parseInt(query.get('building')) || 1);
  const [whereAmIBuilding, setWhereAmIBuilding] = useState(parseInt(query.get('building')) || 1);
  const [selectedFloor, setSelectedFloor] = useState(level);

  const rotation = query.get('rotation');
  const zoom = query.get('zoom')? query.get('zoom') : 17;

  const pitch = query.get('pitch') ? query.get('pitch') : 65;
  const bearing = query.get('bearing') ? query.get('bearing') : -70;
  const centerLong =  -115.17791907047695;
  const centerLat =  36.087886474666035;
 
  const buildingInfo = [
    {
      "buildingTitle": "Mandalay Bay",
      "buildingInternalIdentifier": 1,
      "buildingExternalIdentifier": "f0f76e00-80c5-458d-8254-ec905e6c7fbd",
      "buildingExtraData": {
          "timeZone": "UTC"
      },
      "geometry": {
          "type": "Polygon",
          "coordinates": [
              [
                  [
                      -115.17936524,
                      36.08932927
                  ],
                  [
                      -115.17936523,
                      36.08933202
                  ],
                  [
                      -115.17935278,
                      36.08933199
                  ],
                  [
                      -115.1793528,
                      36.08932376
                  ],
                  [
                      -115.17930922,
                      36.08932366
                  ],
                  [
                      -115.17930903,
                      36.08938313
                  ],
                  [
                      -115.17930676,
                      36.08938312
                  ],
                  [
                      -115.17919922,
                      36.0893829
                  ],
                  [
                      -115.17919921,
                      36.08938473
                  ],
                  [
                      -115.17916072,
                      36.08938464
                  ],
                  [
                      -115.17915984,
                      36.08965565
                  ],
                  [
                      -115.17931493,
                      36.08965598
                  ],
                  [
                      -115.17931492,
                      36.08965827
                  ],
                  [
                      -115.17933756,
                      36.08965832
                  ],
                  [
                      -115.17933756,
                      36.08966015
                  ],
                  [
                      -115.17933133,
                      36.08966013
                  ],
                  [
                      -115.17933133,
                      36.08966048
                  ],
                  [
                      -115.17933791,
                      36.08966049
                  ],
                  [
                      -115.17933812,
                      36.08966066
                  ],
                  [
                      -115.17933791,
                      36.08966083
                  ],
                  [
                      -115.17933133,
                      36.08966082
                  ],
                  [
                      -115.17933127,
                      36.08967929
                  ],
                  [
                      -115.17933127,
                      36.08967963
                  ],
                  [
                      -115.17933127,
                      36.08967967
                  ],
                  [
                      -115.17933121,
                      36.0896981
                  ],
                  [
                      -115.17933121,
                      36.08969878
                  ],
                  [
                      -115.17933743,
                      36.0896988
                  ],
                  [
                      -115.17933743,
                      36.08970063
                  ],
                  [
                      -115.17931479,
                      36.08970058
                  ],
                  [
                      -115.17931465,
                      36.08974312
                  ],
                  [
                      -115.17933729,
                      36.08974316
                  ],
                  [
                      -115.17933728,
                      36.08974499
                  ],
                  [
                      -115.17933106,
                      36.08974498
                  ],
                  [
                      -115.17933105,
                      36.08974532
                  ],
                  [
                      -115.17933105,
                      36.08974567
                  ],
                  [
                      -115.17933105,
                      36.08974571
                  ],
                  [
                      -115.17933099,
                      36.08976413
                  ],
                  [
                      -115.17933099,
                      36.08976448
                  ],
                  [
                      -115.17933099,
                      36.08976452
                  ],
                  [
                      -115.17933093,
                      36.08978295
                  ],
                  [
                      -115.17933093,
                      36.08978363
                  ],
                  [
                      -115.17933716,
                      36.08978364
                  ],
                  [
                      -115.17933715,
                      36.08978547
                  ],
                  [
                      -115.17931451,
                      36.08978543
                  ],
                  [
                      -115.1793145,
                      36.08978771
                  ],
                  [
                      -115.17918432,
                      36.08978744
                  ],
                  [
                      -115.17918404,
                      36.0898716
                  ],
                  [
                      -115.17915914,
                      36.08987155
                  ],
                  [
                      -115.17915885,
                      36.08996005
                  ],
                  [
                      -115.17915881,
                      36.08996189
                  ],
                  [
                      -115.17863867,
                      36.08996078
                  ],
                  [
                      -115.17863869,
                      36.08995391
                  ],
                  [
                      -115.17863885,
                      36.08990589
                  ],
                  [
                      -115.17817527,
                      36.0899049
                  ],
                  [
                      -115.17817689,
                      36.08940816
                  ],
                  [
                      -115.17725245,
                      36.08940623
                  ],
                  [
                      -115.17725289,
                      36.08924453
                  ],
                  [
                      -115.1772529,
                      36.08924259
                  ],
                  [
                      -115.17725551,
                      36.08924049
                  ],
                  [
                      -115.1772443,
                      36.08923139
                  ],
                  [
                      -115.1772449,
                      36.0892309
                  ],
                  [
                      -115.1772365,
                      36.08922408
                  ],
                  [
                      -115.17718262,
                      36.08918031
                  ],
                  [
                      -115.17718202,
                      36.0891808
                  ],
                  [
                      -115.17717244,
                      36.08917301
                  ],
                  [
                      -115.17717304,
                      36.08917253
                  ],
                  [
                      -115.17716316,
                      36.0891645
                  ],
                  [
                      -115.17711077,
                      36.08912194
                  ],
                  [
                      -115.17711017,
                      36.08912243
                  ],
                  [
                      -115.17710777,
                      36.08912048
                  ],
                  [
                      -115.17710059,
                      36.08911464
                  ],
                  [
                      -115.17710119,
                      36.08911416
                  ],
                  [
                      -115.1770477,
                      36.08907071
                  ],
                  [
                      -115.17704529,
                      36.08907264
                  ],
                  [
                      -115.177045,
                      36.08907264
                  ],
                  [
                      -115.17704499,
                      36.0890724
                  ],
                  [
                      -115.1770474,
                      36.08907046
                  ],
                  [
                      -115.17703892,
                      36.08906357
                  ],
                  [
                      -115.17703832,
                      36.08906406
                  ],
                  [
                      -115.17703113,
                      36.08905822
                  ],
                  [
                      -115.17702852,
                      36.08906031
                  ],
                  [
                      -115.17702732,
                      36.08905934
                  ],
                  [
                      -115.17702613,
                      36.08905837
                  ],
                  [
                      -115.17702412,
                      36.08905998
                  ],
                  [
                      -115.17701682,
                      36.08905405
                  ],
                  [
                      -115.17701602,
                      36.0890547
                  ],
                  [
                      -115.17701482,
                      36.08905373
                  ],
                  [
                      -115.17701362,
                      36.08905469
                  ],
                  [
                      -115.17700963,
                      36.08905145
                  ],
                  [
                      -115.17701083,
                      36.08905048
                  ],
                  [
                      -115.17700963,
                      36.08904951
                  ],
                  [
                      -115.17701044,
                      36.08904887
                  ],
                  [
                      -115.17700017,
                      36.08904052
                  ],
                  [
                      -115.17700417,
                      36.08903731
                  ],
                  [
                      -115.1769898,
                      36.08902564
                  ],
                  [
                      -115.1769858,
                      36.08902885
                  ],
                  [
                      -115.17697591,
                      36.08902082
                  ],
                  [
                      -115.17697511,
                      36.08902147
                  ],
                  [
                      -115.17697391,
                      36.08902049
                  ],
                  [
                      -115.17697271,
                      36.08902146
                  ],
                  [
                      -115.17696872,
                      36.08901822
                  ],
                  [
                      -115.17696992,
                      36.08901725
                  ],
                  [
                      -115.17696872,
                      36.08901628
                  ],
                  [
                      -115.17696953,
                      36.08901563
                  ],
                  [
                      -115.17694537,
                      36.08899601
                  ],
                  [
                      -115.17694457,
                      36.08899665
                  ],
                  [
                      -115.17694337,
                      36.08899568
                  ],
                  [
                      -115.17694216,
                      36.08899665
                  ],
                  [
                      -115.17693817,
                      36.0889934
                  ],
                  [
                      -115.17693938,
                      36.08899244
                  ],
                  [
                      -115.17693818,
                      36.08899146
                  ],
                  [
                      -115.17693898,
                      36.08899082
                  ],
                  [
                      -115.17693208,
                      36.08898522
                  ],
                  [
                      -115.1769189,
                      36.08898077
                  ],
                  [
                      -115.17690912,
                      36.08898075
                  ],
                  [
                      -115.17690912,
                      36.08898168
                  ],
                  [
                      -115.17690742,
                      36.08898168
                  ],
                  [
                      -115.17690742,
                      36.08898305
                  ],
                  [
                      -115.17690176,
                      36.08898304
                  ],
                  [
                      -115.17690176,
                      36.08898167
                  ],
                  [
                      -115.17690006,
                      36.08898166
                  ],
                  [
                      -115.17690007,
                      36.08898073
                  ],
                  [
                      -115.17685915,
                      36.08898064
                  ],
                  [
                      -115.17685915,
                      36.08898157
                  ],
                  [
                      -115.17685745,
                      36.08898157
                  ],
                  [
                      -115.17685745,
                      36.08898294
                  ],
                  [
                      -115.17685179,
                      36.08898293
                  ],
                  [
                      -115.17685179,
                      36.08898156
                  ],
                  [
                      -115.17685009,
                      36.08898156
                  ],
                  [
                      -115.1768501,
                      36.08898062
                  ],
                  [
                      -115.176815,
                      36.08898055
                  ],
                  [
                      -115.176815,
                      36.08898148
                  ],
                  [
                      -115.1768133,
                      36.08898148
                  ],
                  [
                      -115.1768133,
                      36.08898285
                  ],
                  [
                      -115.17680764,
                      36.08898284
                  ],
                  [
                      -115.17680764,
                      36.08898147
                  ],
                  [
                      -115.17680594,
                      36.08898146
                  ],
                  [
                      -115.17680595,
                      36.08898053
                  ],
                  [
                      -115.17676746,
                      36.08898045
                  ],
                  [
                      -115.17676747,
                      36.08897612
                  ],
                  [
                      -115.17675389,
                      36.08897609
                  ],
                  [
                      -115.17675387,
                      36.08898042
                  ],
                  [
                      -115.17672982,
                      36.08898037
                  ],
                  [
                      -115.17672982,
                      36.088979
                  ],
                  [
                      -115.17668907,
                      36.08897892
                  ],
                  [
                      -115.17668921,
                      36.08893433
                  ],
                  [
                      -115.17662638,
                      36.0889342
                  ],
                  [
                      -115.17662712,
                      36.08870845
                  ],
                  [
                      -115.17662882,
                      36.08870845
                  ],
                  [
                      -115.17662887,
                      36.08870845
                  ],
                  [
                      -115.17663731,
                      36.08870847
                  ],
                  [
                      -115.17663732,
                      36.08870573
                  ],
                  [
                      -115.17667128,
                      36.0887058
                  ],
                  [
                      -115.17667127,
                      36.08870854
                  ],
                  [
                      -115.17665967,
                      36.08870852
                  ],
                  [
                      -115.17665966,
                      36.08871122
                  ],
                  [
                      -115.17665966,
                      36.08871126
                  ],
                  [
                      -115.17665966,
                      36.08871129
                  ],
                  [
                      -115.17665963,
                      36.0887195
                  ],
                  [
                      -115.17665961,
                      36.08872538
                  ],
                  [
                      -115.17672169,
                      36.08872552
                  ],
                  [
                      -115.17672719,
                      36.0887393
                  ],
                  [
                      -115.17674255,
                      36.08875649
                  ],
                  [
                      -115.17674086,
                      36.08875748
                  ],
                  [
                      -115.17674439,
                      36.08876035
                  ],
                  [
                      -115.17675873,
                      36.08877199
                  ],
                  [
                      -115.17675876,
                      36.08877202
                  ],
                  [
                      -115.17675878,
                      36.08877205
                  ],
                  [
                      -115.1767623,
                      36.08877489
                  ],
                  [
                      -115.17676356,
                      36.0887735
                  ],
                  [
                      -115.17678451,
                      36.08878587
                  ],
                  [
                      -115.17682361,
                      36.08879623
                  ],
                  [
                      -115.17686476,
                      36.08879632
                  ],
                  [
                      -115.17689087,
                      36.08878953
                  ],
                  [
                      -115.17690053,
                      36.08878701
                  ],
                  [
                      -115.17690057,
                      36.088787
                  ],
                  [
                      -115.17690062,
                      36.08878699
                  ],
                  [
                      -115.17690393,
                      36.08878613
                  ],
                  [
                      -115.17693729,
                      36.08876665
                  ],
                  [
                      -115.17697169,
                      36.0887286
                  ],
                  [
                      -115.17697338,
                      36.08872905
                  ],
                  [
                      -115.1770136,
                      36.08873008
                  ],
                  [
                      -115.17701502,
                      36.08873009
                  ],
                  [
                      -115.177021,
                      36.0887301
                  ],
                  [
                      -115.17702242,
                      36.0887301
                  ],
                  [
                      -115.17702247,
                      36.0887301
                  ],
                  [
                      -115.17705497,
                      36.08873017
                  ],
                  [
                      -115.17705666,
                      36.08873017
                  ],
                  [
                      -115.1770567,
                      36.08873017
                  ],
                  [
                      -115.1770801,
                      36.08873022
                  ],
                  [
                      -115.17708015,
                      36.08873022
                  ],
                  [
                      -115.17708019,
                      36.08873022
                  ],
                  [
                      -115.17708185,
                      36.08873023
                  ],
                  [
                      -115.17708181,
                      36.08874166
                  ],
                  [
                      -115.17708178,
                      36.08875373
                  ],
                  [
                      -115.17708178,
                      36.08875377
                  ],
                  [
                      -115.17708178,
                      36.08875381
                  ],
                  [
                      -115.17708176,
                      36.08875971
                  ],
                  [
                      -115.17708176,
                      36.08875975
                  ],
                  [
                      -115.17708176,
                      36.08875979
                  ],
                  [
                      -115.17708172,
                      36.0887721
                  ],
                  [
                      -115.1770817,
                      36.08877759
                  ],
                  [
                      -115.17714821,
                      36.08877773
                  ],
                  [
                      -115.1771484,
                      36.08871939
                  ],
                  [
                      -115.1771467,
                      36.08871939
                  ],
                  [
                      -115.1771467,
                      36.08871802
                  ],
                  [
                      -115.1771467,
                      36.08871798
                  ],
                  [
                      -115.17714728,
                      36.08867046
                  ],
                  [
                      -115.17714729,
                      36.08866772
                  ],
                  [
                      -115.17714756,
                      36.08858538
                  ],
                  [
                      -115.17714713,
                      36.08858538
                  ],
                  [
                      -115.17714728,
                      36.08854102
                  ],
                  [
                      -115.17714728,
                      36.08853964
                  ],
                  [
                      -115.17725766,
                      36.08853988
                  ],
                  [
                      -115.17725797,
                      36.0884438
                  ],
                  [
                      -115.17726216,
                      36.08844381
                  ],
                  [
                      -115.17726221,
                      36.08844381
                  ],
                  [
                      -115.17726225,
                      36.08844381
                  ],
                  [
                      -115.17728259,
                      36.08844386
                  ],
                  [
                      -115.17728514,
                      36.08844386
                  ],
                  [
                      -115.17728532,
                      36.08838897
                  ],
                  [
                      -115.17729381,
                      36.08838899
                  ],
                  [
                      -115.17729391,
                      36.08835699
                  ],
                  [
                      -115.17724976,
                      36.0883569
                  ],
                  [
                      -115.17724977,
                      36.08835507
                  ],
                  [
                      -115.17725656,
                      36.08835508
                  ],
                  [
                      -115.17725656,
                      36.08835439
                  ],
                  [
                      -115.17725656,
                      36.08835435
                  ],
                  [
                      -115.17725663,
                      36.08833295
                  ],
                  [
                      -115.17725663,
                      36.08833261
                  ],
                  [
                      -115.17725663,
                      36.08833257
                  ],
                  [
                      -115.1772567,
                      36.08831117
                  ],
                  [
                      -115.17725671,
                      36.08830843
                  ],
                  [
                      -115.17725675,
                      36.0882963
                  ],
                  [
                      -115.17725619,
                      36.0882963
                  ],
                  [
                      -115.17725557,
                      36.08829608
                  ],
                  [
                      -115.17725534,
                      36.08829561
                  ],
                  [
                      -115.17725534,
                      36.08829516
                  ],
                  [
                      -115.17724112,
                      36.08829513
                  ],
                  [
                      -115.17724069,
                      36.08829513
                  ],
                  [
                      -115.17724065,
                      36.08829513
                  ],
                  [
                      -115.17722639,
                      36.0882951
                  ],
                  [
                      -115.17722646,
                      36.08830018
                  ],
                  [
                      -115.17722624,
                      36.08830036
                  ],
                  [
                      -115.17722603,
                      36.08830018
                  ],
                  [
                      -115.17722497,
                      36.08830018
                  ],
                  [
                      -115.17722494,
                      36.08830904
                  ],
                  [
                      -115.17722537,
                      36.08830913
                  ],
                  [
                      -115.17722544,
                      36.0883103
                  ],
                  [
                      -115.17722409,
                      36.08831033
                  ],
                  [
                      -115.17722388,
                      36.08831027
                  ],
                  [
                      -115.17722388,
                      36.0883099
                  ],
                  [
                      -115.17717011,
                      36.08830978
                  ],
                  [
                      -115.17716845,
                      36.088309
                  ],
                  [
                      -115.17715497,
                      36.08830884
                  ],
                  [
                      -115.17715369,
                      36.08830884
                  ],
                  [
                      -115.17715369,
                      36.08830849
                  ],
                  [
                      -115.17714867,
                      36.08830848
                  ],
                  [
                      -115.17714846,
                      36.0883083
                  ],
                  [
                      -115.17714867,
                      36.08830814
                  ],
                  [
                      -115.17714871,
                      36.08829665
                  ],
                  [
                      -115.1771485,
                      36.08829647
                  ],
                  [
                      -115.17714871,
                      36.0882963
                  ],
                  [
                      -115.17714875,
                      36.08828481
                  ],
                  [
                      -115.17714854,
                      36.08828463
                  ],
                  [
                      -115.17714875,
                      36.08828447
                  ],
                  [
                      -115.17715377,
                      36.08828448
                  ],
                  [
                      -115.17715377,
                      36.08828413
                  ],
                  [
                      -115.17715505,
                      36.08828414
                  ],
                  [
                      -115.17716849,
                      36.08828417
                  ],
                  [
                      -115.17716853,
                      36.08828281
                  ],
                  [
                      -115.17716913,
                      36.08828274
                  ],
                  [
                      -115.17716921,
                      36.08825792
                  ],
                  [
                      -115.17716858,
                      36.08825777
                  ],
                  [
                      -115.17716858,
                      36.08825609
                  ],
                  [
                      -115.17705198,
                      36.08825584
                  ],
                  [
                      -115.17705198,
                      36.08825607
                  ],
                  [
                      -115.17705187,
                      36.08829061
                  ],
                  [
                      -115.1770496,
                      36.0882906
                  ],
                  [
                      -115.17704896,
                      36.0882906
                  ],
                  [
                      -115.17704876,
                      36.08829043
                  ],
                  [
                      -115.17704877,
                      36.08828511
                  ],
                  [
                      -115.1770233,
                      36.08828506
                  ],
                  [
                      -115.17702328,
                      36.08829038
                  ],
                  [
                      -115.17702306,
                      36.08829055
                  ],
                  [
                      -115.17702286,
                      36.08829037
                  ],
                  [
                      -115.17702017,
                      36.08829037
                  ],
                  [
                      -115.17702028,
                      36.08825601
                  ],
                  [
                      -115.17702028,
                      36.08825578
                  ],
                  [
                      -115.17701462,
                      36.08825577
                  ],
                  [
                      -115.17701349,
                      36.08825576
                  ],
                  [
                      -115.17700642,
                      36.08825575
                  ],
                  [
                      -115.17700633,
                      36.08828228
                  ],
                  [
                      -115.17700407,
                      36.08828227
                  ],
                  [
                      -115.17700407,
                      36.0882821
                  ],
                  [
                      -115.17700364,
                      36.0882821
                  ],
                  [
                      -115.17700344,
                      36.08828227
                  ],
                  [
                      -115.17700322,
                      36.0882821
                  ],
                  [
                      -115.17700323,
                      36.08827678
                  ],
                  [
                      -115.17697939,
                      36.08827673
                  ],
                  [
                      -115.17697897,
                      36.08827673
                  ],
                  [
                      -115.17697892,
                      36.08827673
                  ],
                  [
                      -115.17695512,
                      36.08827668
                  ],
                  [
                      -115.17695511,
                      36.088282
                  ],
                  [
                      -115.17695426,
                      36.088282
                  ],
                  [
                      -115.17695426,
                      36.08828217
                  ],
                  [
                      -115.17695199,
                      36.08828216
                  ],
                  [
                      -115.17695208,
                      36.08825563
                  ],
                  [
                      -115.17690296,
                      36.08825553
                  ],
                  [
                      -115.17690296,
                      36.08826592
                  ],
                  [
                      -115.17690296,
                      36.08826595
                  ],
                  [
                      -115.17690293,
                      36.08828206
                  ],
                  [
                      -115.17690218,
                      36.08828206
                  ],
                  [
                      -115.17690219,
                      36.08827964
                  ],
                  [
                      -115.17690134,
                      36.08827964
                  ],
                  [
                      -115.17690129,
                      36.08827964
                  ],
                  [
                      -115.1768775,
                      36.08827961
                  ],
                  [
                      -115.17687707,
                      36.08827961
                  ],
                  [
                      -115.17687703,
                      36.08827961
                  ],
                  [
                      -115.17685323,
                      36.08827958
                  ],
                  [
                      -115.17685238,
                      36.08827958
                  ],
                  [
                      -115.17685242,
                      36.08826589
                  ],
                  [
                      -115.17685242,
                      36.08826585
                  ],
                  [
                      -115.17685242,
                      36.08826582
                  ],
                  [
                      -115.17685246,
                      36.08825565
                  ],
                  [
                      -115.17685272,
                      36.08825544
                  ],
                  [
                      -115.17685271,
                      36.08825542
                  ],
                  [
                      -115.17639443,
                      36.08825445
                  ],
                  [
                      -115.17639438,
                      36.088281
                  ],
                  [
                      -115.17639276,
                      36.08828097
                  ],
                  [
                      -115.17639277,
                      36.08827896
                  ],
                  [
                      -115.17639192,
                      36.08827896
                  ],
                  [
                      -115.17639188,
                      36.08827896
                  ],
                  [
                      -115.17636807,
                      36.08827893
                  ],
                  [
                      -115.17636765,
                      36.08827893
                  ],
                  [
                      -115.1763676,
                      36.08827893
                  ],
                  [
                      -115.17634381,
                      36.0882789
                  ],
                  [
                      -115.17634296,
                      36.0882789
                  ],
                  [
                      -115.17634295,
                      36.08828087
                  ],
                  [
                      -115.17634069,
                      36.08828086
                  ],
                  [
                      -115.17634077,
                      36.08825456
                  ],
                  [
                      -115.1763406,
                      36.08825433
                  ],
                  [
                      -115.17633948,
                      36.08825433
                  ],
                  [
                      -115.17629256,
                      36.08825423
                  ],
                  [
                      -115.17629251,
                      36.08828076
                  ],
                  [
                      -115.17629088,
                      36.08828076
                  ],
                  [
                      -115.17629088,
                      36.08827883
                  ],
                  [
                      -115.17629003,
                      36.08827883
                  ],
                  [
                      -115.17628999,
                      36.08827883
                  ],
                  [
                      -115.17626619,
                      36.0882788
                  ],
                  [
                      -115.17626576,
                      36.0882788
                  ],
                  [
                      -115.17626572,
                      36.0882788
                  ],
                  [
                      -115.17624192,
                      36.08827876
                  ],
                  [
                      -115.17624107,
                      36.08827876
                  ],
                  [
                      -115.17624107,
                      36.08828065
                  ],
                  [
                      -115.17623971,
                      36.08828056
                  ],
                  [
                      -115.17623974,
                      36.08826508
                  ],
                  [
                      -115.17623974,
                      36.08826504
                  ],
                  [
                      -115.17623974,
                      36.088265
                  ],
                  [
                      -115.17623977,
                      36.08825412
                  ],
                  [
                      -115.17605435,
                      36.08825372
                  ],
                  [
                      -115.17598559,
                      36.08825358
                  ],
                  [
                      -115.17598562,
                      36.08824534
                  ],
                  [
                      -115.17598181,
                      36.08824534
                  ],
                  [
                      -115.17598177,
                      36.08824534
                  ],
                  [
                      -115.17591558,
                      36.0882452
                  ],
                  [
                      -115.17591559,
                      36.08824382
                  ],
                  [
                      -115.17588487,
                      36.08824376
                  ],
                  [
                      -115.1758849,
                      36.08823415
                  ],
                  [
                      -115.1758832,
                      36.08823415
                  ],
                  [
                      -115.17588331,
                      36.08820259
                  ],
                  [
                      -115.17588501,
                      36.08820259
                  ],
                  [
                      -115.17588521,
                      36.08813901
                  ],
                  [
                      -115.17587688,
                      36.08713137
                  ],
                  [
                      -115.17587482,
                      36.08713136
                  ],
                  [
                      -115.17587478,
                      36.08713136
                  ],
                  [
                      -115.17587473,
                      36.08713136
                  ],
                  [
                      -115.17587345,
                      36.08713136
                  ],
                  [
                      -115.17587355,
                      36.08709744
                  ],
                  [
                      -115.17581728,
                      36.08709732
                  ],
                  [
                      -115.17581729,
                      36.0870955
                  ],
                  [
                      -115.1758181,
                      36.0870955
                  ],
                  [
                      -115.17581814,
                      36.08708939
                  ],
                  [
                      -115.1758191,
                      36.08708622
                  ],
                  [
                      -115.1758203,
                      36.08708451
                  ],
                  [
                      -115.1758219,
                      36.08708303
                  ],
                  [
                      -115.17582384,
                      36.08708184
                  ],
                  [
                      -115.17582604,
                      36.087081
                  ],
                  [
                      -115.17583002,
                      36.08708046
                  ],
                  [
                      -115.17586736,
                      36.08708052
                  ],
                  [
                      -115.1758677,
                      36.08683306
                  ],
                  [
                      -115.17586818,
                      36.08682939
                  ],
                  [
                      -115.17586901,
                      36.08682703
                  ],
                  [
                      -115.17587023,
                      36.08682478
                  ],
                  [
                      -115.17587183,
                      36.08682269
                  ],
                  [
                      -115.17587377,
                      36.08682079
                  ],
                  [
                      -115.17587601,
                      36.08681913
                  ],
                  [
                      -115.17587851,
                      36.08681774
                  ],
                  [
                      -115.17588122,
                      36.08681663
                  ],
                  [
                      -115.1758841,
                      36.08681584
                  ],
                  [
                      -115.1758886,
                      36.08681526
                  ],
                  [
                      -115.17600722,
                      36.08681673
                  ],
                  [
                      -115.17600723,
                      36.08681485
                  ],
                  [
                      -115.17600746,
                      36.08674169
                  ],
                  [
                      -115.17600746,
                      36.08674165
                  ],
                  [
                      -115.17600746,
                      36.08674161
                  ],
                  [
                      -115.17600747,
                      36.08673891
                  ],
                  [
                      -115.17601394,
                      36.08673877
                  ],
                  [
                      -115.17601394,
                      36.08673682
                  ],
                  [
                      -115.17601593,
                      36.08673663
                  ],
                  [
                      -115.17601564,
                      36.0867346
                  ],
                  [
                      -115.17612663,
                      36.08672403
                  ],
                  [
                      -115.17616968,
                      36.08671871
                  ],
                  [
                      -115.17621218,
                      36.08671103
                  ],
                  [
                      -115.17625393,
                      36.08670102
                  ],
                  [
                      -115.17629474,
                      36.08668873
                  ],
                  [
                      -115.17633441,
                      36.08667421
                  ],
                  [
                      -115.17637276,
                      36.08665754
                  ],
                  [
                      -115.17640961,
                      36.08663879
                  ],
                  [
                      -115.1764099,
                      36.08663914
                  ],
                  [
                      -115.1767567,
                      36.08644875
                  ],
                  [
                      -115.17679723,
                      36.08642806
                  ],
                  [
                      -115.17683937,
                      36.08640959
                  ],
                  [
                      -115.17688294,
                      36.08639344
                  ],
                  [
                      -115.17692774,
                      36.08637966
                  ],
                  [
                      -115.17697358,
                      36.08636832
                  ],
                  [
                      -115.17702025,
                      36.08635947
                  ],
                  [
                      -115.17706755,
                      36.08635315
                  ],
                  [
                      -115.17711526,
                      36.08634939
                  ],
                  [
                      -115.17716318,
                      36.0863482
                  ],
                  [
                      -115.17728367,
                      36.08634846
                  ],
                  [
                      -115.17728935,
                      36.08634862
                  ],
                  [
                      -115.177295,
                      36.08634909
                  ],
                  [
                      -115.1773006,
                      36.08634987
                  ],
                  [
                      -115.17730613,
                      36.08635095
                  ],
                  [
                      -115.17731155,
                      36.08635232
                  ],
                  [
                      -115.17731684,
                      36.08635399
                  ],
                  [
                      -115.17732199,
                      36.08635593
                  ],
                  [
                      -115.17732697,
                      36.08635815
                  ],
                  [
                      -115.17733175,
                      36.08636064
                  ],
                  [
                      -115.17733631,
                      36.08636338
                  ],
                  [
                      -115.17734063,
                      36.08636635
                  ],
                  [
                      -115.1773447,
                      36.08636956
                  ],
                  [
                      -115.1773485,
                      36.08637298
                  ],
                  [
                      -115.177352,
                      36.08637659
                  ],
                  [
                      -115.1773552,
                      36.08638039
                  ],
                  [
                      -115.17735808,
                      36.08638435
                  ],
                  [
                      -115.17736062,
                      36.08638846
                  ],
                  [
                      -115.17736281,
                      36.0863927
                  ],
                  [
                      -115.17736465,
                      36.08639704
                  ],
                  [
                      -115.17736613,
                      36.08640148
                  ],
                  [
                      -115.17736631,
                      36.08640305
                  ],
                  [
                      -115.17750424,
                      36.08640335
                  ],
                  [
                      -115.17750428,
                      36.08639009
                  ],
                  [
                      -115.17750478,
                      36.08638449
                  ],
                  [
                      -115.17750538,
                      36.08638172
                  ],
                  [
                      -115.17750727,
                      36.08637631
                  ],
                  [
                      -115.17750855,
                      36.0863737
                  ],
                  [
                      -115.17751177,
                      36.08636872
                  ],
                  [
                      -115.17751369,
                      36.08636637
                  ],
                  [
                      -115.17751579,
                      36.08636414
                  ],
                  [
                      -115.17752055,
                      36.08636004
                  ],
                  [
                      -115.17752596,
                      36.08635651
                  ],
                  [
                      -115.17752887,
                      36.08635497
                  ],
                  [
                      -115.17753191,
                      36.0863536
                  ],
                  [
                      -115.17753829,
                      36.08635137
                  ],
                  [
                      -115.17754499,
                      36.08634987
                  ],
                  [
                      -115.17754842,
                      36.0863494
                  ],
                  [
                      -115.17755536,
                      36.08634903
                  ],
                  [
                      -115.17792281,
                      36.08634981
                  ],
                  [
                      -115.17794125,
                      36.08635036
                  ],
                  [
                      -115.17795959,
                      36.08635191
                  ],
                  [
                      -115.17797777,
                      36.08635447
                  ],
                  [
                      -115.17799569,
                      36.08635802
                  ],
                  [
                      -115.17801326,
                      36.08636254
                  ],
                  [
                      -115.17803042,
                      36.08636802
                  ],
                  [
                      -115.17804708,
                      36.08637442
                  ],
                  [
                      -115.17806316,
                      36.08638173
                  ],
                  [
                      -115.17807859,
                      36.0863899
                  ],
                  [
                      -115.17809331,
                      36.08639889
                  ],
                  [
                      -115.17810723,
                      36.08640867
                  ],
                  [
                      -115.17812029,
                      36.0864192
                  ],
                  [
                      -115.17843181,
                      36.08668769
                  ],
                  [
                      -115.17844992,
                      36.08670229
                  ],
                  [
                      -115.1784692,
                      36.08671589
                  ],
                  [
                      -115.17848956,
                      36.08672842
                  ],
                  [
                      -115.1785109,
                      36.08673982
                  ],
                  [
                      -115.17853314,
                      36.08675005
                  ],
                  [
                      -115.17855617,
                      36.08675906
                  ],
                  [
                      -115.1785799,
                      36.08676681
                  ],
                  [
                      -115.17860421,
                      36.08677326
                  ],
                  [
                      -115.17862899,
                      36.08677839
                  ],
                  [
                      -115.17865415,
                      36.08678218
                  ],
                  [
                      -115.17867956,
                      36.0867846
                  ],
                  [
                      -115.17869072,
                      36.08678566
                  ],
                  [
                      -115.17870176,
                      36.08678731
                  ],
                  [
                      -115.17871265,
                      36.08678955
                  ],
                  [
                      -115.17872333,
                      36.08679237
                  ],
                  [
                      -115.17873375,
                      36.08679575
                  ],
                  [
                      -115.17874387,
                      36.08679968
                  ],
                  [
                      -115.17875365,
                      36.08680415
                  ],
                  [
                      -115.17876305,
                      36.08680912
                  ],
                  [
                      -115.17877201,
                      36.08681459
                  ],
                  [
                      -115.17878051,
                      36.08682052
                  ],
                  [
                      -115.17878851,
                      36.0868269
                  ],
                  [
                      -115.17879597,
                      36.08683369
                  ],
                  [
                      -115.17880285,
                      36.08684086
                  ],
                  [
                      -115.17880914,
                      36.08684838
                  ],
                  [
                      -115.17881479,
                      36.08685623
                  ],
                  [
                      -115.17881979,
                      36.08686435
                  ],
                  [
                      -115.17882411,
                      36.08687273
                  ],
                  [
                      -115.17882774,
                      36.08688132
                  ],
                  [
                      -115.17883066,
                      36.08689009
                  ],
                  [
                      -115.17887185,
                      36.08703811
                  ],
                  [
                      -115.17892431,
                      36.08718378
                  ],
                  [
                      -115.17898786,
                      36.08732654
                  ],
                  [
                      -115.1796827,
                      36.08879938
                  ],
                  [
                      -115.1797515,
                      36.08895627
                  ],
                  [
                      -115.17981092,
                      36.08911564
                  ],
                  [
                      -115.17981173,
                      36.08911837
                  ],
                  [
                      -115.17981265,
                      36.08912394
                  ],
                  [
                      -115.17981262,
                      36.08912955
                  ],
                  [
                      -115.17981164,
                      36.08913511
                  ],
                  [
                      -115.17980974,
                      36.08914051
                  ],
                  [
                      -115.17980695,
                      36.08914564
                  ],
                  [
                      -115.17980332,
                      36.08915043
                  ],
                  [
                      -115.17980121,
                      36.08915266
                  ],
                  [
                      -115.17979645,
                      36.08915675
                  ],
                  [
                      -115.17979105,
                      36.08916028
                  ],
                  [
                      -115.1797851,
                      36.08916318
                  ],
                  [
                      -115.17977872,
                      36.0891654
                  ],
                  [
                      -115.17977203,
                      36.0891669
                  ],
                  [
                      -115.17976515,
                      36.08916765
                  ],
                  [
                      -115.17970538,
                      36.08916762
                  ],
                  [
                      -115.17970488,
                      36.08932264
                  ],
                  [
                      -115.17970488,
                      36.08932268
                  ],
                  [
                      -115.17970488,
                      36.08932272
                  ],
                  [
                      -115.17970487,
                      36.08932398
                  ],
                  [
                      -115.17970484,
                      36.08933274
                  ],
                  [
                      -115.17969239,
                      36.08933271
                  ],
                  [
                      -115.1796924,
                      36.08932997
                  ],
                  [
                      -115.17936524,
                      36.08932927
                  ]
              ]
          ]
      },
      "levels": [
          {
              "levelShortTitle": "L1",
              "levelLongTitle": "Level 1",
              "levelIndex": 1,
              "levelExtraData": {
                  "timeZone": "UTC",
                  "levelExternalIdentifier": "89468032-5da2-4204-bb90-4da339e35f1e"
              }
          }
      ]
  }
];
  const showPopup = (poi, coordinates, cleanPopup = true) => {
    if (!poi) {
      setPopupState({ poi: null, coordinates: null });
      handleResetView(cleanPopup);
    } else {
      setPopupState({ poi, coordinates: (poi?.geometry?.type === 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
    }
  };
  const showCiscoSpacesPopup = () => {
    setCiscoSpacesPopup(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.PointrWebSDK) {
        setIsPointrSDKLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, []);
  // Handler to hide the popup
  const hidePopup = () => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getZones = async () => {
      try {

        const data = await fetchGsxData(tenantId);
        console.log("gsx" + data);

        setZonesInfo(data.zones);
        setHubwalksInfo(data.hubWalks);
        setFacilitiesData(data.facilities)

      } catch (err) {

        console.log(err);

      }
    };

    getZones();
  }, []);

  // Simulate map loading
  useEffect(() => {
    // Simulate a delay for the map loading
    const mapLoadTimeout = setTimeout(() => {
      setIsMapLoaded(true);
      document.title = '2024 Cisco GSX';
    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(mapLoadTimeout);
  }, []);


  useEffect(() => {
    const eventTypes = ["click", "mousemove", "keypress", "wheel", "gesturestart", "gesturechange", "gestureend", "touchmove", "touchstart", "touchend"];

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleActivity);
    });

    return () => {
      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => { }, [isActive]);


  const handleActivity = (event) => {
    setIsActive(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
      console.log("No activity detected for 2 minutes." + event.type);
    }, 180000); // 2 minutes
  };


  const handleSearch = async (searchInput) => {
    hidePopup();
    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');

    console.log(searchInput);
    let valueAfterTilde;
    let poiFid = searchInput?.fId;
    if (searchInput?.type === 'session') {
      poiFid = searchInput?.poiFId;

    }
    if (poiFid?.includes('~')) {
      valueAfterTilde = poiFid.split('~')[1];
    } else {
      valueAfterTilde = poiFid;
    }
    const poi = await map.getPoiManager().get(valueAfterTilde);
    if (poi) {
      map.unhighlight();
      mapViewController.highlightPoi(poi);
      map.hidePoiDetails(poi);

      if (poi?._geometry?.coordinates[0]) {
        const latLong = (poi.geometry.type === 'Point') ? {
          lat: poi.geometry.coordinates[1],
          lng: poi.geometry.coordinates[0]
        } : calculateMidPoint(poi?._geometry?.coordinates[0]);

        //updateLayerFilterForSingleFeature(mapView, searchInput);
        mapView.map.flyTo({
          center: [latLong.lng, latLong.lat],
          zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
        });
        if (searchInput.type === 'session') {
          poi._properties.isSession = true;
          poi._properties.sessionsInfo = [searchInput];
        }
        showPopup(poi, latLong);

      }
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput);
    }
  }, [searchInput]);

  const handleResetView = (cleanPopup = false) => {
    handleReset(long ? long : -115.1782890405586, lat ? lat : 36.088308032921276, rotation ? rotation : -4.5, zoom ? zoom : 22, true, cleanPopup,false, pitch, bearing, building, level, centerLong, centerLat, 150.091301692798277e-8);
};


  const handleResetViewButton = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  return (
    <div className="main-container">

      {isPointrSDKLoaded && <MapComponent showPopup={showPopup} tenantId={tenantId}
        locationId={locationId} eventName={eventName} whereAmILevel={parseInt(whereAmILevel)} whereAmIBuilding={parseInt(whereAmIBuilding)} defaultBuilding={parseInt(building)} defaultLevel={parseInt(level)} timeZone={timeZone} eventStartDateData={eventStartDateData} />}
      {isMapLoaded && (
        <>
          <HeaderContainer
            showCiscoSpacesPopup={showCiscoSpacesPopup} timeZoneCity={timeZoneCity} eventStartDateData={eventStartDateData} eventEndDateData={eventEndDateData}  handleResetView={handleResetViewButton}  eventLogoName={eventLogoName}  defaultLevel={parseInt(level)} defaultBuilding = {parseInt(building)} setBuilding={setBuilding} setLevel={setLevel} pitch={pitch} bearing={bearing} 
            lat={lat} long={long} rotation={rotation}
            eventTimeZone={timeZone}  buildingInfo={buildingInfo} defaultActiveTab={1}/>
          {zonesInfo &&
            <LeftContainer
              zoneData={zonesInfo} showPopup={showPopup} hidePopup={hidePopup} tenantId={tenantId}
              locationId={locationId} qrCodeExpiresAt={qrCodeExpiresAt}
              isZonesInfoPopulated={true} 
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}  defaultActiveTab={1}  centerLatLong={[centerLong,centerLat]} />}
          {isPointrSDKLoaded && <RightContainer
            hubwalksData={hubwalksInfo} facilitiesData={facilitiesData} pitch={pitch} bearing={bearing} centerLong={centerLong} centerLat={centerLat}/>}
          {(!isActive &&
            /**<CountdownTimer
              initialMinutes={0}
              initialSeconds={10}
              resetTimer={isActive} // Pass isActive as a prop to reset the timer
              showPopup={showPopup}
            />*/
            <Screensaver inactivityTimeout={1000} flipInterval={3000} showPopup={showPopup}  ciscoSpacesPopupImage={ciscoSpacesPopupImage} lat={lat} long={long} zoom={zoom} rotation={rotation} whereAmIBuilding={building} whereAmILevel={level} pitch={pitch} bearing={bearing}/>

          )}
          {showSearch && (
            <SearchPopup
              setSearchInput={setSearchInput}
              setShowSearch={setShowSearch}
              map={map}
              tenantId={tenantId}
            />
          )}
          {popupState.poi && (
            <CustomPopup
              poi={popupState.poi}
              coordinates={popupState.coordinates}
              tenantId={tenantId}
              locationId={locationId}
              setPopupState={setPopupState}
              onCancel={hidePopup}
              source={"poiClick"}
              lat={lat}
              long={long}
              rotation={rotation}
              bearing={bearing}
              pitch={pitch}
              building={building}
              level={level}
              whereAmIBuilding={whereAmIBuilding}
              whereAmIlevel={whereAmILevel}
              qrCodeExpiresAt={qrCodeExpiresAt}
              setBuilding={setBuilding}
              setLevel={setLevel}
              eventStartDate={eventStartDateData}
              setSelectedFloor={setSelectedFloor}
            />
          )}
          {ciscoSpacesPopup && (
            <div className="cisco-spaces-popup-overlay" >
              <div className="cisco-spaces-popup-overlay">
                <div className="cisco-spaces-popup-overlay-popup">
                  <img src={ciscoSpacesPopupIcon} alt="Popup Content" class="cisco-spaces-popup-overlay-image" />
                  <div className="cisco-spaces-popup-overlay-close" onClick={() => setCiscoSpacesPopup(false)}>
                    <svg width="44" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11306_4187)">
                        <circle cx="22.5477" cy="22.1922" r="21.1342" stroke="white" stroke-width="0.98722" />
                        <path d="M29.4141 26.9647L24.6432 22.1929L29.4141 17.4216L27.3198 15.3271L22.5486 20.0983L17.7775 15.3271L15.683 17.4216L20.4544 22.1929L15.6821 26.9647L17.7764 29.0589L22.5486 24.287L27.3198 29.0589L29.4141 26.9647Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_11306_4187">
                          <rect width="43.2556" height="43.2556" fill="white" transform="translate(0.919922 0.564453)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          )}
          <FooterContainer
            setShowSearch={setShowSearch}
            handleResetView={handleResetViewButton}
            lat={lat}
            long={long}
            rotation={rotation}
            pitch={pitch}
            bearing={bearing}
            whereAmIBuilding={whereAmIBuilding}
            whereAmILevel={whereAmILevel}
          />
        </>
      )}
    </div>
  );
};

export default MainContainer;
