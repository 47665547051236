import React, { useEffect, useState, useRef } from "react";
import MapComponent from "../common-pointr-map-container/GenericPointrMapContainer"
import LeftContainer from "../left-container/left-container";
import HeaderContainer from "../header-container/header-container";
import RightContainer from "../right-container/right-container";
import SearchPopup from '../main-container/SearchPopup'; // Import SearchPopup component
import FooterContainer from '../footer-container/footer-container';
import { useMap } from '../../../context/MapContext'; // Import the useMap hook
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { handleReset } from '../main-container/handleResetView'; // Import the functions
import { calculateMidPoint, manageLayers,markCurrentPosition, removeCurrentPosition } from '../main-container/markCurrentPosition'; // Import the functions
import { fetchGsxData } from '../left-container/fetchZonesHubwalksInfo';
import CustomPopup from '../main-container/DirectionPopup';
import ciscoSpacesPopupIcon from '../../../../assets/images/powered-by-spaces-cl-emea.png';
import Screensaver from "../countdown-timer-container/screensaver";
import ciscoSpacesPopupImage from '../../../../assets/images/cisco-space-popup-cl-emea.png';
import {handleWhereAmI} from '../footer-container/handle-where-am-i';  
import ciscoAILogo from '../../../../assets/images/ai/ai-logo.svg';
import ciscoAIQrPlaceHolderLogo from '../../../../assets/images/ai/qr-code-place-holder.svg';
import ciscoAIQrPlaceHolderFailedLogo from '../../../../assets/images/ai/qr-code-place-holder-failed.svg';

import ciscoClosebutton from '../../../../assets/images/close-button.svg';
import ciscoHelpbutton from '../../../../assets/images/ai/help-icon.svg';
import CiscoAiAuth from '../right-container/ciscoAiAuth';
import {fetchAttendeeInfo, fetchRecommendedEvents} from '../right-container/ciscoAiApis';
import { TeaLogService,TeaLogModule } from '@events/tealium';
import {initializeXAPIScanner, getHashes} from '../qr-code-scanner-component/qr-code-scanner';
import AIFAQListContainer from "../right-container/ai-faq-list";
import {sendTrackingEventForCiscoAI} from "../main-container/pendoApis"
import { scaleBand } from "d3";
const SJCDemoMainContainer = () => {
  const kioskData = {
    "1": {
      "lat":  42.18882997249821,
      "long":  -71.20129185010097,
      "rotation": 1.5,
      "bearing": -83.8,
      "building": 8,
      "level":0
    },
    "2": {
      "lat": 42.18882997249821,
      "long": 4.889270989661952,
      "rotation": 1.5,
      "bearing": -83.5,
      "building": 8,
      "level":0
    }
  };
  
  const { map } = useMap();
  const teaLogService = new TeaLogService();

  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isZonesInfoPopulated, setIsZonesInfoPopulated] = useState(false);

  const [zonesInfo, setZonesInfo] = useState([]);
  const [hubwalksInfo, setHubwalksInfo] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [recommendationsInfo, setRecommendationsInfo] = useState([]);
  const [attendeeUserToken, setAttendeeUserToken] = useState([]);
  const [attendeeInfo, setAttendeeInfo] = useState([]);

  const hasPassedDestFidRef = useRef(false);

  const timerRef = useRef(null);
  const [popupState, setPopupState] = useState({ poi: null, coordinates: null }); // Add state for the popup
  const [ciscoSpacesPopup, setCiscoSpacesPopup] = useState(false);
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [showFaqList, setShowFaqList] = useState(false);
  const [qrScanBadgeInfoInput, setQRScanBadgeInfoInput] = useState(null);
  const [aiLoader, showAiLoader] = useState(false);
  const [showFailedPopupCard, setShowFailedPopupCard] = useState(false);

  
  
  const [showAICard, setShowAICard] = useState(false);

  const [isPointrSDKLoaded, setIsPointrSDKLoaded] = useState(false); // State to track Pointr SDK load status
  const [triggerDirectionsPopup, setTriggerDirectionsPopup] = useState(false);

  const tenantId = "363251";
  const locationId = "4a0aa396-804a-4464-b345-a93e367e2867";
  const siteExternalIdentifier = "4a0aa396-804a-4464-b345-a93e367e2867";
  const timeZoneCity = "San Jose";
  const timeZone = "America/Los_Angeles";
  const qrCodeExpiresAt = 1746057599 // March 31 2025
  const eventLogoName = "trigue.svg"
  const eventName = "SJC-DEMO"
  const eventId="ds1ciscolive25emea";
  const eventTitle = "SJC"
  const defaultBuildingTitle = "Cisco - SJC"


  
  const eventSubTitle = ""

  const buildingInfo =  [
    {
        "buildingTitle": "SJC24",
        "buildingInternalIdentifier": 15,
        "buildingExternalIdentifier": "b58c6407-dcc6-4537-9a58-ada47fae94f9",
        "buildingExtraData": {
            "timeZone": "America/Los_Angeles"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.92004137,
                        37.41960308
                    ],
                    [
                        -121.91972627,
                        37.42032015
                    ],
                    [
                        -121.91967477,
                        37.42030572
                    ],
                    [
                        -121.91966568,
                        37.42034662
                    ],
                    [
                        -121.9189355,
                        37.4201469
                    ],
                    [
                        -121.91895065,
                        37.42012043
                    ],
                    [
                        -121.91889914,
                        37.42009637
                    ],
                    [
                        -121.91922939,
                        37.41937208
                    ],
                    [
                        -121.9192809,
                        37.41938652
                    ],
                    [
                        -121.91929604,
                        37.41934802
                    ],
                    [
                        -121.92002016,
                        37.41954052
                    ],
                    [
                        -121.91999896,
                        37.41958624
                    ],
                    [
                        -121.92004137,
                        37.41960308
                    ]
                ]
            ]
        },
        "levels": [
            {
                "levelShortTitle": "1F",
                "levelLongTitle": "First Floor",
                "levelIndex": 1,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "2756bd0d-f520-4924-b80b-7fc3aa0ff41b"
                }
            },
            {
                "levelShortTitle": "2F",
                "levelLongTitle": "Second Floor",
                "levelIndex": 2,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "f9f80887-4de4-47a4-b875-4bfe407f37ab"
                }
            },
            {
                "levelShortTitle": "3F",
                "levelLongTitle": "Third Floor",
                "levelIndex": 3,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "4f4f51f7-1b60-4461-825f-8d1b010bcbba"
                }
            }
        ]
    },
    {
        "buildingTitle": "SJC22",
        "buildingInternalIdentifier": 16,
        "buildingExternalIdentifier": "297de049-cd6b-4f93-b7ce-f1fc92041fd0",
        "buildingExtraData": {
            "timeZone": "America/Los_Angeles"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.91970158,
                        37.41630895
                    ],
                    [
                        -121.91944955,
                        37.41689628
                    ],
                    [
                        -121.91939981,
                        37.41688575
                    ],
                    [
                        -121.91937328,
                        37.41693052
                    ],
                    [
                        -121.91899856,
                        37.4168199
                    ],
                    [
                        -121.91900851,
                        37.41680147
                    ],
                    [
                        -121.91883939,
                        37.41674879
                    ],
                    [
                        -121.91883939,
                        37.41676986
                    ],
                    [
                        -121.91846467,
                        37.41666715
                    ],
                    [
                        -121.91847462,
                        37.41663291
                    ],
                    [
                        -121.91843151,
                        37.4166092
                    ],
                    [
                        -121.91868022,
                        37.41603767
                    ],
                    [
                        -121.91873659,
                        37.41604557
                    ],
                    [
                        -121.91875317,
                        37.41601134
                    ],
                    [
                        -121.91966841,
                        37.41626681
                    ],
                    [
                        -121.91965515,
                        37.41630895
                    ],
                    [
                        -121.91970158,
                        37.41630895
                    ]
                ]
            ]
        },
        "levels": [
            {
                "levelShortTitle": "1F",
                "levelLongTitle": "First Floor",
                "levelIndex": 1,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "5cb26e06-d26e-4cd1-8bc4-e9ddedbdb24e"
                }
            },
            {
                "levelShortTitle": "2F",
                "levelLongTitle": "Second Floor",
                "levelIndex": 2,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "6368e3f2-db95-4c6c-8e8d-b2b191d2f521"
                }
            },
            {
                "levelShortTitle": "3F",
                "levelLongTitle": "Third Floor",
                "levelIndex": 3,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "35ad2e11-cf12-483c-8922-9d7a59e54d06"
                }
            }
        ]
    },
    {
        "buildingTitle": "SJC23",
        "buildingInternalIdentifier": 17,
        "buildingExternalIdentifier": "ec06f36a-f11d-46a5-b3d2-de4ac7ba6a95",
        "buildingExtraData": {
            "timeZone": "America/Los_Angeles"
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.91994845,
                        37.4183497
                    ],
                    [
                        -121.91957903,
                        37.41917385
                    ],
                    [
                        -121.91944621,
                        37.41914088
                    ],
                    [
                        -121.91945866,
                        37.41910462
                    ],
                    [
                        -121.91873228,
                        37.41891012
                    ],
                    [
                        -121.91909339,
                        37.41810245
                    ],
                    [
                        -121.91994845,
                        37.4183497
                    ]
                ]
            ]
        },
        "levels": [
            {
                "levelShortTitle": "1F",
                "levelLongTitle": "First Floor",
                "levelIndex": 1,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "2f5479d6-bbd5-4c25-9b26-da4214ada9ed"
                }
            },
            {
                "levelShortTitle": "2F",
                "levelLongTitle": "Second Floor",
                "levelIndex": 2,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "559e6be2-ff87-4a53-a361-8c4ae23472c7"
                }
            },
            {
                "levelShortTitle": "3F",
                "levelLongTitle": "Third Floor",
                "levelIndex": 3,
                "levelExtraData": {
                    "timeZone": "America/Los_Angeles",
                    "levelExternalIdentifier": "3d924b7d-6a57-41f5-ab18-ab7fb92497cf"
                }
            }
        ]
    }
];
  // Or, if the keys are static, you can use an object:
  const buildingExID = {
    15: "b58c6407-dcc6-4537-9a58-ada47fae94f9",
    16: "297de049-cd6b-4f93-b7ce-f1fc92041fd0",
    17: "ec06f36a-f11d-46a5-b3d2-de4ac7ba6a95"
  };

  const levelExId ={
    "b58c6407-dcc6-4537-9a58-ada47fae94f9":{
      1:"2756bd0d-f520-4924-b80b-7fc3aa0ff41b",
      2:"f9f80887-4de4-47a4-b875-4bfe407f37ab",
      3:"4f4f51f7-1b60-4461-825f-8d1b010bcbba"
    },
    "297de049-cd6b-4f93-b7ce-f1fc92041fd0":{
      1:"5cb26e06-d26e-4cd1-8bc4-e9ddedbdb24e",
      2:"6368e3f2-db95-4c6c-8e8d-b2b191d2f521",
      3:"35ad2e11-cf12-483c-8922-9d7a59e54d06"
    },
    "ec06f36a-f11d-46a5-b3d2-de4ac7ba6a95":{
      1:"2f5479d6-bbd5-4c25-9b26-da4214ada9ed",
      2:"559e6be2-ff87-4a53-a361-8c4ae23472c7",
      3:"3d924b7d-6a57-41f5-ab18-ab7fb92497cf"
    }
  }

  const minMaxBounds = [
    
    [ 4.874747373780906,
      52.32649719537784
    ],
    [
      4.900164974144076,
      52.35777235234855
    ]
  ];

 
  const floorColourMapping = [
    { levelIndex: 0, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 1, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 2, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 3, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 4, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
    { levelIndex: 5, color: '#4F545A',fillColor:'#FFFFFF', enableDisplayBullet:false },
  ];

  const constraintsLookUp = {
    "Full Conference": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "Cisco Full Conference": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "IT Leadership": [
        "Full Conf - Schedule",
        "Explorer - Schedule"
    ],
    "Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Thursday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Thursday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Cisco Wednesday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Wednesday Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],
    "Event Staff": [
        "Full Conf - View"
    ],
    "Speaker Full Conference": [
        "Full Conf - View"
    ],
    "Cisco Explorer": [
        "Full Conf - View",
        "Explorer - Schedule"
    ],

}  
 
  const getLocationId = (poi) => {
    const bid = poi?.properties?.bid;
    const lvl = poi?.properties?.lvl;
    const buildingId = buildingExID[bid];
  
    // Check if both buildingId and lvl exist in levelExId
    if (buildingId && lvl !== undefined && levelExId[buildingId]?.[lvl] !== undefined) {
      return levelExId[buildingId][lvl];
    }
  
    // Fallback to buildingId if lvl is not present or no match found
    return buildingId || locationId;
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const kioskFid = query.get('kioskFid')||query.get('locationNum');
  const destFid = query.get('destFid');
  const latFromQuery = parseFloat(query.get('lat'));
  const longFromQuery = parseFloat(query.get('long'));
  const rotationFromQuery = parseFloat(query.get('rotation'));
  const zoomFromQuery = parseFloat(query.get('zoom'));
  const pitchFromQuery = parseFloat(query.get('pitch'));
  const bearingFromQuery = parseFloat(query.get('bearing'));
  const levelFromQuery = parseFloat(query.get('level'));
  const buildingFromQuery = parseFloat(query.get('building'));
  const visibleAiContainerFromQuery = query.get('enableAI');
  const eventStartDay = query.get('eventStartDay')?query.get('eventStartDay'):3;
   
  
  const centerLong = -121.9191846089422;
  const centerLat = 37.41896623582046;
  const whereAmIScale = 42.091301692798277e-8;
  const xapi=null;

  const [lat, setLat] = useState(kioskData[kioskFid]?.lat || (latFromQuery ? latFromQuery :  37.41856217669175));
  const [long, setLong] = useState(kioskData[kioskFid]?.long || (longFromQuery ? longFromQuery : -121.91947735473215));
  const [rotation, setRotation] = useState(kioskData[kioskFid]?.rotation || (rotationFromQuery ? rotationFromQuery:-1.9));
  const [zoom, setZoom] = useState(kioskData[kioskFid]?.zoom || (zoomFromQuery ? zoomFromQuery : 17));
  const [pitch, setPitch] = useState(kioskData[kioskFid]?.pitch || (pitchFromQuery ? pitchFromQuery: 65));
  const [bearing, setBearing] = useState(kioskData[kioskFid]?.bearing || (bearingFromQuery ? bearingFromQuery : 118.99999999999994));
  const [level, setLevel] = useState(kioskData[kioskFid]?.level || (levelFromQuery ? levelFromQuery: 1));
  const [building, setBuilding] = useState(kioskData[kioskFid]?.building || (buildingFromQuery ? buildingFromQuery : 9));
  const [visibleAIContainer, setVisibleAIContainer] = useState(visibleAiContainerFromQuery?(visibleAiContainerFromQuery==='true'):false);

  const [whereAmILevel, setWhereAmILevel] = useState(level);
  const [whereAmIBuilding, setWhereAmIBuilding] = useState(building);
  const [selectedFloor, setSelectedFloor] = useState(level);
  const [kioskLocation, setKioskLocation] = useState(1);

  const [eventStartDateData, setEventStartDateData] = useState({
    year: 2025,
    month: 3,
    day: eventStartDay
  });


  const [eventEndDateData, setEventEndDateData] = useState({
    year: 2025,
    month: 3,
    day: 14
  });


  useEffect(() => {
    if (kioskFid && kioskData[kioskFid]) {
      const data = kioskData[kioskFid];
      setLat(data.lat ||  42.18846877027224);
      setLong(data.long ||  -71.20235964462145);
      setRotation(data.rotation || 2.8);
      setZoom(data.zoom || 18);
      setPitch(data.pitch || 65);
      setBearing(data.bearing || -157.99999999999994);
      setLevel(data.level || 1);
      setWhereAmILevel(level);
      setBuilding(data.building || 9);
      setWhereAmIBuilding(building);
    }
  }, [kioskFid]);


  useEffect(() => {
    if (destFid && !hasPassedDestFidRef.current) {
      // Call any function or set up as needed for the first time `destFid` is passed
      hasPassedDestFidRef.current = true;
    }
  }, [destFid]);

  
  const showPopup = (poi, coordinates, cleanPopup = true,isMapClick=false) => {
    if (!poi) {
      if(popupState.poi){
        handleResetView(cleanPopup,isMapClick);
      } 
      const navigationViewController = window.PointrWebSDK.MapWidget.getInstance()?.getUiController().getNavigationViewController();
      window.PointrWebSDK.MapWidget.getInstance().getUiController().getMapViewController().hideRoute();
      navigationViewController.close();
      setPopupState({ poi: null, coordinates: null });
    
     
    } else {
      setPopupState({ poi, coordinates: (poi?.geometry?.type === 'Point') ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]) });
    }
  };
  const showCiscoSpacesPopup = () => {
    setCiscoSpacesPopup(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.PointrWebSDK) {
        setIsPointrSDKLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, []);
  // Handler to hide the popup
  const hidePopup = () => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
    setTriggerDirectionsPopup(false);
  };
  
  const badgeQrScanSuccess = async (badgeId) => {
    try{
    console.log("Badge Id received " + badgeId);
    showPopup(null, null, true, false);
    setQRScanBadgeInfoInput(null);
    let accessToken = await CiscoAiAuth.fetchAccessTokenForAttendee(eventId, badgeId);
    const attendeeInfo = await fetchAttendeeInfo(accessToken, eventId, { badgeId: badgeId },teaLogService);
    const userKey=attendeeInfo?.[0]?.userKey;     
    //const userFullName=attendeeInfo?.[0]?.firstName+" "+attendeeInfo?.[0]?.lastName;
    //const firstName=attendeeInfo?.[0]?.firstName
    //const lastName=attendeeInfo?.[0]?.lastName     
    //const dashboardPackage=attendeeInfo?.[0]?.dashboardPackage;     


    teaLogService.overrideConfiguration({"data":{"user":attendeeInfo?.[0]}})
    teaLogService.teaLogBadgeScanAction(badgeId,kioskLocation?kioskLocation:1);
    sendTrackingEventForCiscoAI(tenantId, attendeeInfo?.[0].userKey, "Badge Scan",eventName,attendeeInfo?.[0]);
    const recommendedEvents = await fetchRecommendedEvents(accessToken,eventId,attendeeInfo,"",constraintsLookUp,teaLogService);
    if(recommendedEvents == undefined|| recommendedEvents?.length==0){
      setShowFailedPopupCard(true);
      setShowPopupCard(false);
      return;
    }
    setRecommendationsInfo(recommendedEvents);
    setAttendeeInfo(attendeeInfo);
    setAttendeeUserToken(accessToken)
    setShowPopupCard(false);
    setShowAICard(true);
    }catch(error){
      console.log("Exception occured while processing badge");
      console.log(error)
      setShowFailedPopupCard(true);
      setShowPopupCard(false);
    }
    
  }

  useEffect(() => {
    // Fetch data from the API only once when the component mounts
    const getZones = async () => {
      try {

        const data = await fetchGsxData(tenantId);
        console.log("gsx" + data);
        if(data.zones){
          setIsZonesInfoPopulated(true);
        }
        setZonesInfo(data.zones);
        setHubwalksInfo(data.hubWalks);
        setFacilitiesData(data.facilities);

      } catch (err) {

        console.log(err);

      }
    };

    getZones();
   // intialiseQRScannerModule();
  }, []);
  // Simulate map loading
  useEffect(() => {
    // Simulate a delay for the map loading
    const mapLoadTimeout = setTimeout(() => {
      setIsMapLoaded(true);
      document.title = 'SJC';
    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(mapLoadTimeout);
  }, []);

  useEffect(() => {
    let intervalId;
  
    const fetchPoiUntilFound = async () => {
      if (map && destFid) {
        const poi = await map.getPoiManager().get(destFid);
        if (poi) {
          // Uncomment this line when ready to show the popup
          const uiController = map.getUiController();
          const mapViewController = uiController.getMapViewController();
          mapViewController.setLevel(poi.properties.lvl);
          mapViewController.highlightPoi(poi);
           showPopup(poi, poi?.geometry?.type === 'Point' ? poi.geometry.coordinates : calculateMidPoint(poi.geometry.coordinates[0]),false,false);
           clearInterval(intervalId); // Stop checking once the poi is found
        }
        
      }
    };
  
    // Set up polling to run fetchPoiUntilFound every 500ms (adjust as needed)
    intervalId = setInterval(fetchPoiUntilFound, 5000);
  
    return () => clearInterval(intervalId); // Clear interval on component unmount or when dependencies change
  }, [map, destFid]);


  useEffect(() => {
    const eventTypes = ["click", "mousemove", "keypress", "wheel", "gesturestart", "gesturechange", "gestureend", "touchmove", "touchstart", "touchend"];

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleActivity);
    });

    return () => {
      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => { }, [isActive]);


  const handleActivity = (event) => {
    setIsActive(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
      console.log("No activity detected for 2 minutes." + event.type);
    }, 180000); // 3 minutes
  };


  const handleSearch = async (searchInput) => {
    hidePopup();
    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');

    console.log(searchInput);
    let valueAfterTilde;
    let poiFid = searchInput?.fId;
    if (searchInput?.type === 'session') {
      poiFid = searchInput?.poiFId;
    }else if((searchInput?.type === 'exhibitor')){
      poiFid = searchInput?.poiFId;
    }
    if (poiFid?.includes('~')) {
      valueAfterTilde = poiFid.split('~')[1];
    } else {
      valueAfterTilde = poiFid;
    }
    const poi = await map.getPoiManager().get(valueAfterTilde);
    if (poi) {
      map.unhighlight();
      mapViewController.setLevel(poi.properties.lvl);
      mapViewController.highlightPoi(poi);
      map.hidePoiDetails(poi);

      if (poi?._geometry?.coordinates[0]) {
        const latLong = (poi.geometry.type === 'Point') ? {
          lat: poi.geometry.coordinates[1],
          lng: poi.geometry.coordinates[0]
        } : calculateMidPoint(poi?._geometry?.coordinates[0]);

        //updateLayerFilterForSingleFeature(mapView, searchInput);
        mapView.map.flyTo({
          center: [latLong.lng, latLong.lat],
          zoom: 20, pitch: pitch, bearing: bearing, speed: 0.4
        });
        if (searchInput.type === 'session') {
          poi._properties.isSession = true;
          poi._properties.sessionsInfo = [searchInput];
          poi._properties.sessionStart= searchInput?.utcStartTime;

        }
        showPopup(poi, latLong);
        manageLayers(map?.getUiController().getMapViewController().getView(),poi?._properties?.bid,poi?._properties?.lvl)
        if(poi?._properties?.lvl==whereAmILevel){
          markCurrentPosition(map, long, lat, rotation, true, pitch, bearing, whereAmIBuilding, whereAmILevel, whereAmIBuilding, whereAmILevel, zoom, whereAmIScale);

        }else{
          removeCurrentPosition(map.getUiController().getMapViewController().getView());
        }
      }
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearch(searchInput);
    }
  }, [searchInput]);

  const intialiseQRScannerModule = async () =>{
      console.log("Initiliazing the QR code scanner");
      let hashes = getHashes(["username", "password", "ipAddress", "location"]);
      setKioskLocation(hashes?.location? hashes.location : 1)
      await initializeXAPIScanner(window.jsxapi,hashes,setQRScanBadgeInfoInput);
  }

  useEffect(() => {
    console.log("QR Input received "+qrScanBadgeInfoInput);
    if (qrScanBadgeInfoInput) {
      if(!showAICard && !showFaqList && showPopupCard){
        badgeQrScanSuccess(qrScanBadgeInfoInput);
        showAiLoader(true);
        setQRScanBadgeInfoInput(null);
      }else{
         console.error("Already Attendee is using the AI or no yet launched")
         setQRScanBadgeInfoInput(null);
      }
    }
  }, [qrScanBadgeInfoInput]);


  useEffect(() => {
    console.log("showAiLoader received "+aiLoader);
    if (aiLoader) {
      showLoader();
    }else{
      hideLoader();
    }
  }, [aiLoader]);


  useEffect(() => {
    console.log("showAICard received "+showAICard);
    if (!showAICard) {
      setShowFaqList(0);
      setQRScanBadgeInfoInput(null);
    }
  }, [showAICard]);

  // Function to create and show the loader
  function showLoader() {
    // Create and style the overlay element
    const overlay = document.createElement("div");
    overlay.id = "ai-overlay"; // Set the id
    overlay.className = "ai-overlay"; // Set the class name
    document.body.appendChild(overlay);

    // Create and style the loader element
    const loader = document.createElement("div");
    loader.id = "ai-loader"; // Set the id
    loader.className = "ai-loader"; // Set the class name
    document.body.appendChild(loader);

    // Display the loader and overlay
    if (loader && overlay) {
      overlay.style.display = "block";
      loader.style.display = "block";
    }
  }


  // Function to hide and remove the loader
  function hideLoader() {
    const loader = document.getElementById("ai-loader");
    const overlayloader = document.getElementById("ai-overlay");

    if (loader && overlayloader) {
      loader.style.display = "none";
      document.body.removeChild(loader);

      overlayloader.style.display = "none";
      document.body.removeChild(overlayloader);

    }
  }
  const handleResetView = (cleanPopup = false,isMapClick=false) => {
    setShowAICard(false);
    setShowFaqList(false);
    setTriggerDirectionsPopup(false);
    setSelectedFloor(whereAmILevel)
    handleReset(long ? long : -71.20129185010097, lat ? lat : 42.18882997249821, rotation ? rotation : -4.5, 18, true, cleanPopup,isMapClick, pitch, bearing, whereAmIBuilding, whereAmILevel, centerLong, centerLat, whereAmIScale);
  };


  const handleResetViewButton = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    handleResetView();
  };

  const handleResetViewButtonInitialZoom = (cleanPopup = false) => {
    setPopupState({ poi: null, coordinates: null });
    setSelectedFloor(whereAmILevel)
    handleResetView();
  //handleReset(long ? long : -115.1782890405586, lat ? lat : 36.088308032921276, rotation ? rotation : -4.5, 17, true, cleanPopup,false, pitch, bearing, building, level);

    const uiController = map.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();
    mapView.map.flyTo({
      center: [long, lat],
      zoom: 18,
      bearing: bearing,
      pitch: pitch,
      speed:0.4
    });
  };

  return (
    <div className="main-container">

      {isPointrSDKLoaded && <MapComponent showPopup={showPopup} tenantId={tenantId}
        locationId={locationId} siteExternalIdentifier={siteExternalIdentifier} lat={lat} long={long} zoom={zoom} pitch={pitch} bearing={bearing} rotation={rotation} eventName = {eventName} whereAmILevel={parseInt(whereAmILevel)} whereAmIBuilding={parseInt(whereAmIBuilding)} defaultBuilding={parseInt(building)} defaultLevel={parseInt(level)} setBuilding={setBuilding} setLevel={setLevel} timeZone={timeZone} eventStartDateData={eventStartDateData} centerLatLong={[centerLong,centerLat]} 
        setTriggerDirectionsPopup={setTriggerDirectionsPopup} whereAmIScale={whereAmIScale}/>}
      {isMapLoaded && (
        
        <>
          {handleWhereAmI(map,long,lat,rotation,pitch,bearing,whereAmILevel, whereAmIBuilding)}
          <HeaderContainer
            showCiscoSpacesPopup={showCiscoSpacesPopup} timeZoneCity={timeZoneCity} 
            eventStartDateData = {eventStartDateData} eventEndDateData={eventEndDateData}  
            handleResetView={handleResetViewButton}  eventLogoName={eventLogoName} 
            eventName={eventName} defaultLevel={parseInt(level)} 
            defaultBuilding = {parseInt(building)} setBuilding={setBuilding} 
            setLevel={setLevel} pitch={pitch} 
            bearing={bearing} lat={lat} long={long} 
            rotation={rotation}
            eventTitle={eventTitle}
            eventSubTitle={eventSubTitle}
            eventTimeZone={timeZone}
            defaultBuildingTitle={"Cisco - SJC"}
            buildingInfo={buildingInfo}
            whereAmIScale={whereAmIScale}
            displayDay ={false}/>
          {zonesInfo &&
            <LeftContainer
              zoneData={zonesInfo} showPopup={showPopup} hidePopup={hidePopup} tenantId={tenantId}
              locationId={locationId} qrCodeExpiresAt={qrCodeExpiresAt}
              whereAmIBuilding={whereAmIBuilding}
              whereAmILevel={whereAmILevel}
              defaultLevel={parseInt(level)} 
              defaultBuilding = {parseInt(building)}
              handleResetView={handleResetViewButton}
              setBuilding={setBuilding}
              setLevel={setLevel} 
              lat={lat}
              long={long}
              rotation={rotation}
              pitch={pitch}
              bearing={bearing}
              showLevelSelector={true}
              isZonesInfoPopulated={isZonesInfoPopulated}
              isDestFidPopulated={destFid?true:false}
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
              buildingInfo={buildingInfo}
              defaultBuildingTitle={defaultBuildingTitle}
              centerLatLong={[centerLong,centerLat]}
              eventName={eventName}
              floorColourMapping={floorColourMapping}
              whereAmIScale={whereAmIScale}
               />}
          {isPointrSDKLoaded && 
          <RightContainer
            hubwalksData={hubwalksInfo} 
            facilitiesData={facilitiesData} 
            pitch={pitch} 
            bearing={bearing} 
            centerLong={centerLong} 
            centerLat={centerLat}
            visibleAIContainer={visibleAIContainer}
            eventId={eventId}
            tenantId={tenantId}
            setShowPopupCard={setShowPopupCard}
            showAICard={showAICard}
            setShowAICard={setShowAICard}
            showPopup={showPopup}
            recommendationsInfo={recommendationsInfo}
            setRecommendationsInfo={setRecommendationsInfo}
            attendeeUserToken={attendeeUserToken}
            attendeeInfo={attendeeInfo}
            constraintsLookUp={constraintsLookUp}
            teaLogService={teaLogService}
            setShowFaqList={setShowFaqList}
            showFaqList={showFaqList}
            setTriggerDirectionsPopup={setTriggerDirectionsPopup}
            triggerDirectionsPopup={triggerDirectionsPopup}
            setPopupState={setPopupState}
            showAiLoader={showAiLoader}
            />}
          {(!isActive &&
          /** <CountdownTimer
              initialMinutes={0}
              initialSeconds={10}
              resetTimer={isActive} // Pass isActive as a prop to reset the timer
              showPopup={showPopup}
            /> */
            <Screensaver inactivityTimeout={1000} flipInterval={3000} showPopup={showPopup} ciscoSpacesPopupImage={ciscoSpacesPopupImage} lat={lat} long={long} zoom={zoom} rotation={rotation} pitch={pitch} bearing={bearing} whereAmILevel={whereAmILevel} centerLong={centerLong} centerLat={centerLat} setShowAICard={setShowAICard} setShowPopupCard={setShowPopupCard} whereAmIScale={whereAmIScale} displayScreenSaver={false}/>
           
          )}
          {(showFaqList==1) && <AIFAQListContainer setShowFaqList={setShowFaqList} showFaqList={showFaqList} setShowAICard={setShowAICard} showAICard={showAICard} setShowPopupCard={setShowPopupCard}/>}
          {showSearch && (
            <SearchPopup
              setSearchInput={setSearchInput}
              setShowSearch={setShowSearch}
              map={map}
              tenantId={tenantId}
              placeholder={"Search for points of interest and other facilities"}
            />
          )}
          {showPopupCard && (
            <div className="ai-popup-overlay">
              <div className="ai-popup-overlay-popup">
                <div className="ai-popup-overlay-popup-card">
                 
                  <img src={ciscoAILogo} class="ai-popup-overlay-popup-card-logo" />
                  <div className="ai-popup-overlay-popup-card-logo-name">
                  You have activated <br/>Cisco AI Assistant.
                  </div>
                  <div className="ai-popup-overlay-popup-card-title">
                  Now, scan the front of your<br/>badge on the scanner to begin<br/>your customized journey.​
                  </div>
                  <img src={ciscoAIQrPlaceHolderLogo} className="ai-popup-overlay-popup-card-qr-place-holder" onClick={()=>{badgeQrScanSuccess("1730740293847001Zs8f")}}  />
                  <div className="ai-popup-overlay-popup-card-bottom-button">
                  <img src={ciscoHelpbutton} className="ai-popup-overlay-popup-card-bottom-button-help"  onClick={() => {setShowPopupCard(false);setShowFailedPopupCard(false); setShowFaqList(1); }}/>

                  <img src={ciscoClosebutton} className="ai-popup-overlay-popup-card-bottom-button-close" onClick={() => setShowPopupCard(false)}/>
                  </div>
                </div>
              </div>
            </div>
          )}
           {showFailedPopupCard && (
            <div className="ai-popup-overlay">
              <div className="ai-popup-overlay-popup">
                <div className="ai-popup-overlay-popup-card">
                 
                  <img src={ciscoAILogo} class="ai-popup-overlay-popup-card-logo" />
                  <div className="ai-popup-overlay-popup-card-logo-name">
                  You have activated <br/>Cisco AI Assistant.
                  </div>
                  <div className="ai-popup-overlay-popup-card-title">
                  There was an error<br/>scanning your badge.​
                  </div>
                  <img src={ciscoAIQrPlaceHolderFailedLogo} className="ai-popup-overlay-popup-card-qr-place-holder"/>
                  <div className="ai-popup-overlay-popup-card-bottom-scan-again-button" onClick={()=>{ setShowFailedPopupCard(false); setShowPopupCard(true);}}>
                    Scan again
                  </div>

                  <div className="ai-popup-overlay-popup-card-bottom-button">
                  <img src={ciscoHelpbutton} className="ai-popup-overlay-popup-card-bottom-button-help"  onClick={() => {setShowPopupCard(false); setShowFailedPopupCard(false); setShowFaqList(1)}}/>

                  <img src={ciscoClosebutton} className="ai-popup-overlay-popup-card-bottom-button-close" onClick={() => {setShowPopupCard(false); setShowFailedPopupCard(false);}}/>
                  </div>
                </div>
              </div>
            </div>
          )}
          {popupState.poi && (
            <CustomPopup
              poi={popupState.poi}
              coordinates={popupState.coordinates}
              tenantId={tenantId}
              locationId={getLocationId(popupState.poi)}
              onCancel={hidePopup}
              setPopupState={setPopupState}
              source={"poiClick"}
              lat={lat}
              long={long}
              building={building}
              level={level}
              rotation={rotation}
              bearing={bearing}
              pitch={pitch}
              whereAmIBuilding={whereAmIBuilding}
              whereAmIlevel={whereAmILevel}
              qrCodeExpiresAt={qrCodeExpiresAt}
              destFid={hasPassedDestFidRef.current ? undefined : destFid} 
              setBuilding={setBuilding}
              setLevel={setLevel}
              eventStartDate={eventStartDateData}
              eventTimeZone={timeZone}
              setSelectedFloor={setSelectedFloor}
              triggerDirectionsPopup={triggerDirectionsPopup}
              setTriggerDirectionsPopup={setTriggerDirectionsPopup}
              whereAmIScale={whereAmIScale}
              navigationZoom={19}
            />
          )}

          { aiLoader && <div  class="ai-overlay" id="ai-overlay" >
                    <div class="ai-loader" id="ai-loader" ></div>
                </div>}
          <FooterContainer
            setShowSearch={setShowSearch}
            handleResetView={handleResetViewButtonInitialZoom}
            long={long}
            lat={lat}
            rotation={rotation}
            pitch={pitch}
            bearing={bearing}
            whereAmIBuilding={parseInt(whereAmIBuilding)}
            whereAmILevel={parseInt(whereAmILevel)}
            setSelectedFloor={setSelectedFloor}
            whereAmIScale={whereAmIScale}
          />
        </>
      )}
    </div>
  );
};

export default SJCDemoMainContainer;
