import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import RMSAuth from './rmsauth'; // Adjust the path as necessary

const QrCodeComponent = ({ tenant, location, requestBody, className, canvasId, isZonesPopulated }) => {
  const [error, setError] = useState(null);
  const [isQrCodePopulated, setIsQrCodePopulated] = useState(false);

  useEffect(() => {
    const fetchShortUri = async () => {
      try {
        // Ensure we have a valid token before making the request

        let shortUriKey = requestBody.poiId ? tenant + "_" + location + "_" + requestBody.poiId : tenant + "_" + location;
        let tokenKey = tenant + "_" + location
        let token = RMSAuth.getToken(tokenKey);
        if (!token) {
          const authData = await RMSAuth.auth(tenant, location);
          token = authData.accessToken;
          RMSAuth.setToken(tokenKey, token);
        }
        let short_uri = RMSAuth.getShortUri(shortUriKey);
        if (!short_uri) {
          const response = await fetch(`${process.env.REACT_APP_QR_CODE_BACKEND_API_URL}/wf-api/api/short-uri`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });


          if (!response.ok) {
            throw new Error('Network response was not ok');
          }


          const data = await response.json();
          if (process.env.REACT_APP_SHORT_CODE_PREFIX_API_URL) {
            const shortCode = data.short_uri.substring(data.short_uri.lastIndexOf("/") + 1);
            data.short_uri = `${process.env.REACT_APP_SHORT_CODE_PREFIX_API_URL}/${shortCode}`;
          }
          short_uri = data.short_uri;
          RMSAuth.setShortUri(shortUriKey, data.short_uri, data.expiryAt);

        }
        const fullShortUri = `${short_uri}`;
        if (!isQrCodePopulated) {
          QRCode.toCanvas(document.getElementById(className), fullShortUri, {
            margin: 0, color: {
              dark: '#FFFFFF', // QR code color
              light: '#0000'   // Transparent background
            }
          }, (error) => {
            if (error) {
              console.error('Error generating QR code:', error);
              setError(error);
            } else {
              console.log('QR code generated! for Short URI ' + fullShortUri);
              setIsQrCodePopulated(true);
            }
          });


          if (!isZonesPopulated) {
            const footerQRWrapper1 = document.querySelector('.left-container-footer-inner-outer-container .outer-bottom');

            if (footerQRWrapper1) {
              footerQRWrapper1.style.maxHeight = '20.6vh';
              footerQRWrapper1.style.maxWidth = '15vw';

              footerQRWrapper1.style.transform = '1.5'
            }

            const footerQRWrapper = document.querySelector('.left-container-footer-inner-outer-container .outer-bottom canvas');

            if (footerQRWrapper) {
              footerQRWrapper.style.maxHeight = '20.6vh';
              footerQRWrapper.style.maxWidth = '15vw';

              footerQRWrapper.style.transform = '1.5'
            }
          }
        }

      } catch (error) {
        console.error('Error submitting form:', error);
        setError(error);
      }
    };

    fetchShortUri();
  }, [tenant, location, requestBody, isQrCodePopulated]);

  return (
    <div className={className}>
      <canvas id={canvasId}></canvas>

    </div>
  );
};

export default QrCodeComponent;
