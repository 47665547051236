import React from 'react';
import SJCDemoMainContainer from './SJCDemoMainContainer';

const SJCDemoFullPageContainer = () => {
  return (
    <div className="full-page-container">
       <SJCDemoMainContainer />
    </div>
  );
};

export default SJCDemoFullPageContainer;
