import React from 'react';
import HospitalVenueDemoMainContainer from './HospitalVenueDemoMainContainer';

const HospitalVenueDemoFullPageContainer = () => {
  return (
    <div className="full-page-container">
       <HospitalVenueDemoMainContainer />
    </div>
  );
};

export default HospitalVenueDemoFullPageContainer;
